import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { AnswerGroupModel, AnswerModel, ConnectionModel, ExitCallModel, IceCandidateModel, IceGroupCandidateModel, OfferGroupModel, OfferModel } from '../../models/call-users-model';

@Injectable({
    providedIn: 'root'
})
export class CallService {

    meeting = {
        meetingId: 1,
        participants: [
            {
                userId: 1, acceptStatus: false
            },
            {
                userId: 3, acceptStatus: false
            }
        ],

    }
    
    constructor(public appInfo: AppInfoService, private http: HttpClient) {
      
    }

    callUsers(selectedUsers, callType, userId, meetingId, isCameraActive): Observable<any> {
        const data = {
            selectedUsers: selectedUsers,
            callType: callType,
            callerUserId: userId,
            meetingId: meetingId,
            isCameraActive: isCameraActive
        };
        return this.http.post<any>(this.appInfo.appUrl + "api/user/calluser", JSON.stringify(data), this.appInfo.httpOptions);
    }

    sendIceCandidateToServer(candidate: RTCIceCandidate, user = 0): void {
        const candidateModel: IceCandidateModel = {
            Candidate: candidate.candidate,
            SdpMid: candidate.sdpMid,
            SdpMLineIndex: candidate.sdpMLineIndex
        };

        this.http.post(this.appInfo.appUrl + 'api/user/icecandidate?user=' + user, candidateModel)
            .subscribe(
                response => {
                    console.log('ICE aday� sunucuya ba�ar�yla g�nderildi:', response);
                },
                error => {
                    console.error('ICE aday� g�nderilirken hata olu�tu:', error);
                }
            );
    }

    sendOfferToServer(offer: RTCSessionDescription | null, user = 0): void {
        const offerModel: OfferModel = {
            Type: offer.type as RTCSdpType,
            Sdp: offer.sdp
        };

        this.http.post(this.appInfo.appUrl + 'api/user/offer?user=' + user, offerModel)
            .subscribe(
                response => {
                    console.log('Teklif sunucuya ba�ar�yla g�nderildi:', response);
                },
                error => {
                    console.error('Teklif g�nderilirken hata olu�tu:', error);
                }
            );
    }

    sendAnswerToServer(answer: RTCSessionDescription | null, user = 0): void {
        if (!answer) {
            console.error('Ge�ersiz cevap, sunucuya g�nderilemiyor.');
            return;
        }

        const answerModel: AnswerModel = {
            Type: answer.type as RTCSdpType,
            Sdp: answer.sdp
        };

        this.http.post(this.appInfo.appUrl + 'api/user/answer?user=' + user, answerModel)
            .subscribe(
                response => {
                    console.log('Cevap sunucuya ba�ar�yla g�nderildi:', response);
                },
                error => {
                    console.error('Cevap g�nderilirken hata olu�tu:', error);
                }
            );
    }
    sendScreenShareStatus(isActive: boolean): Observable<any> {
        try {
            return this.http.get<any>(this.appInfo.appUrl + "api/user/screensharestatus?isActive=" + isActive, this.appInfo.httpOptions);
        } catch (ex) {
            console.log(ex);
        }
    }

    sendCallAnswer(answer: boolean, userId, meetingId, cameraIsActive): Observable<any> {
        try {
            return this.http.get<any>(`${this.appInfo.appUrl}api/user/sendcallanswer?answer=${answer}&userId=${userId}&meetingId=${meetingId}&cameraIsActive=${cameraIsActive}`, this.appInfo.httpOptions);
        } catch (ex) {
            console.log(ex);
        }
    }

    endCall(endcall: boolean, meetingId: string): Observable<any> {
        try {
            return this.http.get<any>(`${this.appInfo.appUrl}api/user/endCall?endcall=${endcall}&meetingId=${meetingId}`, this.appInfo.httpOptions);
        } catch (ex) {
            console.log(ex);
        }
    }

    exitCall(currentUser: number, callUsers: string[], meetingId: string): Observable<any> {
        const data: ExitCallModel = {
            currentUser: currentUser,
            callUsers: callUsers,
            meetingId: meetingId
        };
        try {
            return this.http.post<any>(this.appInfo.appUrl + "api/user/exitCall", JSON.stringify(data), this.appInfo.httpOptions);
        } catch (ex) {
            console.log(ex);
        }
    }
    /*receiveIceCandidate(): Observable<any> {
        return this.http.get(this.appInfo.appUrl + "api/user/icecandidate");
    }

    // Teklifleri almak i�in bir Observable
    receiveOffer(): Observable<any> {
        return this.http.get(this.appInfo.appUrl + "api/user/offer");
    }

    // Cevaplar� almak i�in bir Observable
    receiveAnswer(): Observable<any> {
        return this.http.get(this.appInfo.appUrl + "api/user/answer");
    }*/

    setConnections(connectionGuid: number, selectedUsers: string[]): Observable<any> {
        const data: ConnectionModel = {
            connectionGuid: connectionGuid,
            selectedUsers: selectedUsers
        };
        return this.http.post<any>(this.appInfo.appUrl + "api/user/setconnections", data, this.appInfo.httpOptions);
    }




    // Grup Aramalar�


    sendGroupIceCandidateToServer(candidate: RTCIceCandidate, participantId): void {
        const candidateModel: IceGroupCandidateModel = {
            Candidate: candidate.candidate,
            SdpMid: candidate.sdpMid,
            SdpMLineIndex: candidate.sdpMLineIndex,
            participantId: participantId
        };

        this.http.post(this.appInfo.appUrl + 'api/user/icecandidategroup', candidateModel)
            .subscribe(
                response => {
                    console.log('ICE aday� sunucuya ba�ar�yla g�nderildi:', response);
                },
                error => {
                    console.error('ICE aday� g�nderilirken hata olu�tu:', error);
                }
            );
    }
    sendGroupOfferToServer(offer: RTCSessionDescription | null, participantId): void {
        const offerModel: OfferGroupModel = {
            Type: offer.type as RTCSdpType,
            Sdp: offer.sdp,
            participantId: participantId
        };

        this.http.post(this.appInfo.appUrl + 'api/user/offergroup', offerModel)
            .subscribe(
                response => {
                    console.log('Teklif sunucuya ba�ar�yla g�nderildi:', response);
                },
                error => {
                    console.error('Teklif g�nderilirken hata olu�tu:', error);
                }
            );
    }

    sendGroupAnswerToServer(answer: RTCSessionDescription | null, participantId): void {
        if (!answer) {
            console.error('Ge�ersiz cevap, sunucuya g�nderilemiyor.');
            return;
        }

        const answerModel: AnswerGroupModel = {
            Type: answer.type as RTCSdpType,
            Sdp: answer.sdp,
            participantId: participantId
        };

        this.http.post(this.appInfo.appUrl + 'api/user/answergroup', answerModel)
            .subscribe(
                response => {
                    console.log('Cevap sunucuya ba�ar�yla g�nderildi:', response);
                },
                error => {
                    console.error('Cevap g�nderilirken hata olu�tu:', error);
                }
            );
    }


}
