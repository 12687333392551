import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

import { ChatService } from '@shared/services/api/chat.service';
import { AuthService, GenericService, UserService, WebMessageService } from '../../../../../@shared/services/api';
import { AppInfoService, EventEmitterService } from '../../../../../@shared/services/common';
import { debounceTime } from "rxjs/operators";
import { Subject, Subscription } from 'rxjs';
@Component({
    selector: 'app-chat-sidebar',
    templateUrl: './chat-sidebar.component.html'
})
export class ChatSidebarComponent implements OnInit {
    // Public
    public contacts;
    public chatGroups;
    modelChanged = new Subject<string>();
    public searchText;
    public chats;
    public selectedIndex = null;
    public userProfile;
    languageLocal: any = "";
    filteredMessages: any[] = [];
    isSeenMessage: boolean = false;
    noResultsVoiceMessage: TemplateRef<any>;
    noResultsTextMessage: TemplateRef<any>;
    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     * @param {CoreSidebarService} _coreSidebarService
     */
    constructor(private _chatService: ChatService, private _coreSidebarService: CoreSidebarService, private genericService: GenericService, public appInfoService: AppInfoService, private _authService: AuthService,
        private _webMessageService: WebMessageService,
        public changeDetectorRef: ChangeDetectorRef,
        public eventEmitterService: EventEmitterService,
        public _userService: UserService) {
        this.modelChanged.pipe(debounceTime(500)).subscribe(() => {

            this.searchMessage();
        })
        this.searchText = "";

        this.eventEmitterService.invokeSelectedChats.subscribe(id => {
            this.setIndex(id);
        })

        this._authService._isLoggedIn.subscribe(data => {
            this.loginStatusOnline = data;
        });
        this._userService.onlineUsersSubject.subscribe(onlineUsers => {
            this.chatOnlineUsers = onlineUsers;
        });
    }
    updateMessage(val) {
        this.modelChanged.next(val);
    }
    searchMessage() {

        this._chatService.searchChatMessage(this.searchText, this.appInfoService.currentUser.id).subscribe(filtered => {
            if (Array.isArray(filtered)) {
                this.filteredMessages = filtered.map(item => {
                    return {
                        ...item,
                        receiver_id: parseInt(item.receiver_id, 10),
                        sender_id: parseInt(item.sender_id, 10)
                    };
                });
            } else {
                this.filteredMessages = [];
            }

        });
    }
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @ViewChild('messageElement') messageElement: ElementRef;
    chatOnlineUsers = [];
    loginStatusOnline = false;

    userStatusClass(_groupId, _type) {
        /*_userId*/
        let _fAll = this.chatGroups.find(x => x.id == _groupId);// ("," + x.UserIds + ",").indexOf("," + _userId + ",")); 
        let _f = _fAll.userIds;
        //let _fArray = _f.split(",");
        if (_type != 2) {
            let fClear1 = "," + _f + ",";
            let fClear2 = "," + this.appInfoService.currentUser.id + ",";
            let _fClear = fClear1.replace(fClear2, ",");
            return this.chatOnlineUsers.findIndex(x => _fClear.indexOf("," + x.id + ",") != -1) == -1 ? " avatar-status-offline " : " avatar-status-online ";
        }
        else {
            return this.chatOnlineUsers.findIndex(x => x.id == _groupId) == -1 ? " avatar-status-offline " : " avatar-status-online ";
        }
    }
    userStatusClassContacts(_groupId, _type) {
        /*_userId*/
        let _fAll = this.contacts.find(x => x.id == _groupId);// ("," + x.UserIds + ",").indexOf("," + _userId + ",")); 
        let _f = _fAll.id;
        //let _fArray = _f.split(",");
        if (_type != 2) {
            let fClear = "," + _f + ",";
            return this.chatOnlineUsers.findIndex(x => fClear.indexOf("," + x.id + ",") != -1) == -1 ? " avatar-status-offline " : " avatar-status-online ";
        }
        else {
            return this.chatOnlineUsers.findIndex(x => x.id == _groupId) == -1 ? " avatar-status-offline " : " avatar-status-online ";
        }
    }
    chatUserList: any = [];
    chatUserListTemp: any = [];
    chatUserListImage: any = [];
    photoAll = [];
    getChatPanelUserList() {
        let loginUserId = this.appInfoService.currentUser.id;
        this.genericService.getChatUserList(loginUserId).subscribe((result: any) => {
            this.chatUserList = result;
            this.chatUserListTemp = result;
            this.chatUserListImage = [];
            this.chatUnReadGlobal = 0;
            this.chatUserList.forEach(f => {
                this.chatUnReadGlobal = this.chatUnReadGlobal + f.unReadCount;
                let _image = { id: f.id, type: f.type, photo: f.photo };
                this.chatUserListImage.push(_image);
                //f.photo = "";
            });
            this.photoAll = [];
            this.chatUserListSearch(null);
            let _this = this;
            setTimeout(function () {
                _this.userListColor();
            }, 1000);

        });
    }
    searchColor: any = "#f18567";
    searchTextColor: any = "#3f1c13";
    userListColor() {
        var listItems = document.querySelectorAll('.friends-list-item');
        if (listItems != undefined) {
            listItems.forEach(e => {
                let listItem = <HTMLElement>e;
                listItem.setAttribute('data-color', this.searchColor);
                listItem.setAttribute('style', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor);
            })
        }
    }
    chatUserListSearchModel = "";
    chatUserListSearch(event) {
        this.chatUserListTemp = this.chatUserList.filter(o => o.name.toLowerCase().indexOf(this.chatUserListSearchModel.toLowerCase()) > -1);
    }
    chatMessageAll: any = [];
    chatMessageboxImage: any = [];
    chatUnReadGlobal = 0;
    messageWithPage(page): void {
        
        if (this.appInfoService.chatSelectedUserGlobal.type != this.appInfoService.chatMessageEnum.NotChat) {
            this.genericService.getMessageWithPage(this.appInfoService.currentUser.id, this.appInfoService.chatSelectedUserGlobal.id, page).subscribe((result: any) => {
                this.chatMessageAll = result;
                this.chatMessageAll.forEach(f => {
                    this.chatUnReadGlobal = this.chatUnReadGlobal + f.unReadCount;
                    let _image = { id: f.userId, photo: f.photo };
                    this.chatMessageboxImage.push(_image);
                    f.photo = "";
                });

                this.scrollToBottom();
                this.getChatPanelUserList();
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            });
        }
        else {
            this.chatMessageAll = [];
            this.scrollToBottom();
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }
    scrollToBottom(): void {
        try {
            let _thisPage = this;
            setTimeout(function () {
                _thisPage.myScrollContainer.nativeElement.scrollTop = _thisPage.myScrollContainer.nativeElement.scrollHeight;
            }, 100);

        } catch (err) { }
    }
    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open Chat
     *
     * @param id
     * @param newChat
     */
    openChat(chatGroup, readingMessageCount) {
        
        this.eventEmitterService.readMessage(readingMessageCount);
        this._chatService.openChat(chatGroup);
        // Reset unread Message to zero
        this.chatGroups.map(user => {
            if (user.id === chatGroup.id) {
                user.unSeenVoiceMsgs = 0;
                user.unReadCount = 0;
            }
        });
        this._chatService.isSeenMessage(chatGroup.id).subscribe((isSeen: boolean) => {
            this.isSeenMessage = isSeen
        });
        this.eventEmitterService.isSeenMessage(this.isSeenMessage);
    }
    openSearchChat(receiver_id: number, id: number) {
        for (let i = 0; i < this.chatGroups.length; i++) {
            if (this.chatGroups[i].id === receiver_id) {
                const selectedChatGroup = this.chatGroups[i];
                this.eventEmitterService.chatName(selectedChatGroup.name);
                this._chatService.openChat(selectedChatGroup);
                break;
            }
        }
        
        this.eventEmitterService.scrollChatMessage(id);
    }

    /**
     * Open Contact
     *
     * @param id
     * @param newChat
     */
    openContact(contact) {
        this._chatService.openContact(contact);

    }

    /**
     * Toggle Sidebar
     *
     * @param name
     */
    toggleSidebar(name) {
        this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    }

    /**
     * Set Index
     *
     * @param index
     */
    setIndex(index: number) {
        this.selectedIndex = index;
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to contacts
        this._chatService.onContactsChange.subscribe(res => {
            this.contacts = res;
        });

        let skipFirst = 0;

        // Subscribe to chat users
        this._chatService.onChatGroupsChange.subscribe(res => {
            this.chatGroups = res;

            // Skip setIndex first time when initialized
            //if (skipFirst >= 1) {
            //    this.setIndex(this.chatGroups.length - 1);
            //}
            //skipFirst++;
        });

        // Subscribe to selected Chats
        //this._chatService.onSelectedChatChange.subscribe(res => {
        //    this.chats = res;
        //});

        // Add Unseen Message To Chat User
        this._chatService.onChatsChange.pipe(first()).subscribe(chats => {
            chats.map(chat => {
                this.chatGroups.map(user => {
                    if (user.id === chat.userId) {
                        user.unSeenVoiceMsgs = chat.unSeenVoiceMsgs;
                    }
                });
            });
        });
        //this.getChatPanelUserList(); //Bu alana tekrardan istek at�l�yor zaten ve resimlerle birlikte fazla kullan�c� oldu�unda performans kay�plar� olmamas� ad�na kapat�ld�.
        // Subscribe to User Profile
        this._chatService.onUserProfileChange.subscribe(response => {
            this.userProfile = response;
        });

        //this.userProfile = this._chatService.userProfile;
    }
    getFilteredTextMessages(): any[] {
        return this.filteredMessages.filter((message) => message.file_name !== 'audio/x-m4a');
    }
    getFilteredVoiceMessages(): any[] {
        return this.filteredMessages.filter((message) => message.file_name === 'audio/x-m4a');
    }

    openCreateGroupPopup() {
        this._chatService.openCreateGroup();
        //this.modalService.open(this.createGroupModal, { centered: false, size: 'sm' });
    }
    getContactPhoto(userIds: string): string {
        const userIdArray = userIds.split(',');
        const userId = parseInt(userIdArray.filter(id => id !== this.userProfile.id.toString())[0]);
        const contact = this._chatService.contacts.find(c => c.id == userId);
        return contact ? contact.photo : '../../../../../assets/img/default-avatar.png';
    }
}
