import { Pipe, PipeTransform } from '@angular/core';
import { CallService } from '../../@shared/services/api/call.service';

@Pipe({
    name: 'callPipe'
})
export class CallPipe implements PipeTransform {

    constructor(private _callService: CallService) { }

    transform(participantsCon: any[], currentUser: any, meetingId: number): any[] {
        if (!participantsCon || !currentUser || !meetingId) {
            return [];
        }

        return participantsCon.filter(participant => {
            return participant.guid === meetingId &&
                participant.from === currentUser && 
                participant.connection;
        });
    }

    private generateConnectionGuid(participant1: any, participant2: any): number {
        const sortedParticipants = [participant1, participant2].sort((a, b) => a - b);
        return parseInt(`${sortedParticipants[0]}${sortedParticipants[1]}`);
    }

    /*tr(usr: any[]) {
        { guid: '', from: '10', to: '78', connection:true }   
        { guid: '', from: '10', to: '16', connection:false }   



        { guid: '', from: '5', to: '10', connection }
        { guid: '', from: '5', to: '16', connection }   
        { guid: '', from: '5', to: '78', connection }   

        { guid: '', from: '16', to: '78', connection }
    }*/
}