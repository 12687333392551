import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { ChatModule } from '../../../../pages/chat/chat.module';
import { NoteModule } from '../../../../pages/Sticky Notes/note.module';
import { NoteRoutingModule } from '../../../../pages/Sticky Notes/note.routing.module';

import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { MenuModule } from '../../components/menu/menu.module';
import { NavbarModule } from '../../components/navbar/navbar.module';
import { VerticalLayoutComponent } from './vertical-layout.component';
import { CallModule } from '../../../../pages/call/call.module';

@NgModule({
    declarations: [VerticalLayoutComponent],
    imports: [RouterModule, CoreCommonModule, ChatModule, NoteModule, CoreSidebarModule, NavbarModule, MenuModule, ContentModule, FooterModule, CallModule],
  exports: [VerticalLayoutComponent]
})
export class VerticalLayoutModule {}
