import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


import FileSystemItem from 'devextreme/file_management/file_system_item';
import UploadInfo from 'devextreme/file_management/upload_info';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { DomSanitizer } from '@angular/platform-browser';

enum Operation {
    Add = 1,
    Remove = 2,
    AddAll = 3,
    RemoveAll = 4
}

enum FileType {
    None = 0,
    Folder = 1,
    File = 2
}

enum UploadType {
    Cancel = 0,
    ChangeName = 1,
    NewVersion = 2
}

@Injectable({
    providedIn: 'root'
})
export class FileManagementService {



    constructor(public appInfo: AppInfoService, private http: HttpClient, public sanitizer: DomSanitizer) {

    }

    getItems(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getitems", body, this.appInfo.httpOptions);
    }

    getVersions(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getversions", body, this.appInfo.httpOptions);
    }

    renameItems(item: any, name: string): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["name"] = name;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/renameitems", body, this.appInfo.httpOptions);
    }

    copyItem(item: any, destinationDir: FileSystemItem): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/copyitem", body, this.appInfo.httpOptions);
    }

    moveItem(item: any, destinationDir: FileSystemItem): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/moveitem", body, this.appInfo.httpOptions);
    }

    deleteItem(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/deleteitem", body, this.appInfo.httpOptions);
    }

    createDirectory(parentDir: any, name: string): Observable<any> {
        let bodyObject = {}
        delete parentDir.Permissions
        bodyObject["parentDir"] = parentDir;
        bodyObject["name"] = name
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/createdirectory", body, this.appInfo.httpOptions);
    }

    lastUpload = null;
    uploadFileChunk(chunkMetadata: any, fileData: File, destinationDir: any, uploadType: any, fileObj: any = { OCR: "", newName: null }, _reportProgress = false): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', fileData, fileData.name);

        let bodyObject = {}
        delete destinationDir.Permissions
        bodyObject["destinationDir"] = destinationDir
        bodyObject["uploadType"] = uploadType
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["fileData"] = fileObj
        bodyObject["newName"] = fileObj.newName
        bodyObject["chunkMetadata"] = chunkMetadata
        bodyObject["chunkBlob"] = chunkMetadata.chunkBlob.size



        formData.append('info', JSON.stringify(bodyObject));

        const url = this.appInfo.appUrl + "api/filemanagement/uploadfileNewChunky"
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'Access-Control-Allow-Origin': url,
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
            'Access-Control-Allow-Credentials': 'true'

        })
        let _others = _reportProgress ? { headers, reportProgress: true, observe: 'events' } : { headers };
        return this.http.post<any>(url, formData, (_others as any)); 
    }
    uploadFileOldChunk(chunkMetadata: any, fileData: File, destinationDir: any, uploadType: any, fileObj: any = { OCR: "", newName: null }) {
        const formData: FormData = new FormData();
        formData.append('file', fileData, fileData.name);

        let bodyObject = {}
        delete destinationDir.Permissions
        bodyObject["destinationDir"] = destinationDir
        bodyObject["uploadType"] = uploadType
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["fileData"] = fileObj
        bodyObject["newName"] = fileObj.newName
        bodyObject["chunkMetadata"] = chunkMetadata
        bodyObject["chunkBlob"] = chunkMetadata?.chunkBlob.size



        formData.append('info', JSON.stringify(bodyObject));

        const url = this.appInfo.appUrl + "api/filemanagement/uploadfilechunky"
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'Access-Control-Allow-Origin': url,
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
            'Access-Control-Allow-Credentials': 'true'

        })

        return this.http.post<any>(url, formData, { headers });

    }

    uploadFileToDMS(fileData: File, path: string) {

        const formData: FormData = new FormData();
        formData.append('file', fileData, fileData.name);

        let bodyObject = {}
        bodyObject["path"] = path
        bodyObject["userId"] = this.appInfo.currentUser.id

        formData.append('info', JSON.stringify(bodyObject));

        const url = this.appInfo.appUrl + "api/filemanagement/uploadfiletodmspath"
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'Access-Control-Allow-Origin': url,
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
            'Access-Control-Allow-Credentials': 'true'

        })

        return this.http.post<any>(url, formData, { headers });

    }

    getDmsPathForKanban(): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getDmsPathForKanban", this.appInfo.httpOptions)
    }

    abortFileUpload(fileData: File, chunksInfo: UploadInfo, destinationDir: any) {
        let bodyObject = {}
        delete destinationDir.Permissions
        bodyObject["fileData"] = fileData;
        bodyObject["chunkMetadata"] = chunksInfo
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/abortFileUpload", body, this.appInfo.httpOptions);
    }
    /*
    downloadItem(fileSystemItems: any) {

        let bodyObject = {}
        delete fileSystemItems.Permissions
        bodyObject["fileSystemItems"] = fileSystemItems;
        bodyObject["userId"] = this.appInfo.currentUser.id
        console.log(fileSystemItems)
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/downloadItem", body, this.appInfo.httpOptions)

    }

    downloadVersion(fileId: any) {

        let bodyObject = {}
        bodyObject["fileDataId"] = fileId;
        bodyObject["userId"] = this.appInfo.currentUser.id

        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/downloadVersion", body, this.appInfo.httpOptions)

    }*/

    savePermission(fileItem: any, updateAllChilds = false) {

        let bodyObject = {}
        bodyObject["updateAllChilds"] = updateAllChilds;
        bodyObject["permissionJsonString"] = JSON.stringify(fileItem.Permissions)
        bodyObject["userId"] = this.appInfo.currentUser.id
        delete fileItem.Permissions
        bodyObject["fileItem"] = fileItem

        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/savefilepermission", body, this.appInfo.httpOptions)
    }

    saveSettings(settings: any) {

        let bodyObject = {}
        bodyObject["fileManagementSettings"] = settings
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/savefilemanagementsettings", body, this.appInfo.httpOptions)
    }

    getFileManagementSettings() {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getfilemanagementsettings", this.appInfo.httpOptions)
    }

    getUserPermissionsFromFileManagementSettings() {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getUserPermissionsFromFileManagementSettings", body, this.appInfo.httpOptions)
    }

    checkFileNameForNewVersionOrNewFileUpload(fileData: any, destinationDir: any) {
        let bodyObject = {}
        bodyObject["fileName"] = fileData.name
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/checkFileNameForNewVersionOrNewFileUpload", body, this.appInfo.httpOptions)
    }

    searchFile(search, filter) {
        let bodyObject = {}
        bodyObject["search"] = search;
        bodyObject["filter"] = filter;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/searchFile", body, this.appInfo.httpOptions)
    }

    downloadFileManagementDataVM(fileId, type) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["type"] = type
        bodyObject["fileDataId"] = fileId
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/downloadFileManagementDataVM", body, this.appInfo.httpOptions)
    }
    downloadFromListFileManagementDataVMG(guid, type) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/downloadFromListFileManagementDataVMG?userId=" + this.appInfo.currentUser.id + "&type=" + type + "&guid=" + guid, { ...this.appInfo.httpOptions, responseType: 'blob' as 'json' })
    }

    public saveFileManagementItemVM(fileItem, type, updatedItem) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["type"] = type
        bodyObject["fileItem"] = fileItem
        bodyObject["updatedItem"] = updatedItem
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/saveFileManagementItemVM", body, this.appInfo.httpOptions)
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    addGuid(model: any) {

        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/addguid", JSON.stringify(model), this.appInfo.httpOptions);

    }


    getExistGuid(id: number) {

        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getexistguid?id=" + id, this.appInfo.httpOptions);

    }
    findMatchedGuid(guid: string) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/findmatchedguid?guid=" + guid, this.appInfo.httpOptions);

    }
    public getItemsByIdList(fileIds) {
        let params = new HttpParams().set("itemIds", fileIds);

        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getitemsbyidlist", JSON.stringify(fileIds), this.appInfo.httpOptions)

    }

    public getItemsByComponent(componentName) {
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getHelpFile?componentName=" + componentName, this.appInfo.httpOptions);

    }

    getFileLogs(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getitemslog", body, this.appInfo.httpOptions);
    }
    public recoverFileItem(fileItem) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["fileItem"] = fileItem
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/recover", body, this.appInfo.httpOptions)
    }
    public compareFiles(firstId, secondId, type, userId) {
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/compareselectedfiles?firstId=" + firstId + "&secondId=" + secondId + "&type=" + type + "&userId=" + userId, this.appInfo.httpOptions);

    }

    public convertFileToBlob(fileSrc: string): Observable<Blob> {
        return this.http.get(fileSrc, { responseType: 'blob' });
    }

    getGalleryItems(): Observable<any> {
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getgalleryitems", this.appInfo.httpOptions);
    }
    getItemByParentId(id: number, userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getitembyparent?id=" + id + "&userId=" + userId, this.appInfo.httpOptions);
    }
    getItemDatabyId(id: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getitemdatabyid?id=" + id, this.appInfo.httpOptions);
    }

    getDmsDataPermission(id: number[]) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id;
        bodyObject["id"] = id;
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getdmspermission", body, this.appInfo.httpOptions);
    }

    uploadExcelFile(base64Data: string, userId: string, fileName: string): Observable<any> {

        const requestData = {
            base64Data: base64Data,
            userId: userId,
            fileName: fileName
        };

        const apiUrl = `${this.appInfo.appUrl}api/filemanagement/uploadExcelFile`;

        return this.http.post<any>(apiUrl, requestData);
    }

    clearExcelFile(userId: number, fileName: string): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/clearExcelFile?userId=" + userId + "&fileName=" + fileName, this.appInfo.httpOptions);
    }

    getSignatures(userId: number): Observable<any> {
        return this.http.post<any>(`${this.appInfo.appUrl}api/filemanagement/getsignexe?userId=${userId}`, {}, this.appInfo.httpOptions);
    }

    processFileWithSignature(fileId: number, signature: number): Observable<any> {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["type"] = 1
        bodyObject["fileDataId"] = fileId
        bodyObject["signature"] = signature

        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/processFileWithSignature", bodyObject, this.appInfo.httpOptions);
    }







    /////
    /// DIGITAL SIGNATURE
    /////

    fileDigitalSignature(fileId: number, _cert: string, _digitalSignature: any): Observable<any> {
        let bodyObject = {};
        bodyObject["userId"] = this.appInfo.currentUser.id;
        bodyObject["fileId"] = fileId;
        bodyObject["certificate"] = _cert;

        bodyObject["format"] = _digitalSignature.format;
        bodyObject["algorithm"] = _digitalSignature.algorithm;
        //bodyObject["pin"] = _digitalSignature.pin;
        return this.http.post<any>(`${this.appInfo.appUrl}api/filemanagement/filedigitalsign`, JSON.stringify(bodyObject), this.appInfo.httpOptions);
    }

    fileDigitalSignatureComplete(signatures: string, guid: string /*datatosign: string, certificate: string, pCode: string*/): Observable<any> {
        let bodyObject = {};
        bodyObject["userId"] = this.appInfo.currentUser.id;
        bodyObject["signatures"] = signatures;
        //bodyObject["datatosign"] = datatosign;
        //bodyObject["certificate"] = certificate;
        //bodyObject["pCode"] = pCode;
        bodyObject["guid"] = guid;
        return this.http.post<any>(`${this.appInfo.appUrl}api/filemanagement/filedigitalsigncomplete`, JSON.stringify(bodyObject), this.appInfo.httpOptions);
    }

    invokeCompleteSignEvent = new EventEmitter();
    rds_pageInfo = "";
    try_send = 3;
    sending_message = "";
    need_to_Rehandshake = !1;

    isOpera = !!(window as any).opr && !!(window as any).opr.addons || !!(window as any).opera || navigator.userAgent.indexOf(" OPR/") >= 0;
    isFirefox = "undefined" != typeof (window as any).InstallTrigger;
    isSafari = /constructor/i.test((window as any).HTMLElement) || "[object SafariRemoteNotification]" === (!(window as any).safari || "undefined" != typeof (window as any) && (window as any).pushNotification).toString();
    isIE = !!(document as any).documentMode;
    isEdge = !this.isIE && !!(window as any).StyleMedia;
    isChrome = !(!(window as any).chrome || !(window as any).chrome.webstore && !(window as any).chrome.runtime);

    signService_uri = "SignArtSignService";
    signatureWebSocket;
    webSocket_port = 10100;
    is_websocket_connected;
    last_port = this.webSocket_port + 5;
    RDSCertificate = "";
    //complateBeforeSignatures;

    RDSWebSocketConnection(e) {
        debugger;
        if ("WebSocket" in window)
            this.startWebSocket(e);
        else {
            var t = '<span  class="RDS-Error">Tarayıcı bu işlemi yapmak için uygun değil. Bir sorun ile karşılaşılırsa farklı bir tarayıcı üzerinden deneme yapınız!<br><br></span>';
            this.consolelog(t)
        }
    }


    consolelog(e) {
        console.log(e)
        this.setPageInfo(e);
    };
    setPageInfo(_info) {
        this.rds_pageInfo = _info + "<br>" + this.rds_pageInfo;
    }
    getPageInfo() {
        return this.sanitizer.bypassSecurityTrustHtml(this.rds_pageInfo)
    }

    startWebSocket(e) {
        let _that = this;
        (_that.signatureWebSocket = new WebSocket("wss://local.signservice.signart.tech:" + _that.webSocket_port + "/" + _that.signService_uri + "/")).onopen = function (e) {
            try { _that.signatureWebSocket.send("<SLM>"); _that.consolelog("Bağlantı Açıldı...") } catch { }
        }, _that.signatureWebSocket.onerror = function (t) {
            _that.is_websocket_connected = !1,
                _that.consolelog("Bağlantı Hatalı..."/*Connection error...*/),
                (_that.webSocket_port += 1) == _that.last_port ?
                    (_that.webSocket_port = 10100,
                        this.consolelog("Bağlantı Reddedildi..."/*Refused connection...*/),
                        this.setPageInfo("E-İmza uygulamasının makinenizde kurulu olduğunu ve imzalama işlemi sırasında uygulamanın çalışır durumda olduğunu kontrol ediniz.")) :
                    _that.RDSWebSocketConnection(e)
        }, _that.signatureWebSocket.onmessage = function (e) {
            if ("string" == typeof e.data)
                if (0 === e.data.toString().indexOf("<ASLM>")) _that.consolelog("Senkronizasyon Sağlandı. ***** portu üzerinden soket açıldı." /*"Handshake is done... Socket is opened on port *****"*/), _that.webSocket_port = 10100, _that.is_websocket_connected = !0, "" !== _that.sending_message && (_that.send_message_to_socket(_that.sending_message), _that.sending_message = "");
                else {
                    var t = e.data;
                    if (0 === t.indexOf("allCertificates:")) {
                        t = t.slice("allCertificates:".length);
                        var n = JSON.parse(t);
                        /// İleride birden fazla sertifika olma ihtimaline karşın burası düzenlenecek. ; for (var i = 0; i < n.certlist.length; i++) 
                        let c = n.certlist[0];//n.certlist[i];
                        //if (typeof _that.RDSCertificate == "undefined" || _that.RDSCertificate == "") {
                        let o = {
                            id: c.serialNumber,
                            value: c.certificate,
                            certname: c.subject,
                            enddate: c.validTo,
                            tckn: c.tckn
                        }
                        _that.RDSCertificate = c.certificate;
                        _that.showRDS(o)
                        //}
                        //else {

                        //}

                    } else if (0 === t.indexOf("signedDatas:")) {
                        t = t.slice("signedDatas:".length);
                        n = JSON.parse(t);
                        debugger;
                        /*_that.complateBeforeSignatures = n.signatures,*/  _that.completeSign(n.signatures);
                    } else 0 === t.indexOf("Error:") ? (t = t.slice("Error:".length), _that.consolelog(`<span  class="RDS-Error">${t}</span>`), _that.RDSCertificate = "") : 0 === t.indexOf("connected:") ? (t = t.slice("connected:".length), _that.consolelog(t)) : (_that.consolelog("Message received from another app. Connection will be closed"), _that._webSocketDisconnect())
                }
            else _that.consolelog("Binary message received...")
        }, _that.signatureWebSocket.onclose = function (e) {
            _that.is_websocket_connected = !1, _that.consolelog("Bağlantı Kapatıldı...")
        }
    }


    send_message_to_socket(e) {
        debugger;
        let _that = this;
        var t = e;
        if (!0 === _that.is_websocket_connected) {
            if (_that.try_send = 3, 1 !== _that.signatureWebSocket.readyState) return _that.consolelog("Bağlantı kapalıyken veri gönderemezsiniz." + e/*Cannot send data when the connection is closed...*/), "ERROR";
            (_that.isFirefox || _that.isEdge || _that.isIE) && (_that.need_to_Rehandshake = !0), _that.need_to_Rehandshake && _that.signatureWebSocket.send("<SLM>"), _that.signatureWebSocket.send(e)
        } else _that.RDSWebSocketConnection(t), _that.sending_message = e
    }

    signDataKey: any;
    signInSocket(dataToSigns, signCertificate, pinCode, guid) {
        this.signDataKey = guid
        var e = {
            signAllDatas: [{
                datatosign: dataToSigns
            }, {
                certificate: signCertificate
            }, {
                pCode: btoa(pinCode)
            }]
        };
        this.send_message_to_socket(JSON.stringify(e))
    }
    completeSignEvent = undefined;
    completeSign(signatures) {
        this.fileDigitalSignatureComplete(signatures, this.signDataKey
            /*, this.signAllData.datatosign, this.signAllData.certificate, this.signAllData.pCode*/).subscribe(response => {
            debugger;
            this.invokeCompleteSignEvent.emit(response);
        });
    }
    destroyInvokeCompleteSign() {
        try { if (typeof this.completeSignEvent != "undefined") this.completeSignEvent.unsubscribe(); } catch (err) { }
    }
    showRDS(e) {
        this.setPageInfo("</br>" + `<table style="width:100%">
        <tr><td>Sertifika Sahibi: </td><td>&nbsp;</td><td><b>${e.certname}</b></td></tr>
        <tr><td>TC Kimlik No: </td><td>&nbsp;</td><td><b>********${e.tckn.slice(-3)}</b></td></tr>
        <tr><td>Son Geçerlilik Tarihi: </td><td>&nbsp;</td><td><b>${e.enddate}</b></td></tr>
        </table>` + "</br>");
    }
    _webSocketDisconnect() {
        this.consolelog("Bağlantı Kapatılıyor..."/*Closing connection...*/), this.signatureWebSocket.close()
        this.RDSCertificate = "";
        this.signatureWebSocket = undefined;
        this.webSocket_port = 10100;
        this.is_websocket_connected = undefined;
        this.last_port = this.webSocket_port + 5;
        this.try_send = 3;
        this.sending_message = "";
        this.need_to_Rehandshake = !1;

    }

}
