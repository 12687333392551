import { ChangeDetectorRef,Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppInfoService } from '@shared/services/common/app-info.service';
import moment from 'moment';
import { ProfileService } from '../../../../../pages/profile/profile.service';
import { ComponentService, EventEmitterService, LocalStorageService } from '../../../../../../@shared/services/common';
import { AuthService, InstanceService, KanbanService, UserService, } from '../../../../../../@shared/services/api';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NotificationsService } from '../../../../../../@shared/services/api/notifications.service';
import { NotificationModel } from './notifications.model';
import { dateTimePeriods, objectDataSourceSerializationsInfo } from 'devexpress-dashboard/model/index.metadata';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { PageDesignerService } from '../../../../../../@shared/services/api/pagedesigner.service';
import { ToastrModule, ToastrService, ToastNoAnimation, ToastNoAnimationModule, Toast } from 'ngx-toastr';
import { locale as english } from 'app/auth/data/en'
import { locale as french } from 'app/auth/data/fr';
import { locale as german } from 'app/auth/data/de';
import { locale as turkish } from 'app/auth/data/tr';
import { locale as arabic } from 'app/auth/data/ar';
import { locale as azerbaijani } from 'app/auth/data/az';
import { locale as georgian } from 'app/auth/data/geo';
import { locale as dutch } from 'app/auth/data/nl';
import { locale as italian } from 'app/auth/data/it';
import { locale as russian } from 'app/auth/data/ru';

import { CoreTranslationService } from '../../../../../../@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
    selector: 'app-navbar-notification',
    templateUrl: './navbar-notification.component.html',
    styleUrls: ['./navbar-notification.component.scss'],

})
export class NavbarNotificationComponent implements OnInit, OnDestroy {
   
    private _unsubscribeAll: Subject<void>;
    users: any[] = [];
    selectedTab: string = "";
    pageSize: number = 0;
    isTrue: boolean;
    showDeletedNotifications: boolean;
    pageType: number = 1;
    instanceNumber: any;
    public taskHistory: any = [];
    selectUser: any;
    _ReadMoreBoard: boolean;
    _ReadMoreProcess: boolean = false;
    _ReadMoreSocial: boolean = false;
    deletedItems: boolean = true;
    commentData: any[] = [];
    boards: any[] = []
    newBoards: any;
    historyPopup: boolean = false;
    scrollTimeout: any;
    newSocial: any;
    ReadMoreSocial: boolean = false;
    ReadMoreProcess: boolean = false;
    ReadMoreBoard: boolean = false;
    socialNotifiCount: number = 0;
    processNotifiCount: number = 0;
   deletedBoardNotifiCount: number = 0;
    deletedSocialNotifiCount: number = 0;
    deletedProcessNotifiCount: number = 0;
    boardNotifiCount: number = 0;
    processes: any[] = []
    deletedProcesses: any[] = [];
    deletedBoards: any[] = [];
    newProcess: number;
    deletedSocial: any[] = [];
    socialNotify: any[] = []
    socialToastNotify: any[] = [];
    boardToastNotify: any[] = [];
    postOrComment: string;
    groupControl: boolean = false;
    loadingVisible: boolean;

    pageTypes = {
        processPage: 1,
        boardPage: 2,
        socialPage: 3
    }
    isLoggedInControl: boolean = true;
    notifyPageSize: number = 15;
    socialPageNo: number = 1;
    boardPageNo: number = 1;
    processPageNo: number = 1;
    deletedProcessPageNo: number = 1;
    deletedBoardPageNo: number = 1;
    deletedSocialPageNo: number = 1;
    toastNotifyLang: string;


    invokeNotificationManagementEvent: Subscription;
    invokeLogOutFunc: Subscription;
    openFormFromNotify: Subscription;
    constructor(private _notificationsService: NotificationsService, private appInfo: AppInfoService, public toaster: ToastrService, private profileService: ProfileService, private _userService: UserService, private _authService: AuthService, public componentService: ComponentService, public _pageService: PageDesignerService, public instanceService: InstanceService, public changeDetectorRef: ChangeDetectorRef, private spinner: NgxSpinnerService,
        private _eventEmitterService: EventEmitterService, private router: Router, private _coreTranslationService: CoreTranslationService,
        public _translateService: TranslateService) {
        this._coreTranslationService.translate(english, french, german, turkish, arabic, azerbaijani, georgian, dutch, italian, russian);

        this.getAllNotificationsCount();
        this.showDeletedNotificationsAuthFunc();
        this.selectUser = this.appInfo.currentUser.id;

        this.invokeLogOutFunc= this._eventEmitterService.invokeLogOutFunc.subscribe((sub: any) => {
            this.isLoggedInControl = sub.data;
        });
        
      
         
        this.invokeNotificationManagementEvent = this._eventEmitterService.invokeNotificationManagementEvent.subscribe((sub: any) => {
           
            if (sub.data.groups != null && sub.data.groups != "") {
                let userGroups = this.appInfo.currentUser.groupIds.split(',').map(Number);
                this.groupControl = userGroups.some(groupId => sub.data.groups.includes(groupId));
            }

            let searchStr = sub.data.users !== null && sub.data.users !== undefined ? sub.data.users : "";
            let target = this.selectUser !== null && this.selectUser !== undefined ? this.selectUser.toString() : "";


            if (searchStr !== null && target !== null && (searchStr.indexOf(target) !== -1 || this.groupControl)) {

                if (sub.data.type == 1 || sub.data.type == 2) {
                    this.getNotificationCountByPageType(this.pageTypes.socialPage);

                    if (sub.data.id != 0) {
                        this._notificationsService.getToastNotificationData(sub.data.id, this.appInfo.currentUser.id).subscribe(data => {
                            this.socialToastNotify = data;
                            let _type = sub.data.type
                            let socialToastNotify = this.notificationTextGenerator(_type, this.socialToastNotify[0].userName, this.socialToastNotify[0].body, this.socialToastNotify[0].notifyDate);
                            if (this.socialNotify != null) {
                                this.toaster.success(socialToastNotify, '', {

                                    toastClass: 'toastr ngx-toastr ',
                                    easeTime: 2000,
                                    timeOut: 40000,
                                    positionClass: 'toast-bottom-right',
                                    closeButton: true
                                });
                            }

                        });
                    }                

                    this.socialToastNotify = [];
                }
                else if (sub.data.type != 0 && sub.data.type != 11) {
                    this.getNotificationCountByPageType(this.pageTypes.boardPage);
                    
                    this._notificationsService.getToastNotificationData(sub.data.id, this.appInfo.currentUser.id).subscribe(data => {
                        if (data != null && typeof data != "undefined" && data.length > 0) {
                            this.boardToastNotify = data;
                            let boardToastNotifyLang = this.notificationTextGenerator(sub.data.type, this.boardToastNotify[0].userName, this.boardToastNotify[0].body, this.boardToastNotify[0].subject);

                            if (this.boardToastNotify != null) {

                                this.toaster.success(boardToastNotifyLang, '', {
                                    toastClass: 'toastr ngx-toastr ',
                                    easeTime: 2000,
                                    timeOut: 40000,
                                    positionClass: 'toast-bottom-right',
                                    closeButton: true
                                });
                            }
                        }

                    })
                }
                else {
                    this.getNotificationCountByPageType(this.pageTypes.processPage);

                    this._notificationsService.getToastNotificationData(sub.data.id, this.appInfo.currentUser.id).subscribe(data => {
                        let processToastNotify = this.notificationTextGenerator(sub.data.type, data[0].startedPersonName, data[0].processName, "");

                        this.toaster.success(processToastNotify, '', {
                            toastClass: 'toastr ngx-toastr ',
                            easeTime: 2000,
                            timeOut: 40000,
                            positionClass: 'toast-bottom-right',
                            closeButton: true
                        });
                      
                    })


                }
            }
          



        })
     
        this._unsubscribeAll = new Subject();
        this._authService._isLoggedIn.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => { // navbarda,menüde ve home componentte çalışırken _isLoggedIn kotrolü yapılması gerekiyor.
            if (data == true) {
                this._userService.onUsersChanged.subscribe(result => {
                    if (this.users.length != result.length && result.length > 0) {
                        this.users = result;

                    }



                })
            }

        })

    }



    findUserName(id: number): string {
        const user = this.users.find(u => u.id === id);
        return user ? user.fullname : '';
    }

    findBoardName(pageId) {
        return this._pageService.getPageIdById(pageId);
    }


    findUserPhoto(id: number): string {
        const user = this.users.find(u => u.id === id);

        return user ? user.custom : '../../../../../../../assets/img/default-avatar.png';
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        try { if (typeof this.invokeNotificationManagementEvent != "undefined") this.invokeNotificationManagementEvent.unsubscribe(); } catch (err) { console.error(err) }
        try { if (typeof this.invokeLogOutFunc != "undefined") this.invokeLogOutFunc.unsubscribe(); } catch (err) { console.error(err) }

        
     
    }
   

    openNotificationTab(pageType: number) {
        if (!this.deletedItems) {
            if (pageType == this.pageTypes.processPage) {
                this.getDeletedProcessData();
            }
            else if (pageType == this.pageTypes.boardPage) {
                this.getDeletedBoardData();
            }
            else {
                this.getDeletedSocialData();

            }
        }
        else {
            if (pageType == this.pageTypes.processPage) {
                this.getProcessNotificationsByPageType();
            }
            else if (pageType == this.pageTypes.boardPage) {
                this.getBoardNotificationsByPageType();

            }
            else {
                this.getProfileNotificationsByPageType();
            }
        }

    }
    getAllNotificationsCount() {
        /*this.getNotificationCountByPageType(this.pageTypes.boardPage);  3 ayrı istek attığımızdan dolayı kaldırıldı tek tek istek atmak yerine hepsini tek seferde çekebildiğimiz bir API yazıldı.
        this.getNotificationCountByPageType(this.pageTypes.processPage);
        this.getNotificationCountByPageType(this.pageTypes.socialPage);*/
        this._notificationsService.getAllNotificationCount(this.appInfo.currentUser.id).subscribe(data => {
            this.processNotifiCount = data.processCount;
            this.boardNotifiCount = data.boardCount;
            this.socialNotifiCount = data.socialCount;
        });
    }
    getAllDeletedNotificationsCount() {
        /*this.getDeletedNotificationsCountByPageType(this.pageTypes.boardPage);  3 ayrı istek attığımızdan dolayı kaldırıldı tek tek istek atmak yerine hepsini tek seferde çekebildiğimiz bir API yazıldı.
        this.getDeletedNotificationsCountByPageType(this.pageTypes.processPage);
        this.getDeletedNotificationsCountByPageType(this.pageTypes.socialPage);*/
        this._notificationsService.getAllDeletedNotificationCount(this.appInfo.currentUser.id).subscribe(data => {
            this.deletedSocialNotifiCount = data.socialCount;
            this.deletedBoardNotifiCount = data.boardCount;
            this.deletedProcessNotifiCount = data.processCount;
        });

    }
    
    notificationTextGenerator(type: number, user: string, subject: string, eventName: string) {
        debugger;
        let action = "";
        if (type == 1 || type == 2) {
            this.toastNotifyLang = this._translateService.instant('socialToastNotification', {
                0: user,
                1: subject,
                2: eventName
            });
            return this.toastNotifyLang;
        }
        else if(type == 12){
            this.toastNotifyLang = this._translateService.instant('repostToastNotification', {
                0: user,
                1: subject,
                2: eventName
            });
            return this.toastNotifyLang;
        }
        else if (type != 0 && type != 11) {

            if (type == 3) {
                action = this._translateService.instant('addedTask')
            }
            else if (type == 4) {
                action = this._translateService.instant('updatedTask')

            }
            else if (type == 5) {
                action = this._translateService.instant('messageAddedToTask')

            }
            else if (type == 6) {
                action = this._translateService.instant('deleteTask')

            }
            else if (type == 7) {
                action = this._translateService.instant('completedTask')

            }
            this.toastNotifyLang = this._translateService.instant('boardToastNotification', {
                0: user,
                1: eventName,
                2: subject,
                3: action

            });
            return this.toastNotifyLang;
        }
        else {
            this.toastNotifyLang = this._translateService.instant('socialToastNotification', {
                0: user,
                1: subject,
            });
            return this.toastNotifyLang;
        }



    }
    getBoardNotificationsByPageType() {
  
        this._notificationsService.getAllNotificationsByPageType(this.appInfo.currentUser.id, this.pageTypes.boardPage, this.boardPageNo, this.notifyPageSize).subscribe(data => {


            if (this.boardPageNo == 1) {
                this.boards = data.filter(f => !f.isDeleted);
              
                this._notificationsService.getNotificationCount(this.appInfo.currentUser.id, this.pageTypes.boardPage).subscribe(data => {
                    if (data) {

                        if (this.boardNotifiCount > this.boards.length)
                            this._ReadMoreBoard = true;

                        else {
                            this._ReadMoreBoard = false;
                        }
                    }
                })

            }
            else {
                if (data.length) {
                    let filteredBoardData = data.filter(f => !f.isDeleted);
                    this.boards = [...this.boards, ...data];

                    this.boards = this.boards.filter(f => !f.isDeleted)
                    this._notificationsService.getNotificationCount(this.appInfo.currentUser.id, this.pageTypes.boardPage).subscribe(data => {
                        if (data) {
                            if (this.boardNotifiCount > this.boards.length)
                                this._ReadMoreBoard = true;
                        }
                    })
                    
                  
                }
                else {
                    this.boards = this.boards.filter(f => !f.isDeleted)
                    
                    this._ReadMoreBoard = false;
                }
            }



            const filteredData = this.boards.filter(item => item.isRead === false && item.isDeleted === false);
            this.newBoards = filteredData.length;
           
        });
    }

    getProfileNotificationsByPageType() {
        this._notificationsService.getAllNotificationsByPageType(this.appInfo.currentUser.id, this.pageTypes.socialPage, this.socialPageNo, this.notifyPageSize).subscribe(data => {
             var notifyList = data.map(notification => {
                const mappedNotification = {

                    actionUserId: notification.actionUserId,
                    content: notification.body,
                    likeDate: notification.notifyDate,
                    type: notification.type,
                    userName: notification.userName,
                    isRead: notification.isRead,
                    userPhoto: this.findUserPhoto(notification.actionUserId),
                    id: notification.id,
                    isDeleted: notification.isDeleted
                };
                return mappedNotification;
             });
            if (this.socialPageNo == 1) {
                let filteredSocial = notifyList.filter(f => !f.isDeleted);
                this.socialNotify = filteredSocial;
                this.getNotificationCountByPageType(this.pageTypes.socialPage);

                if (this.socialNotifiCount > this.socialNotify.length) {
                    this._ReadMoreSocial = true;

                }
                else {
                    this._ReadMoreSocial = false;

                }

            }
            else {
                if (notifyList.length) {
                    this.getNotificationCountByPageType(this.pageTypes.socialPage);
                    let _filteredSocial = notifyList.filter(f => !f.isDeleted);
                    this.socialNotify.push(..._filteredSocial);
                    this._notificationsService.getNotificationCount(this.appInfo.currentUser.id, this.pageTypes.socialPage).subscribe(data => {
                        if (data) {
                            if (this.socialNotifiCount > this.socialNotify.length) {
                                this._ReadMoreSocial = true;

                            }
                            else {
                                this._ReadMoreSocial = false;

                            }
                        }
                    })
                   
                    
                }


            }
            // Sosyal bildirim sekmesinde kullanıcı fotoğrafları gösterilmesi için istek atıp fotoğrafları çekiyoruz.
            this.socialNotify.forEach(n => {
                this._userService.getUserPhotoById(n.actionUserId).subscribe(response => {
                    if (response) {
                        n.userPhoto = '';
                        n.userPhoto = `data:image/png;base64,${response}`;
                    }
                });
            });

            const filteredData = this.socialNotify.filter(item => item.isRead === false && item.isDeleted === false);
           
            this.newSocial = filteredData.length;


        })
    }
    getProcessNotificationsByPageType() {
        
        this._notificationsService.getAllNotificationsByPageType(this.appInfo.currentUser.id, this.pageTypes.processPage, this.processPageNo, this.notifyPageSize).subscribe(data => {
            //this.getNotificationCountByPageType(this.pageTypes.processPage);
            //aşağıdaki istekte data zaten geliyor hem _ReadMoreProcess hemde count aşağıda alınabilir tekrar tekrar istek atmaya gerek kalmadan..
            if (this.processPageNo == 1) {
                this.processes = data.filter(f => !f.isDeleted);
                this._notificationsService.getNotificationCount(this.appInfo.currentUser.id, this.pageTypes.processPage).subscribe(data => {
                    if (data) {
                        if (this.pageTypes.processPage == this.pageTypes.socialPage) {
                            this.socialNotifiCount = data;
                        }
                        else if (this.pageTypes.processPage == this.pageTypes.boardPage) {
                            this.boardNotifiCount = data;
                        }
                        else if (this.pageTypes.processPage == this.pageTypes.processPage) {
                            this.processNotifiCount = data;
                        }
                        if (this.processNotifiCount > this.processes.length) {
                            this._ReadMoreProcess = true;
                        }
                        else {
                            this._ReadMoreProcess = false;
                        }
                    }
                })
              
            }
            else {
                if (data.length) {
                    this.processes.push(...data);
                    if (this.processNotifiCount > this.processes.length) {
                        this._ReadMoreProcess = true;

                    }
                    else {
                        this._ReadMoreProcess = false;
                    }
                }

            }

            const filteredData = this.processes.filter(item => item.isRead === false && item.isDeleted === false);
            this.newProcess = filteredData.length;            
        });
        // Sosyal bildirim sekmesinde kullanıcı fotoğrafları gösterilmesi için istek atıp fotoğrafları çekiyoruz.
        this.socialNotify.forEach(n => {
            this._userService.getUserPhotoById(n.actionUserId).subscribe(response => {
                if (response) {
                    n.userPhoto = '';
                    n.userPhoto = `data:image/png;base64,${response}`;
                }
            });
        });
    }



    
    ngOnInit() {
      
    }
    getAllDeletedNotifications() {
        this.getDeletedBoardData()
        this.getDeletedProcessData()
        this.getDeletedSocialData();
    }
    getDeletedBoardData() {
        this._notificationsService.getDeletedNotificationsByPageType(this.appInfo.currentUser.id, this.pageTypes.boardPage, this.deletedBoardPageNo, this.notifyPageSize).subscribe(data => {
            //this.getDeletedNotificationsCountByPageType(this.pageTypes.boardPage);
            if (this.deletedBoardPageNo == 1) {
                this.deletedBoards = data;

                this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, this.pageTypes.boardPage).subscribe(data => {
                    if (data) {

                        if (this.deletedBoardNotifiCount > this.deletedBoards.length)
                            this.ReadMoreBoard = true;

                        else {
                            this.ReadMoreBoard = false;
                        }
                    }
                })
            }
            else {
                if (data.length) {
                    this.deletedBoards.push(...data);
                    this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, this.pageTypes.boardPage).subscribe(data => {
                        if (data) {
                            if (this.deletedBoardNotifiCount > this.deletedBoards.length) {
                                this.ReadMoreBoard = true;
                            }
                            else {
                                this.ReadMoreBoard = false;
                            }

                        }
                    })
                }
               
            }

        })
    }
    getDeletedSocialData() {
        this._notificationsService.getDeletedNotificationsByPageType(this.appInfo.currentUser.id, this.pageTypes.socialPage, this.socialPageNo, this.notifyPageSize).subscribe(data => {
            


            var _notifyList = data.map(notification => {
                const mappedNotification = {

                    actionUserId: notification.actionUserId,
                    content: notification.body,
                    likeDate: notification.notifyDate,
                    type: notification.type,
                    likedBy: notification.userName,
                    isRead: notification.isRead,
                    userPhoto: this.findUserPhoto(notification.actionUserId),
                    id: notification.id,
                    isDeleted: notification.isDeleted
                };
                return mappedNotification;
            });
            if (this.socialPageNo == 1) {
                this.deletedSocial = _notifyList;
                this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, this.pageTypes.socialPage).subscribe(data => {
                    if (data) {
                        if (this.deletedSocialNotifiCount > this.deletedSocial.length) {
                            this.ReadMoreSocial = true;

                        }
                        else {
                            this.ReadMoreSocial = false;

                        }
                    }
                })





            }
            else {
                if (_notifyList.length) {
                    this.deletedSocial.push(...data);

                    this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, this.pageTypes.socialPage).subscribe(data => {
                        if (data) {
                            if (this.socialNotifiCount > this.socialNotify.length) {
                                this.ReadMoreSocial = true;

                            }
                            else {
                                this.ReadMoreSocial = false;

                            }
                        }
                    })
                   


                }


            }


            const filteredData = this.deletedSocial.filter(item =>  item.isDeleted === true);
            this.newSocial = filteredData.length;


        })
    }
    getDeletedProcessData() {
        this._notificationsService.getDeletedNotificationsByPageType(this.appInfo.currentUser.id, this.pageTypes.processPage, this.deletedProcessPageNo, this.notifyPageSize).subscribe(data => {
             //this.getDeletedNotificationsCountByPageType(this.pageTypes.processPage);
            if (this.deletedProcessPageNo == 1) {
                this.deletedProcesses = data;
                this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, this.pageTypes.processPage).subscribe(data => {
                    if (data) {
                        if (this.deletedProcessNotifiCount > this.deletedProcesses.length) {
                            this.ReadMoreProcess = true;

                        }
                        else {
                            this.ReadMoreProcess = false;

                        }
                    }
                })

            }
            else {
                if (data.length) {
                    this.deletedProcesses.push(...data);
                    this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, this.pageTypes.processPage).subscribe(data => {
                        if (data) {
                            if (this.deletedProcessNotifiCount > this.deletedProcesses.length) {
                                this.ReadMoreProcess = true;
                            }
                            else {
                                this.ReadMoreProcess = false;
                            }

                        }
                    })
                }

            }
        })
    }
  
    toggleNotifiHistory() {
        this.deletedBoardPageNo = 1;
        this.deletedBoards = []
       
        this.deletedProcessPageNo = 1;
        this.deletedProcesses = []
        this.deletedSocialPageNo = 1;
        this.deletedSocial = []
        this.deletedItems = !this.deletedItems;
        this.boardPageNo = 1;
        this.socialPageNo = 1;
        this.processPageNo = 1;
        this.ReadMoreBoard = false;
        if (this.deletedItems != true) {
            this.getAllDeletedNotificationsCount();
        }
        this.openNotificationTab(this.pageType);
        
           
        
        
    }
    showDeletedNotificationsAuthFunc() {
        this.profileService.getShowNotifiData(this.appInfo.currentUser.id).subscribe((data: boolean) => {
            if(data)
             this.showDeletedNotifications = data;
        })
    }


    dropdownStateChanged(isOpen: boolean) {
        if (isOpen) {
            this.ChangeTab(1);
        }
    }
    ChangeTab(tabType: number) {
        if (tabType == 1) {
            this.selectedTab = this._translateService.instant('formNotifications');
            this.pageType = 1;


        }
        else if (tabType == 2) {
            this.selectedTab = this._translateService.instant('boardNotifications');
            this.pageType = 2;
        }
        else {
            this.selectedTab = this._translateService.instant('socialNotifications');
            this.pageType = 3;
        }
        this.openNotificationTab(this.pageType);

    }
    goToProfile(contactId) {
        
        this.router.navigate(['app/profile']);
        this.profileService.changeSelectedUser(contactId);
    }

    readItem(id: number) {
        
        let stringId = id.toString();
        this._notificationsService.addIsReadUser(stringId, this.appInfo.currentUser.id).subscribe(data => {
            if (data) {
                setTimeout(() => {
                  
                    this.getNotificationCountByPageType(this.pageType);
                    this.openNotificationTab(this.pageType);
                }, 500);
            }    

        })
    }

    deleteItem(id: number, type: number) {
        let stringId = id.toString();
        this._notificationsService.deleteItem(stringId, this.appInfo.currentUser.id).subscribe(data => {
            if (data) {
                this.getNotificationCountByPageType(type);
                setTimeout(() => {
                    this.openNotificationTab(type);
                }, 1000);

            }

        })
    }
    getDeletedNotificationsCountByPageType(pageType: number) {
        this._notificationsService.getDeletedNotificationCount(this.appInfo.currentUser.id, pageType).subscribe(data => {
            if (pageType == this.pageTypes.socialPage) {
                this.deletedSocialNotifiCount = data;
            }
            else if (pageType == this.pageTypes.boardPage) {
                this.deletedBoardNotifiCount = data;
            }
            else if (pageType == this.pageTypes.processPage) {
                this.deletedProcessNotifiCount = data;
            }
            else {
                return;
            }
        })
    }
    getNotificationCountByPageType(pageType: number) {
        this._notificationsService.getNotificationCount(this.appInfo.currentUser.id, pageType).subscribe(data => {

            if (pageType == this.pageTypes.socialPage) {
                this.socialNotifiCount = data;
            }
            else if (pageType == this.pageTypes.boardPage) {
                this.boardNotifiCount = data;
            }
            else if (pageType == this.pageTypes.processPage) {
                this.processNotifiCount = data;
            }
            else {
                return;
            }

        })

    }
    onScroll(event: any) {
        
        clearTimeout(300);
        this.scrollTimeout = setTimeout(() => { 
            const element = event.target;

            if (element.scrollHeight - element.scrollTop - element.clientHeight < 1) { 
                this.scrollDown(); 
            }

        }, 500); 

    }
    popupStyleChange() {
        let _thisLocal = this;
    }
    getHistoryPopupButtonActive(taskHistory) {
        let rowFromUser = taskHistory.fromUserId == this.appInfo.currentUser.id;
        let rowToUser = taskHistory.toUserIds.split(',').indexOf(this.appInfo.currentUser.id.toString()) != -1
        return rowFromUser || rowToUser
    }
    openForm(instanceId, formId, readonly, buttonId = "") {

        this._eventEmitterService.openFormFromNotify({ instanceId: instanceId, formId: formId, readonly: readonly, buttonId: "" });
    }

    hideLoadPanel() {
        this.loadingVisible = false;
    }
    getProcessHistory(id: number, instanceNumber: any) {
        this.spinner.show("mainspinner");

        this.instanceService.getHistory(id).subscribe((data: any[]) => {
            this.taskHistory = data;
            this.spinner.hide("mainspinner");
            if (!this.historyPopup) {
                this.historyPopup = true;
            }
            
            
            this.instanceNumber = instanceNumber;
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

        });
    }
  
    scrollDown() {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            if (this.pageType == this.pageTypes.processPage) {
                this.readMoreProcess();
            }
            else if (this.pageType == this.pageTypes.boardPage) {
                this.readMoreBoard();
            }
            else if (this.pageType == this.pageTypes.socialPage) {
                this.readMoreSocial();
            }
          
        }, 500); 
    }

 

    redirectToPage(board) {
        
        let id = board.id
        let pageId = board.pageId
        let type = board.type
        this._notificationsService.setReadUserToNotify(id, this.appInfo.currentUser.id, type).subscribe(data => {

            this.openNotificationTab(this.pageTypes.boardPage);
        });
        window.location.href = '/app/pageviewer?pageId=' + pageId;

    }
    deleteAll() {
        this._notificationsService.addDeletedUsers(this.appInfo.currentUser.id, this.pageType).subscribe(data => {
            if (data) {
                this.boards = []
                this.boardPageNo = 1
                if (this.pageType == this.pageTypes.socialPage) {
                    this.socialNotify = [];
                    this.socialPageNo = 1;
                    this._ReadMoreSocial = false;
                }
                else if (this.pageType == this.pageTypes.boardPage) {

                    this.boards = []
                    this.boardPageNo = 1
                    this._ReadMoreBoard = false;
                }
                else if (this.pageType == this.pageTypes.processPage) {
                    this._ReadMoreProcess = false;
                    this.processes = [];
                    this.processPageNo = 1;
                }
               
                this.getNotificationCountByPageType(this.pageType);
                this.openNotificationTab(this.pageType);
            }

        })
    }


    readAll() {
        this._notificationsService.addIsReadUsers(this.appInfo.currentUser.id, this.pageType).subscribe(data => {
            if (data) {
                this.boards.map(f => f.isRead = true)
                if (this.pageType == 1) {
                    this.newProcess = 0;
                } else if (this.pageType == 2) {
                    this.newBoards = 0;
                } else if (this.pageType == 3) {
                    this.newSocial = 0;
                }
                this.getNotificationCountByPageType(this.pageType);
                this.openNotificationTab(this.pageType);
               

            }


        })

    }

    readMoreSocial() {
        if (this.deletedItems) {
            this.socialPageNo++;
            this.getProfileNotificationsByPageType();
        }
        else  {
            this.deletedSocialPageNo++;
            this.getDeletedSocialData();
        }
     
    }
    readMoreBoard() {
        if (this.deletedItems) {
            this.boardPageNo++;
            this.getBoardNotificationsByPageType();
        }
        else  {
            this.deletedBoardPageNo++;
            this.getDeletedBoardData();
        }
    
    }
    readMoreProcess() {
        if (this.deletedItems) {
            this.processPageNo++;
            this.getProcessNotificationsByPageType();
        }
        else {
            this.deletedProcessPageNo++;
            this.getDeletedProcessData();
        }
       
    }
   
}
