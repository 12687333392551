import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation, EventEmitter, Output, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';
import { locale, loadMessages } from 'devextreme/localization';
import { User } from 'app/auth/models';

import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
//import { locale as english } from 'app/auth/data/en'
//import { locale as french } from 'app/auth/data/fr';
//import { locale as german } from 'app/auth/data/de';
//import { locale as turkish } from 'app/auth/data/tr';
//import { locale as arabic } from 'app/auth/data/ar';
//import { locale as azerbaijani } from 'app/auth/data/az';
//import { locale as georgian } from 'app/auth/data/geo';
//import { locale as dutch } from 'app/auth/data/nl';
//import { locale as italian } from 'app/auth/data/it';
import { TaskService, ReportService, GenericService, UserService, AuthService } from '@shared/services/api';
import { ProfileService } from '../../../../pages/profile/profile.service';
import { ComponentService, LocalStorageService } from '../../../../../@shared/services/common';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { LocalStorage } from '@ng-idle/core';
import { CoreTranslationService } from '../../../../../@core/services/translation.service';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class NavbarComponent implements OnInit, OnDestroy {
    activeBoard = 'kanban';
    // @Output() activeBoardChanged = new EventEmitter<string>();
    @Output() showStickyNotes = new EventEmitter<boolean>();
    public horizontalMenu: boolean;
    public hiddenMenu: boolean;

    public coreConfig: any;
    public currentSkin: string;
    public prevSkin: string;
    public showProfileSvg: boolean = false;
    public currentUser: User;

    // public languageOptions: any;
    public navigation: any;
    public selectedLanguage: any;
    public showSticky: boolean = true;
    selectedFlag: any = "TRTR";
    completionStatusItems = [];
    userList: any = [];
    @HostBinding('class.fixed-top')
    public isFixed = false;
    countryFlags = []
    @HostBinding('class.navbar-static-style-on-scroll')
    public windowScrolled = false;
    isDmsPermission: any = false;
    private schemaList: any = [];
    selectedLanguageName: any;
    showmailIcon: boolean;
    showDeletedNotifications: boolean;
    selectedGlobalParameter = null;
    // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) && this.coreConfig.layout.navbar.type == 'navbar-static-top' && this.coreConfig.layout.type == 'horizontal') {
            this.windowScrolled = true;
        } else if ((this.windowScrolled && window.pageYOffset) || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    // Private
    private _unsubscribeAll: Subject<void>;
    private currentRoute = "";
    navbarColorValue: string;
    emailNotifyCount = ""
    licenseType: string;
    isNotPro: boolean;
    isReportDesigner: boolean;
    isPolls: boolean=false;
    /**
     * Constructor
     *
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     * @param {CoreConfigService} _coreConfigService
     * @param {CoreSidebarService} _coreSidebarService
     * @param {CoreMediaService} _coreMediaService
     * @param {MediaObserver} _mediaObserver
     * @param {TranslateService} _translateService
     */
    constructor(
        private _router: Router,
        private _coreConfigService: CoreConfigService,
        private _coreMediaService: CoreMediaService,
        private _coreSidebarService: CoreSidebarService,
        private _coreMenuService: CoreMenuService,
        private _mediaObserver: MediaObserver,
        private reportingService: ReportService,
        public _translateService: TranslateService,
        public _appInfoService: AppInfoService,
        private _eventEmitterService: EventEmitterService,
        private _taskService: TaskService,
        private _profileService: ProfileService,
        private _genericService: GenericService,
        public _componentService: ComponentService,
        private _userService: UserService,
        private _authService: AuthService,
        private _msalService: MsalService,
        private _localStorage: LocalStorageService,
        private _coreTranslationService: CoreTranslationService,
    ) {
        // this._coreTranslationService.translate(english, french, german, turkish, arabic, azerbaijani, georgian, dutch, italian);
        this._authService.globalParameterVariable.subscribe(val => {
            this.selectedGlobalParameter = val;
        })

        this.showMailIcon();      
        this._unsubscribeAll = new Subject();

        this.currentRoute = this._router.url;
        this.checkRoute()

        this._router.events.subscribe((event: any) => {

            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                this.checkRoute()
            }
        });
        this.licenseType = this._appInfoService.currentUser.licanseType;

        if (this.licenseType == '1') {
            this.isNotPro = true;
        }
        this._authService._isLoggedIn.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
            if (data) {
                this.currentUser = this._appInfoService.currentUser
                this.selectedLanguageName = this._coreTranslationService.countryFlags.find(lang => lang.Code === this.selectedFlag)?.name;
                const storedLang = localStorage.getItem('localLang');
                if (storedLang) {
                    this._translateService.use(storedLang);
                    this._translateService.currentLang = storedLang;
                }
            }
        })
        if (this._appInfoService.currentUser.tabs && this._appInfoService.currentUser.tabs.includes(4)) {
            this.isReportDesigner = true;
        }
        else {
            this.isReportDesigner = false;
        }
        if (this._appInfoService.currentUser.tabs && this._appInfoService.currentUser.tabs.includes(7)) {
            this.isPolls = true;
        }
        else {
            this.isPolls = false;
        }
        this.countryFlags = this._coreTranslationService.countryFlags

    }

    checkRoute() {
        if (this.currentRoute.includes('pageviewer')) {
            this.showKanbanIcons = true;
        } else {
            this.showKanbanIcons = false;
        }
        if (this.currentRoute.includes('profile')) {
            this.showProfileSvg = true;
        }
        else {
            this.showProfileSvg = false;
        }

    }




    showKanbanIcons = false;

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
    * Toggle sidebar open
    *
    * @param key
    */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }
    toggleSidebarNew(nameRef): void {
        var sidebar = this._coreSidebarService.getSidebarRegistry(nameRef)
    
        sidebar.toggleOpen();

    }

    /**
    * Set the language
    *
    * @param language
   */
    @ViewChild('myDropdown', { static: true }) myDropdown: NgbDropdown;
    languageSourceObject: any;
    localeLang: string;
    async onSelectedFlag(Code, Key) {
        
        this.selectedFlag = Code;
        this.selectedLanguageName = this._coreTranslationService.countryFlags.find(lang => lang.Code === Code)?.name;

        this.localeLangGetAndSet(Code);
        if (Key != "") {
            await this._genericService.setUserLanguage(this._appInfoService.currentUser.id, Key).subscribe((result: any) => {
                this.getLanguageFunc(Code);

                let entityLocalStorage = JSON.parse(localStorage.getItem("localUserSession"));
                entityLocalStorage.currentUser.deflang = this._coreTranslationService.countryFlags.find(x => x.Code == Code).Key;
                localStorage.setItem("localUserSession", JSON.stringify(entityLocalStorage));

                this.localeLangGetAndSet(Key);
                window.location.reload();
            });
        }
    }

    localeLangGetAndSet(key: string) {
        var languageObject = this._coreTranslationService.countryFlags.find(lang => lang.Code === key);
      
        if (languageObject) {

            this._translateService.use(languageObject.Code)
            this._translateService.currentLang = languageObject.Code
            localStorage.setItem('localLang', languageObject.Code);

        }

    }
  
    getLanguageFunc(Code) {

        if (localStorage.getItem("languageSourceStorage") && this.languageSourceObject && this.selectedFlag == this.languageSourceObject.languageCode) {

            this._componentService.setLanguageSource(this.languageSourceObject.langSource);
            this.setCompletionStatusAndUser();
        }
        else {

            this._genericService.getlogininit(Code, "dictionary").subscribe((result: any) => {
                localStorage.setItem("languageSourceStorage", JSON.stringify(result.languages));
                this.languageSourceObject = JSON.parse(localStorage.getItem("languageSourceStorage"));
                this._componentService.setLanguageSource(this.languageSourceObject.langSource);
                this.setCompletionStatusAndUser();

            });
        }
        try {
            this.myDropdown.close();
        } catch { }

    }
    setCompletionStatusAndUser() {
        this.completionStatusItems = [
            { id: 0, name: this._translateService.instant('all') },
            { id: 1, name: this._translateService.instant('completions') },
            { id: 2, name: this._translateService.instant('ongoing') }
        ];

        this.userList = this._appInfoService.RiverEntity.users;
        var userListIndex = this.userList.findIndex(x => x.id == 0);
        if (userListIndex > -1)
            this.userList.splice(userListIndex, 1);

        this.userList.unshift({ id: 0, fullName: this._translateService.instant('all') });
        this._eventEmitterService.setLanguageFunc(this.languageSourceObject.langSource);
    }
    /**
    * Toggle Dark Skin
    */
    toggleDarkSkin() {
        this._coreConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.currentSkin = config.layout.skin;
            });
        this.prevSkin = localStorage.getItem('prevSkin');

        if (this.currentSkin === 'dark') {
            this._coreConfigService.setConfig(
                { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
                { emitEvent: true }
            );
        } else {
            localStorage.setItem('prevSkin', this.currentSkin);
            this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
        }
    }

    /**
    * Logout method
    */
    async logout() {
        await this._eventEmitterService.isUserLoginControl(false);
        let isLogintoOutlook = localStorage.getItem("tenantId")
        if (isLogintoOutlook != null) {
            await this.outlookLogout().toPromise();
        }
        await this._authService.logOut();
        await this._router.navigate(['/login']);
        localStorage.removeItem('outlookToken');
        sessionStorage.removeItem('openingFormExecuted');
    }

    outlookLogout(): Observable<any> {

        return new Observable<any>(observer => {
            var config = {
                authority: "https://login.microsoftonline.com/" + localStorage.getItem("tenantId"),
                postLogoutRedirectUri: "https://localhost:44385/login"
            };

            this._msalService.logoutPopup(config).subscribe({
                next: () => {
                    observer.next();
                    observer.complete();
                },
                error: error => {
                    observer.error(error);
                }
            });
        });
    }



    clearCookies() {

        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
    * On init
    */

    currentUserJson: any = { fullName: "", eMail: "", avatar: "" }
    ngOnInit(): void {
        if (this._appInfoService.currentUser.tabs.search("5") != -1) {
            this.isDmsPermission = true;
        } else {
            this.isDmsPermission = false;
        }
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            
            this.currentUserJson = this._appInfoService.currentUser
            this.currentUserJson.avatar = (this._appInfoService.currentUser.photo != null &&
                this._appInfoService.currentUser.photo != "" &&
                this._appInfoService.currentUser.photo != "ZABhAHQAYQA6AGkAbQBhAGcAZQAvAHAAbgBnADsAYgBhAHMAZQA2ADQALABpAFYAQgA=" ?
                'data:image/png;base64,' + this._appInfoService.currentUser.photo : "/assets/img/team2.jpg");
            this.currentUserJson.deflang = this._appInfoService.currentUser.deflang.replace(/-/gi, '').toUpperCase();

            this.coreConfig = config;
            this.horizontalMenu = config.layout.type === 'horizontal';
            this.hiddenMenu = config.layout.menu.hidden === true;
            this.currentSkin = config.layout.skin;
            // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
            if (this.coreConfig.layout.type === 'vertical') {
                setTimeout(() => {
                    if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                        this.isFixed = true;
                    }
                }, 0);
            }
        });

        if (this.coreConfig.layout.type == 'horizontal') {
            // On every media(screen) change
            this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
                if (isFixedTop) {
                    this.isFixed = false;
                } else {
                    this.isFixed = true;
                }
            });
        }
        this.selectedFlag = this._appInfoService.currentUser.deflang.replace(/-/gi, '').toUpperCase();
        this.selectedLanguageName = this._coreTranslationService.countryFlags.find(lang => lang.Code === this.selectedFlag)?.name;
        this.onSelectedFlag(this.selectedFlag, "");
        this.setNavbarColor();
        this.showMailIcon();
        if (!this.navbarColorValue || this.navbarColorValue === "") {
            var itemColor = localStorage.getItem("customNavbarColor");
            this.navbarColorValue = JSON.parse(itemColor);
        }



    }
    setNavbarColor() {
        this._eventEmitterService.invokeNavbarColor.subscribe(color => {
            this.navbarColorValue = color.value;
        })
        this.showMailIcon();
        this.emailNotifyCount = this._localStorage.get("unreadItemCount") || "0"

    }
    /**
    * On destroy
    */
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    async getSchemaList() {
        await this._taskService.getSchemeList().subscribe(result => {
            var mainScheme = {
                id: "river",
                icon: "circle",
                title: this._translateService.instant('forms'),
                type: 'section',
                children: []
            }

            result.forEach((el: any) => {
                var schemaItem = {
                    id: el.name,
                    icon: "home",
                    title: el.name,
                    type: 'collapsible',
                    children: [],
                    isProcess: false,
                    isMenu: false
                }

                el.items.forEach(el => {
                    schemaItem.children.push({
                        id: el.name,
                        icon: "home",
                        title: el.name,
                        type: "item",
                        url: "river",
                        isProcess: true,
                        isMenu: false
                    })
                })

                mainScheme.children.push(schemaItem)

            })

            this._coreMenuService.PushMenuAndRegister("main", mainScheme);

            let resultSchema = [];
            resultSchema = result;    
            this._eventEmitterService.getAllSchemaList(resultSchema);
            resultSchema.forEach(item => {

                if (!item.isScheme) {
                    this.filterSchemaList(item.items);
                }
                else {
                    this.schemaList.push(item);
                }
            });
        });
    }

    designerMenuList = [];
    getMenuList() {
        this._taskService.getSidebarMenu().subscribe(data => {
            var mainScheme = {
                id: "riverMenu",
                title: this._translateService.instant('catalog'),
                type: 'section',
                icon: 'menu',
                children: [],
                isProcess: false,
                isMenu: false
            }

            for (var i = 0; i < data.length; i++) {
                let _obj = { schemaId: data[i].schemaId, data: JSON.parse(data[i].sidebarJson) };

                var schemaItem = {
                    id: _obj.schemaId,
                    icon: "home",
                    title: _obj.data.name,
                    type: 'collapsible',
                    children: [],
                    isProcess: false,
                    isMenu: false
                }

                _obj.data.items.forEach(el2 => {
                    //      console.log("el2", el2)


                    el2.items.forEach(el3 => {
                        //    console.log("el3",el3)
                        var schemaItem2 = {
                            id: el3.id,
                            icon: "home",
                            title: el3.name,
                            type: "item",
                            url: "river2",
                            children: [],
                            isProcess: true,
                            isMenu: false
                        }

                        schemaItem.children.push(schemaItem2)
                    })


                })

                mainScheme.children.push(schemaItem)

                this.designerMenuList.push(_obj);
            }

            this._coreMenuService.PushMenuAndRegister("main", mainScheme);

        });
    }

    getlangTitle(_name, _languages) {
        _languages = JSON.parse(_languages.languages);
        let _currentLang = this._coreTranslationService.countryFlags.find(f => f.Code == this._translateService.currentLang);
        if (_currentLang) {
            let reportLang = _languages[_currentLang.Key];
            if (reportLang)
                return reportLang;
        }
        return _name;
    }

    getReportList() {
        
        this.reportingService.getReports().subscribe((data: any[]) => {

            var mainScheme = {
                id: 'riverReport',
                title: this._translateService.instant('riverReports'),
                type: 'section',
                icon: 'activity',
                children: [],
                isProcess: false,
                isMenu: false
            }

            data.forEach(el => {
                var schemaItem = {
                    id: el.id,
                    icon: "home",
                    title: this.getlangTitle(el.name, el.languages),
                    type: 'collapsible',
                    children: [],
                    isProcess: false,
                    isMenu: false
                }

                el.items.forEach(el2 => {
                    var schemaItem2 = {
                        id: el2.id,
                        icon: "home",
                        title: this.getlangTitle(el2.name, el2.languages),
                        type: 'item',
                        url: "river3",
                        isProcess: false,
                        isMenu: false
                    }
                    schemaItem.children.push(schemaItem2)
                })
                mainScheme.children.push(schemaItem)
            })
            this._coreMenuService.PushMenuAndRegister("main", mainScheme);



        });
    }

    filterSchemaList(items) {
        items.forEach(item => {
            if (!item.isScheme) {
                this.filterSchemaList(item.items);
            }
            else {
                this.schemaList.push(item);
            }
        });
    }
    setActiveBoard(board: string) {
        // this.activeBoard = board;
        this._eventEmitterService.emitActiveBoardChanged(board);

        // console.log("*****"+board)
    }

    selectSocialType(type: string) {
        this._eventEmitterService.closeEditPage(false);

        if (type === 'all') {
            this._profileService.changeSelectedUser(0);
            this._router.navigate(['/app/profile']);
        } 
        else if (type === 'polls') {
            this._router.navigate(['/app/profile/polls']);
        }
        else {
            this._profileService.changeSelectedUser(this._appInfoService.currentUser.id);
            this._router.navigate(['/app/profile']);
        }
    }

    showStickyFunc() {

        this.showSticky = !this.showSticky;
        if (this.showSticky) {
            this._eventEmitterService.showStickyNotes(true);
        }
        else {
            this._eventEmitterService.showStickyNotes(false);
        }
    }

    showMailIcon() {

        this._profileService.getEmailPreferences(this._appInfoService.currentUser.id).subscribe((data: boolean) => {
            this.showmailIcon = data;
        })
    }

    onSelectGlobalParameter(parameter) {
        this._authService.updateTokenClaim(parameter.code)
    }

}
