import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { AppComponent } from 'app/app.component';
import { CommonModule } from '@angular/common';
import {
    IPublicClientApplication,
    PublicClientApplication,
    BrowserCacheLocation,
} from '@azure/msal-browser';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { coreConfig } from 'app/app-config';
import 'hammerjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent, LoadingModule } from '@shared/components/loading/loading.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppRoutingModule } from './app.routing.module';
import { AuthGuardService, AuthInterceptor, AuthService, FormbuilderGuardService, ReportingGuardService } from '@shared/services/api/auth.service';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { CoreConfigService } from '@core/services/config.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import { GlobalService } from '@shared/services/common/global.service';
import { LocalStorageService } from '@shared/services/common/local-storage.service';
import { ComponentService } from '@shared/services/common/component.service';
import { NoAuthLayoutModule } from './layout/no-auth-layout/no-auth-layout.module';
import { AuthLayoutModule } from './layout/auth-layout/auth-layout.module';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { CoreCommonModule } from '../@core/common.module';
import { CoreLoadingScreenService } from '../@core/services/loading-screen.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { HtmlEditorModule } from './pages/html-editor/htmlEditor.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; //deniyorum azure i�in
let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
    msalInstance =
        msalInstance ??
        new PublicClientApplication({
            auth: { 
                clientId: '3dc5f2e4-8758-4128-9802-08a79e5ec46b',
                redirectUri: 'https://localhost:44385/app/home',// 'https://localhost:44385/app/home'
                postLogoutRedirectUri:  'https://localhost:44385/app/home' , //'https://localhost:44385/app/home'
            },
            cache: {
             //   cacheLocation: BrowserCacheLocation.LocalStorage,
            },
        });

    return msalInstance;
}
@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent
    ],
    exports: [
        LoadingComponent,
        BrowserAnimationsModule 
    ],
    imports: [
        TranslateModule.forRoot(),
        MsalModule,
        NgIdleKeepaliveModule.forRoot(),
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CommonModule,
        DxReportViewerModule,
        LoadingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserTransferStateModule,
        ReactiveFormsModule,
        FormsModule,                                                                         
        AuthLayoutModule,
        AppRoutingModule,
        HtmlEditorModule,
        TranslateModule,
        ToastrModule.forRoot(),
        NgxPaginationModule,
        ReactiveFormsModule,
        RecaptchaV3Module,
        NgxCaptchaModule
    ],

    providers: [
        AuthService,
        AuthGuardService,
        FormbuilderGuardService,
        ReportingGuardService,
        AppInfoService,
        CoreConfigService,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        MsalService,
        AuthInterceptor,

        EventEmitterService,
        GlobalService,
        LocalStorageService,
        ComponentService,
        CoreLoadingScreenService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcwEEcqAAAAAMI6TRXC4QLIptj-sxhyUldb8FR1' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
