export const locale = {

    lang: 'DEDE',

    data: {

        loginButton: "Anmeldung",

        userName: "Nutzername",

        pass: "Passwort",

        remember: "Erinnere dich an mich",

        forgotPass: "Passwort vergessen",

        modules: "Module",

        generic: "Allgemein",

        search: "Suchen",
        fileLink:"Dateilink",
        generateLink: "Link generieren",
        givePasswordToTheLink: "Geben Sie dem Link ein Passwort",
        giveTheLinkAnExpirationDate: "Geben Sie dem Link ein Ablaufdatum",

        logout: "Ausloggen",

        waitingJob: "Meine ausstehende Arbeit",

        myStarted: "Ich habe angefangen",

        myIncluded: "Ich bin eingeschlossen",

        myCompleted: "Abgeschlossene Arbeiten",

        jobList: "Jobliste",

        formNo: "Formular-Nr.",

        Status: "Status",
        starter: "Gestartet",

        assigned: "Zugewiesen",

        timeoutDate: "Timeout-Datum",

        startDate: "Startdatum",

        processing: "wird bearbeitet",

        you: "Du",
        jobListMessageArea: "Nachrichtenbereich der Auftragsliste",
        history: "Geschichte",

        Description: "Beschreibung",

        Date: "datum",

        growShrink: "Wachstum/Reduzierung",

        close: "Schließen",

        GridReset: "Gittereinstellungen zurücksetzen",

        ColumnSelect: "Spaltenauswahl",

        jobName: "Jobname",

        keyword: "Schlüsselwort",

        completion: "Abschluss",

        all: "alles",

        completedDate: "Enddatum",

        completions: "Abgeschlossen",

        ongoing: "Laufend",

        formName: "Formularname",

        sunday: "Sonntag",

        monday: "Montag",

        tuesday: "Dienstag",

        wednesday: "Mittwoch",
        thursday: "Donnerstag",

        Friday: "Freitag",

        Saturday: "Samstag",

        pdfExport: "PDF exportieren",

        sendPassword: "Mein Passwort senden",

        assignedDate: "Zuweisungsdatum",

        events: "Ereignisse",

        new: "Neu",

        Save: "Speichern",

        Formula: "Formel",

        selectDataSource: "Datenquelle auswählen",

        requireField: "Pflichtfeld",

        Suffix: "Suffix",

        valueColumn: "Wertspalte",

        showLength: "Anzahl der Aufgaben in Spalten anzeigen",

        clearButton: "Löschen-Schaltfläche",

        allCaps: "Alle Großbuchstaben",

        autoAsc: "Auto Asc",

        width: "Breite",

        Length: "Länge",

        Function: "Funktionen",

        selectElement: "Element auswählen",

        selectColumn: "Spalte auswählen",

        edit: "Bearbeiten",

        changeEventActions: "Ereignisaktionen ändern",

        Barcode: "Barcode",

        systemInfo: "Systeminformationen",

        prefix: "Präfix",

        max: "Maximal",

        showMinMaxInfo: "Zeigt Minimal- und Maximalinformationen an",

        selectUser: "Nutzer wählen",

        spinButton: "Drehknopf",

        numberIncDecButton: "Schaltfläche zum Erhöhen und Verringern der Zahl",

        decimal: "Dezimalzahl",

        numeral: "Nummer",

        wrongFormat: "Sie haben das falsche Format eingegeben",

        height: "Höhe",

        microphone: "Mikrofon",

        notifyDataReset: "Daten-Reset",

        data: "Daten",

        dynamicData: "Dynamische Daten",

        manualData: "Manuelle Daten",

        selectDataGrid: "Wählen Sie Datagrid aus",

        overrideUserChange: "Benutzeränderungen unterdrücken",

        textColumn: "Textspalte",

        makeInitialValue: "Standardanfangswert",

        undo: "Rückgängig machen",

        copy: "Kopieren",

        preview: "Vorschau",

        delete: "Löschen",

        cancel: "Stornieren",

        languageSettings: "Spracheinstellungen",

        messageSqlLinkDeleted: "Da Sie die Daten manuell hinzugefügt haben, werden das SQL-Skript und die Linkadresse gelöscht! Weitermachen?",

        messageSqlLinkDeletedForMenu: "Die Auswahl der Daten als Menü führt zum Löschen der SQL-Skriptdatei und der Verbindungsadresse! Möchten Sie fortfahren?",

        permissions: "Berechtigungen",

        objectid: "Objekt Identifikation",

        fontSize: "Schriftgröße",

        fontWidth: "Textbreite",

        fontStyle: "Schriftstil",

        underline: "Unterstreichen",

        textColor: "Textfarbe",

        visibility: "Sichtweite",

        textAlignment: "Textausrichtung",

        settings: "Einstellungen",

        messageUnsaved: "Sind Sie sicher, dass Sie den Vorgang beenden möchten, ohne Ihre Änderungen zu speichern?",

        warning: "Warnung!",

        userAuthorizations: "Benutzerberechtigungen",

        formPreferences: "Formulareinstellungen",

        formOpeningevent: "Offene Veranstaltung bilden",

        chooseConnectionAddress: "Wählen Sie Verbindungsadresse",

        selectPriorty: "Wählen Sie Priorität",

        loadingEditor: "Editor wird geladen ...",

        showAddButton: "Schaltfläche Zeile hinzufügen anzeigen",

        update: "Aktualisieren",

        connectionID: "Verbindungs-ID",

        formPreview: "Formularvorschau",

        nameDisplayedform: "Name des angezeigten Formulars",

        formNamevariable: "Formularnamenvariable",

        category: "Kategorie",
        formColor: "Formfarbe",

        generalForm: "Generelle Form",

        saveForm: "Formular speichern",

        messageNeworupdate: "Die Änderungen, die Sie am vorhandenen Formular vorgenommen haben, werden gelöscht. Weitermachen?",

        messageDelete: "Sind Sie sicher, dass Sie das Formular löschen möchten?",

        toastDeleted: "Formular gelöscht",

        toastError: "Leider ist bei der Verarbeitung ein Fehler aufgetreten",

        alertDifferentID: "Diese ID wird bereits anderweitig verwendet. Bitte geben Sie eine andere ID an.",

        confirmChanges: "Änderungen bestätigen",

        toastUserpermission: "Benutzer hat keine Formular-Design-Berechtigung.",

        toastCodeeditor: "Der Code-Editor existiert bereits.",

        messageDeletesure: "Die Elemente in diesem Komponente werden gelöscht.",

        messageSure: "Sind Sie sicher?",


        successful: "Erfolgreich",

        error: "Fehler",

        toastFormupdate: "Formular aktualisiert",

        toastMistake: "Es ist ein Fehler aufgetreten.",

        toastSaved: "Gespeichert",

        toastCopied: "Kopiert",

        toastEmptyFormMessage: "Es wurden noch keine Formularelemente hinzugefügt.",

        toastGiveName: "Bitte geben Sie Ihrem Formular einen Namen.",

        toastGiveVariableName: "Bitte geben Sie einen Namen für die Formularnamen-Variable ein.",

        toastVariableNameNumberWarning: "Der Variablenname darf nicht mit einer Zahl beginnen.",

        formCopy: "Formular kopieren",

        messageButtonDelete: "Sind Sie sicher, dass Sie die Schaltfläche löschen möchten?",

        noDataFound: "Keine Daten gefunden",

        language: "Sprache",

        filterDatagrid: "Datagrid-Filterung",

        addManualData: "Manuelle Daten zur Laufzeit hinzufügen",

        dateFormat: "Datumsformat",

        messageSqlLinkDelete: "Da Sie Daten manuell hinzugefügt haben, werden SQL-Skript und Link-Adresse gelöscht! Fortfahren?",

        timeOption: "Uhrzeiten Option",

        defaultTodaysDate: "Standardmäßig heutiges Datum verwenden",

        dateRange: "Datumsbereich",

        minToday: "Mindestens heute",

        maxToday: "Höchstens heute",

        dateRangeDay: "Datumsbereich (Tag)",

        enterDateRangeValue: "Geben Sie den Wert für den Datumsbereich ein",

        imageIdColumn: "ID-Spalte",

        statusSettings: "Status-Einstellungen",

        active: "Aktiv",

        passive: "Passiv",

        enable: "Aktivieren",

        disable: "Deaktivieren",

        visible: "Sichtbar",

        invisible: "Unsichtbar",

        items: "Elemente",

        showItems: "Elemente anzeigen",

        array: "Array",

        letLabelAppear: "Beschriftung anzeigen",

        add: "Hinzufügen",

        horizontal: "Horizontal",

        vertical: "Vertikal",

        text: "Text",

        alertFillRequiredFields: "Bitte füllen Sie die erforderlichen Felder aus.",

        alertSignatureField: "Das Unterschriftsfeld darf nicht leer sein.",

        messageApproveTransaction: "Stimmen Sie der Transaktion zu?",

        button: "Schaltfläche",

        reasonforRefusal: "Ablehnungsgrund",

        enterReasonRejection: "Grund für die Ablehnung eingeben",

        reject: "Ablehnen",

        addDeleteButton: "Schaltfläche hinzufügen/löschen",

        showBorder: "Rahmen anzeigen",

        buttons: "Schaltflächen",

        clean: "Leeren",

        buttonSettings: "Schaltflächen-Einstellungen",

        saveLocation: "Speicherort speichern",

        paymentButton: "Zahlungs-Schaltfläche",

        paymentProvider: "Zahlungsanbieter",

        price: "Preis",

        numberOfInstallments: "Anzahl der Raten",

        descriptionField: "Beschreibungsfeld",

        current: "Aktuell",

        currentForm: "Aktuelles Formular",

        name: "Name",

        surname: "Nachname",

        country: "Land",

        city: "Stadt",

        address: "Adresse",

        authorizations: "Berechtigungen",

        users: "Benutzer",

        user: "Benutzer",

        givePermission: "Berechtigung erteilen",

        groups: "Gruppen",

        group: "Gruppe",

        saveandSend: "Speichern und senden",

        approve: "Genehmigen",

        sendBack: "Zurücksenden",

        deslineAndEnd: "Ablehnen und beenden",

        complete: "Abgeschlossen",

        blue: "Blau",

        gray: "Grau",

        green: "Grün",

        red: "Rot",

        left: "Links",

        right: "Rechts",

        color: "Farbe",

        symbol: "Symbol",

        position: "Position",

        action: "Aktion",

        total: "Gesamt",

        filePath: "Dateipfad",

        fileName: "Dateiname",

        fileUpload: "Datei hochladen",

        choose: "Auswählen",

        updateData: "Daten aktualisieren",


        show: "Anzeigen",

        columns: "Spalten",

        changeEvent: "Änderungsereignis",

        columnSettings: "Spalteneinstellungen",

        columnName: "Spaltenname",

        deleteColumn: "Spalte löschen",

        columnSqlScripts: "SQL-Skripts für Spalte",

        imageNameColumn: "Dateinamenspalte",

        columnLanguageSetting: "Spacheneinstellung für Spalte",

        dataSource: "Datenquelle",

        filter: "Filter",

        reset: "Zurücksetzen",

        column: "Spalte",

        image: "Bild",

        searchLocation: "Suchort...",

        draggable: "Ziehbar",

        usersCanChange: "Benutzer können ändern",

        multiMarker: "Mehrere Markierungen",

        markerLimit: "Markierungslimit (0: kein Limit)",

        meetingType: "Besprechungstyp",

        meetingName: "Besprechungsname",


        loadingFiles: "Dateien werden geladen...",

        fileSizeExceeded: "Dateigröße überschritten",

        filesLoaded: "Dateien geladen",

        condition: "Bedingung",

        value: "Wert",

        connectionAddress: "Verbindungsadresse",

        imageTypeColumn: "Dateityp Spalte",

        visibleColumn: "Sichtbare Spalte",

        selectionMode: "Auswahlmodus",

        selectMode: "Auswahlmodus",

        datagridFiltering: "Datagrid-Filterung",

        imageDataColumn: "Datei Spalte",


        selectDataColumn: "Datagrid-Spalte auswählen",

        dataFormat: "Datenformat",


        photoPreview: "Fotovorschau",

        videoPreview: "Video-Vorschau",

        displayPhoto: "Foto anzeigen",

        displayVideo: "Video anzeigen",

        imageColumn: "Bildspalte",

        videoColumn: "Videospalte",


        columnNameAppear: "Spaltenname zum Anzeigen",


        selected: "Ausgewählt",

        inStock: "Auf Lager",

        messageSave: "Möchten Sie Ihre Änderungen speichern?",

        gallery: "Galerie",

        dollar: "Dollar",

        sterling: "Pfund",

        underPhoto: "Unter dem Foto",

        topPhoto: "Foto oben",


        byCategory: "Nach Kategorie",

        dashboardShowHide: "Dashboard anzeigen/ausblenden",

        showDetail: "Details anzeigen",

        hideDetail: "Details ausblenden",

        decrease: "Verringern",

        increase: "Erhöhen",

        watch: "Ansehen",

        watchMovie: "Film ansehen",

        noRecordsFound: "Keine Datensätze gefunden!",

        appearance: "Erscheinungsbild",

        numberofColumns: "Anzahl der Spalten",

        pagingRecords: "Seitenaufzeichnungen",

        detailLocation: "Detailposition",

        hideImages: "Bilder ausblenden",

        quantityCategoryDisable: "Kategorie deaktivieren, wenn Menge erhöht wird",

        general: "allgemein",

        idColumn: "Id-Spalte",

        availableQuantity: "Verfügbare Menge",

        categoryColumn: "Kategoriespalte",

        photoColumn: "Fotospalte",

        titleColumn: "Titelspalte",

        priceColumn: "Preisspalte",

        updatablePrice: "Aktualisierbarer Preis",

        videoGallery: "Video Galerie",

        uniqueComponent: "Einzigartige Komponente",

        showVariant: "Variante anzeigen",

        transferTotalAmount: "Gesamtbetrag übertragen",

        title: "Titel",

        hide: "Verbergen",

        detail: "Detail",

        priceQuery: "Preisabfrage",

        quantityQuery: "Mengenabfrage",


        uniqueComponentError: "Einzigartige Komponentenfehler",

        joinColumn: "Verbindungsspalte",

        nameColumn: "Namensspalte",

        variantScript: "Variantenskript",

        variantImages: "Variantenbilder",

        variantJoinColumn: "Varianten-Verbindungsspalte",

        showAll: "Alle anzeigen",

        showSelected: "Ausgewählte anzeigen",

        showByCategory: "Nach Kategorie anzeigen",

        showInStock: "Auf Lager anzeigen",

        notYetAdded: "Noch nicht hinzugefügt",

        added: "Hinzugefügt",

        startingDate: "Startdatum",

        endDate: "Enddatum",

        themeFeatures: "Theme-Funktionen",

        menu: "Menü",

        selectMenu: "Menü auswählen",

        selectSubmenu: "Untermenü auswählen",

        selectMenuBrowser: "Menü im Browser auswählen",

        nameElement: "Elementname",

        subject: "Betreff",

        picture: "Bild",

        pictureElement: "Bildelement",

        startingDateElement: "Startdatumselement",

        endDateElement: "Enddatumselement",

        usersCanEdit: "Benutzer können bearbeiten",

        thisPropertyCant: "Diese Eigenschaft KANN NICHT verwendet werden, wenn die Daten aus SQL abgerufen werden",

        calendarDisplay: "Kalender-Anzeigeeigenschaften",

        monthly: "Monatlich",

        generalDisplayLunarDays: "Allgemeine Anzeige der Mondtage",

        agenda: "Agenda",

        listofWeeklyEvents: "Liste der wöchentlichen Ereignisse",

        weekly: "Wöchentlich",

        weekend: "Wochenende",

        generalDisplayWeekDays: "Allgemeine Anzeige der Wochentage",

        showWeekendDaysWhileWeekdays: "Wochenendtage anzeigen, während Wochentage angezeigt werden",

        daily: "Täglich",

        listofDailyEvents: "Liste der täglichen Ereignisse",

        startTime: "Startzeit",

        endTime: "Endzeit",

        changeDefaultColor: "Standardfarben ändern",

        weekdayColor: "Wochentagsfarbe",

        weekendColor: "Wochenendfarbe",

        eventColor: "Ereignisfarbe",

        groupBySql: "Gruppieren nach SQL",

        groupColumnName: "Gruppenspaltenname",

        assignedColumnName: "Zugeordneter Spaltenname",

        taskPriortiyColumnName: "Spaltenname für Aufgabenpriorität",

        taskSubjectColumnName: "Spaltenname für Aufgabenbetreff",

        taskDetailColumnName: "Spaltenname für Aufgabendetails",

        taskQuery: "Aufgabenabfrage",

        taskKeyExpression: "Aufgabenschlüsselausdruck",

        taskTitleExpression: "Aufgabentitelausdruck"
        ,
        taskStartDateExpression: "Aufgabestartdatum-Ausdruck",

        taskEndDateName: "Aufgabeschlussdatum-Name",

        taskParentExpression: "Übergeordneter Aufgaben-Ausdruck",

        taskProgressExpression: "Aufgabenfortschrittsausdruck",

        taskColorExpression: "Aufgabenfarbausdruck",

        taskConnections: "Aufgabenverbindungen",

        diagramClear: "Möchten Sie das Diagramm wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        uniqueComponentMenuCloneWarning: "Bitte ändern Sie die eindeutige Komponente, bevor Sie sie kopieren.",

        filesLoading: "Dateien werden geladen...",

        couldnotbeLoaded: "konnte nicht geladen werden.",

        fileSizeLimit: "Dateigrößenbegrenzung",

        filesUploaded: "Dateien hochgeladen",

        filesWillDeleted: "Die Dateien werden gelöscht!",

        filesSureDelete: "Möchten Sie die Dateien wirklich löschen?",

        fileDelete: "Möchten Sie die Datei wirklich löschen?",

        uploadedPhotos: "Hochgeladenes Foto",

        maxSize: "Maximale Größe",

        center: "Mittel",

        size: "Größe",

        canItBeUpdated: "kann nach der Installation aktualisiert werden?",

        uploadedFiles: "Hochgeladene Dateien",

        type: "Typ",

        showdeleteOldData: "Löschoptionen für alte Daten anzeigen",

        videoBrowser: "Ihr Browser unterstützt das Video-Tag nicht.",

        deleted: "Gelöscht",

        theseComponentElementsDeleted: "Diese Komponentenelemente werden gelöscht.",

        invalidTarget: "Ungültiges Ziel",

        ridge: "Gerade Linie",

        dotted: "Punkt",

        dashed: "Gestrichelt",

        addRowColumn: "Zeile/Spalte hinzufügen",

        dataPagingShow: "Daten als Seiten abrufen",

        borderType: "Rahmentyp",

        maxCharacters: "Maximale Anzahl von Zeichen",

        edgeThickness: "Rahmenstärke",

        lineSpacing: "Zeilenabstand",

        settingsRow: "Spalten Zeilen-/Spalteneinstellungen",

        addRow: "Zeile hinzufügen",

        deleteRow: "Zeile löschen",

        rowPermission: "Zeilenerlaubnis",

        addTabs: "Tabs hinzufügen",

        deletePanel: "Panel löschen",

        tabPermission: "Tab-Berechtigung",

        addPanel: "Panel hinzufügen",

        panelPermission: "Panel-Berechtigungen",

        backgroundColor: "Hintergrundfarbe",

        saveAndCloseChildForm: "Dieses Unterformular speichern und schließen, während ein neues Formular geöffnet wird",

        icon: "Symbol",

        selectForm: "Formular auswählen",

        fontColor: "Schriftfarbe",

        minCharacters: "Mindestanzahl von Zeichen",

        generalCategory: "Allgemeine Kategorie",

        removeRow: "Möchten Sie den Datensatz wirklich löschen?",

        send: "Senden",

        declineFinish: "Ablehnen und Beenden",

        finish: "Beenden",

        noData: "Keine Daten",

        createEditMenu: "Menü erstellen/bearbeiten",

        addNewBreakdown: "Neue Aufschlüsselung hinzufügen",

        addForm: "Formular hinzufügen",

        addBottomBreak: "Untere Unterbrechung hinzufügen",

        addNode: "Knoten hinzufügen",

        topCategory: "Top-Kategorie",

        explanation: "Beschreibung",

        browserTitle: "Browser-Titel",

        formTitle: "Formulartitel",

        addSqlColumn: "SQL-Spalte hinzufügen",

        formBg: "Formular-Hintergrund",

        codeEditor: "Code-Editor",

        authorityType: "Berechtigungstyp",

        viewOnly: "Nur anzeigen",

        dark: "Dunkel",

        white: "Weiß",

        hightBlack: "Schwarz",

        run: "Ausführen",

        rowColor: "Zeilenfarbe",

        operator: "Operator",

        flowOfForm: "Formularfluss",

        columnWidth: "Spaltenbreite",

        alignLeft: "Links ausrichten",

        alignRight: "Rechts ausrichten",

        alignButton: "Schaltfläche ausrichten",

        imageTableNameColumn: "Tabellenname",

        uploadBg: "Hintergrund hochladen",

        selectFile: "Datei auswählen",

        dropFile: "Datei hier ablegen",

        alwaysRoundUp: "Immer aufrunden",

        alwaysRoundBot: "Immer abrunden",

        default: "Standard",

        deleteOldDataTable: "Die alten Daten der Tabelle löschen?",

        deleteOldData: "Alte Daten löschen?",

        integer: "Ganzzahl",

        double: "Dezimalzahl",

        pleaseFillFields: "Bitte füllen Sie die erforderlichen Felder aus.",

        toastColumnNameNumber: "Der Spaltenname darf nicht mit einer Zahl beginnen.",

        areYouSure: "Sind Sie sicher?",

        columnDeleted: "Die Spalte wird gelöscht.",

        allColumnDeleted: "Alle Spalten werden gelöscht.",

        idCantStartNumber: "Die ID darf nicht mit einer Zahl beginnen.",

        uploadFile: "Datei hochladen",

        fileViewer: "Dateibetrachter",

        ok: "OK",

        map: "Karte",


        editPanel: "Panel bearbeiten",

        filterColumnName: "Filter Spaltenname",

        hintExamples: "TotalAmount, Beispiel: Gehalt",


        columnLanguageSettings: "Spalten-Spracheinstellungen",

        fileColumn: "Datei-Spalte",


        fileNameColumn: "Dateinamenspalte",

        extensionColumn: "Erweiterungsspalte",

        multipleSelection: "Mehrfachauswahl",

        addSelectionsRows: "Auswahlen als Zeilen hinzufügen",

        mapSettings: "Karteneinstellungen",


        formatAndFeatures: "Format und Funktionen",

        enterDateRange: "Geben Sie den Wert des Datumsbereichs ein",

        filesCanUploadedUsers: "Benutzer können Dateien hochladen",

        barcodeColumn: "Barcode-Spalte",

        autoAscend: "Automatisch aufsteigend",

        roundType: "Rundungstyp",

        font: "Schriftart",

        columnProcess: "Spaltenoperation",

        exportColumnTotal: "Spaltensumme exportieren",

        outsideElementDisabled: "Externe Elemente deaktivieren",

        chat: "Chat",

        languages: "Sprachen",

        display: "Bild",

        enterYourComment: "Geben Sie Ihren Kommentar ein",

        comment: "Kommentar",

        fileUploaded: "Dateien hochgeladen",

        pdfViewIsButton: "Als Schaltfläche anzeigen",

        takeScreenshot: "Screenshot erstellen",

        alertText: "Alarmtext",

        clearButtonVisible: "Clear-Schaltfläche anzeigen",

        allText: "Alle",

        letter: "Nur Buchstaben",

        letterandnumber: "Buchstaben und Zahlen",

        letterandsymbol: "Buchstaben und Symbole",

        numberandsymbol: "Zahlen und Symbole",

        showIDColumn: "ID-Spalte anzeigen",

        showCopyButton: "Kopierschaltfläche anzeigen",

        showDeleteButton: "Löschschaltfläche anzeigen",

        showCheckboxButton: "Checkbox-Schaltfläche anzeigen",

        autoRowHeight: "Automatische Zeilenhöhe",

        exportImport: "Formular Import/Export",

        export: "Export",

        import: "Import",

        toastImport: "Formularimport abgeschlossen.",

        warningApproveImportReport: "Bestätigen Sie den Vorgang nach den erforderlichen Anpassungen. Das Importieren des Berichts schließt den derzeit geöffneten Bericht.",
        reportName: "Berichtsname",
        emptyReportName: "Bitte fügen Sie den Berichtsnamen von der rechten Seite hinzu.",

        old: "Alt",


        upperNode: "Oberer Knoten",

        pdfViewStaticPdf: "Fixierte Datei anzeigen",

        actions: "Aktionen",

        loading: "Wird geladen",

        exportDataToExcell: "Alle Daten in Excel exportieren",

        showRefreshIcon: "Aktualisierungsschaltfläche",

        headerFilter: "Kopfzeilenfilter",

        filterRowVisible: "Suchfeld in der Spalte anzeigen",

        password: "Passwort",

        categories: "Kategorien",

        pressEnter: "Enter drücken",

        historyColumnValue: "Datenverlaufsspalte",

        hideMobile: "Auf Mobilgeräten ausblenden",

        align: "Ausrichtung",



        theme: "Thema",

        info: "Info",

        showTitle: "Titel anzeigen",

        purple: "Lila",

        brown: "Braun",

        yellow: "Gelb",

        turnOnComments: "Kommentare öffnen",

        showContent: "Inhalt anzeigen",

        canBeHidden: "Kann ausgeblendet werden",

        allowEdit: "Bearbeiten erlauben",

        allowDelete: "Löschen erlauben",

        allowAdd: "Hinzufügen erlauben",

        allowCopy: "Kopieren erlauben",

        toastDeleteErrorMessage: "Dieses Element wird in Formular-Präferenz-Skripten verwendet. Es kann nicht gelöscht werden.",

        formOpeningMultiElementEvent: "Ereignis für die Formularöffnung von mehreren Elementen",

        resetDatagridLocalSettings: "Datagrid-Sprache zurücksetzen",
        loginP1: "River bietet ein ganzheitliches Gedächtnissystem als Ihr Unternehmensgedächtnis, das Informationen wie vergangene Erfahrungen, Erfolge, Misserfolge, Lehren, Kultur und Werte Ihrer Organisation oder Institution enthält, die als Referenzquelle für zukünftige Entscheidungen und Maßnahmen genutzt werden können. Prozesse, das Wissen Ihrer Mitarbeiter, Kundenerfahrungen, die Entwicklung von Produkten und Dienstleistungen und viele andere Informationen. Diese Informationen werden die zukünftigen Ziele und Strategien des Unternehmens leiten.",
        loginP2: "River hilft Ihrem Unternehmen, Geschäftsprozesse effizienter und effektiver auszuführen, dank seiner Web- und fortschrittlichen mobilen Anwendungsstruktur. Alles, was Sie benötigen, ist so nah bei Ihnen wie Ihr Computer, Tablet oder Smartphone.",
        loginConnect: "Verbinden",
        loginText: "Anmelden",
        loginRightMore: "Mehr",
        loginHeaderP1: "Ihr Unternehmensgedächtnis",
        loginHeaderP2: "Mobilität",
        loginHeaderP3: "Grafische Leistung",
        kanbanaddTasks: "Kanban-Aufgabe hinzufügen",
        tasksstatusDesciption: "Es zeigt den Namen der Spalte an, der die Kanban-Aufgabe zugewiesen wird.",
        taskssubjectDescription: "Ermöglicht die Eingabe des Titels der Kanban-Aufgabe. Der Kanban-Titel ist ein Pflichtfeld.",
        taskassignedToTask: "Zuweisung von Gruppen zur Aufgabe",
        taskassignedTaskDescription: "Ermöglicht die Auswahl von Gruppen, die der Kanban-Aufgabe zugewiesen werden sollen.",
        addedForTasks: "Hinzugefügt für Aufgabe",
        taskAssignedInfoDescription: "Personen, die über die Aufgabe informiert werden sollen",
        assignedInfo: "Personen, die benachrichtigt werden sollen",
        groupInfo: "Gruppen, die für die Aufgabe benachrichtigt werden sollen",
        sortEnd: "Nach Enddatum sortieren",
        sender: "Von",
        forward: "Weiterleiten",
        attachments: "Anhänge",
        loginP3: "Kanban- und Gantt-Diagramme sind äußerst nützliche Tools zur Visualisierung eines Projekts oder Arbeitsablaufs. Sie bieten eine visuelle Darstellung von Arbeitsgegenständen, ihrem aktuellen Status und dem Workflow in verschiedenen Phasen des Geschäfts. Dies erleichtert die Identifizierung von Engpässen, die Priorisierung von Aufgaben und die Verbesserung der betrieblichen Effizienz. Kanban- und Gantt-Diagramme sind praktisch und effektiv zur Verwaltung agiler Projekte, zur Softwareentwicklung und für andere kontinuierliche Verbesserungsprozesse.",
        taskdetailDescription: "Ermöglicht die Eingabe von Details zur Kanban-Aufgabe. Kanban-Detail ist ein Pflichtfeld.",
        taskassignedDescription: "Ermöglicht die Zuweisung von Benutzern zur Kanban-Aufgabe. Mindestens ein Benutzer muss für Kanban zugewiesen sein.",
        taskstartdateDescription: "Ermöglicht die Auswahl des Startdatums der Kanban-Aufgabe. In Kanban wird das Startdatum automatisch aus der Erstellungszeit der Aufgabe übernommen.",
        taskenddateDescription: "Ermöglicht die Auswahl des Enddatums der Kanban-Aufgabe. In Kanban liegt das Aufgabenenddatum automatisch eine Woche nach der Erstellungszeit der Aufgabe.",
        taskpriDescription: "Ermöglicht die Auswahl des Prioritätsstatus der Kanban-Aufgabe. In Kanban wird das Aufgabenfälligkeitsdatum automatisch eine Woche nach der Erstellungszeit der Aufgabe festgelegt.",
        tagsDescription: "Ermöglicht das Hinzufügen von Tags zur Kanban-Aufgabe. Tags werden durch Kommata getrennt, wenn sie hinzugefügt werden.",
        outlookDescription: "Wenn die Option zum Hinzufügen der Aufgabe zu Outlook aktiviert ist, wird die Aufgabe automatisch in den Kalender eingetragen, wenn Sie sich in Ihrem Outlook-Konto anmelden.",
        taskAssInfoDescription: "Dies ist das Feld für Personen, die der Aufgabe nicht zugewiesen sind, aber deren Informationen angefordert werden. Personen, die informiert werden, können keine Maßnahmen in Bezug auf Aufgaben ergreifen.",
        receiver: "Empfänger",
        draft: "Entwurf",
        spam: "Unnötige E-Mail",
        filterstate: "Nach Status filtern",
        checkForspelling: "Rechtschreibfehler prüfen",
        emailToForward: "E-Mail zum Weiterleiten",
        lowImpMails: "Wenig wichtige E-Mails",
        normalImpMails: "Normal wichtige E-Mails",
        highImpEmails: "Hoch wichtige E-Mails",
        showReadMails: "Gelesene E-Mails anzeigen",
        groupinfoDescription: "Dies ist das Feld für Gruppen, die einer Aufgabe nicht zugewiesen sind, aber deren Informationen angefordert werden. Personen, die zu den informierten Gruppen gehören, können keine Maßnahmen in Bezug auf Aufgaben ausführen.",
        timeoutMission: "Zeit bis zum Start der Warnung vor Aufgabenende",
        timeoutmissionDescription: "Wird verwendet, um die Zeit festzulegen, zu der die Warnung gestartet wird, wenn die Kanban-Aufgabe ihr Enddatum erreicht.",
        taskperiodDescription: "Wird verwendet, um die periodische Erinnerungszeit festzulegen, wenn das Enddatum einer Kanban-Aufgabe näher rückt.",
        taskProgress: "Aufgabenfortschritt",
        taskProgressDescription: "Wird verwendet, um den Fortschritt einer Kanban-Aufgabe anzuzeigen. Der Fortschritt kann im Gantt-Diagramm angezeigt werden.",
        updatekanbanTask: "Aktualisierung der Kanban-Aufgabe",
        taskcreatorDescription: "Kanban ermöglicht die Anzeige des Aufgaben-Erstellers.",
        uploadfileDescription: "Wird verwendet, um Dateien zur Kanban-Aufgabe hochzuladen. Dateien oder Bilder werden im DMS gespeichert.",
        infoPersons: "Personen, die informiert werden sollen",
        warningtime: "Warnzeit",
        taskPeriod: "Aufgabenzeitraum",
        taskProgresSatus: "Aufgabenfortschritt",
        makeTask: "Aufgabenersteller",
        addedforTasks: "Hinzugefügt für Aufgabe",
        outlookIntegration: "Outlook-Integration",
        loginoutlook: "Bei Outlook-Kalender anmelden",
        fetchoutlook: "Daten aus Outlook-Kalender abrufen",
        progressStatus: "Fortschrittsstatus",
        taskColors: "Aufgabenfarben",
        addcalendarTask: "Aufgaben zum Kalender hinzufügen",
        updatetaskCalendar: "Kalender-Aufgabenaktualisierung",
        statusComplete: "Filtern nach Abschlussstatus",
        priorityFilter: "Prioritätsfilterung",
        columnFilter: "Spaltenfilterung",
        assignPerson: "Zugewiesene Personen",
        assignGroup: "Zugewiesene Gruppen",
        noTask: "Keine Aufgaben gefunden",
        resetSort: "Sortierung zurücksetzen",
        sortASC: "Sortieren A - Z",
        sortDESC: "Sortieren Z - A",
        sortAppointment: "Sortieren nach Zuweisungsdatum",
        trash: "Papierkorb",
        showOlderMail: "Ältere E-Mails anzeigen",
        mailNotFound: "E-Mail nicht gefunden",
        sendNewMail: "Neue E-Mail senden",
        showUnreadMails: "Ungelesene E-Mails anzeigen",
        updateColumnMail: "Nachricht an Kontakt, wenn Aufgabe zur Spalte hinzugefügt wird",
        addTaskToOutlook: "Aufgabe zu Outlook hinzufügen",
        enterYourMessage: "Geben Sie Ihre Nachricht ein",
        reminder: "Erinnerung",
        determinedToTask: "Für die Aufgabe festgelegte Zeit",
        remainingTime: "Verbleibende Zeit",
        hours: "Stunde",
        withoutPriority: "Keine Priorität",
        lowPri: "Niedrige Priorität",
        normalPri: "Mittlere Priorität",
        highPri: "Hohe Priorität",
        showAllTasks: "Alle anzeigen",
        showIncompleteTasks: "Unvollständige anzeigen",
        showCompeletedTasks: "Abgeschlossene anzeigen",
        reportDesigner: "Berichtsdesigner",
        fileManagementSystem: "Dateiverwaltungssystem",
        formDesigner: "Formulardesigner",
        boardDesigner: "Boarddesigner",
        themeSettings: "Design-Eigenschaften",
        social: "Soziales",
        profile: "Profil",
        contacts: "Kontakte",
        files: "Dateien",
        pages: "Seiten",
        noResultsFound: "Keine Ergebnisse gefunden",
        processes: "Prozesse",
        filterByStatus: "Nach Status filtern",
        filterByPri: "Nach Priorität filtern",
        filterByCol: "Nach Spalte filtern",
        todoAddTasks: "To-Do-Aufgaben hinzufügen",
        addLabel: "Label eingeben",
        showWithSubTasks: "Unteraufgaben anzeigen",
        riverBoard: "River Boards",
        notify: "Benachrichtigungen",
        likedYourPost: "Hat deinen Beitrag geliked",
        likedYourComment: "Hat deinen Kommentar geliked",
        systemNotify: "Systembenachrichtigungen",
        readAllNotify: "Alle Benachrichtigungen lesen",
        recommendedUsers: "Empfohlene Personen",
        suggestedPosts: "Vorgeschlagene Beiträge",
        sPostReposted: "Beitrag erneut veröffentlicht",
        shareSomething: "Etwas teilen",
        updatePost: "Beitrag aktualisieren",
        ifYouDoNotSelectGroup: "Wenn Sie keine Gruppe auswählen, kann jeder Ihren Beitrag sehen",
        addComment: "Kommentar hinzufügen",
        sendComment: "Kommentar senden",
        areYouSureYouWantToDeleteThisPost: "Sind Sie sicher, dass Sie diesen Beitrag löschen möchten",
        yes: "Ja",
        no: "Nein",
        deletePost: "Beitrag löschen",
        postSuccesfullyDeleted: "Beitrag erfolgreich gelöscht",
        deleteComment: "Kommentar löschen",
        areYouSureYouWantToDeleteThisComment: "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten",
        commentSuccesfullyDeleted: "Kommentar erfolgreich gelöscht",
        sharePost: "Beitrag teilen",
        areYouSureYouWantToShareThisPost: "Sind Sie sicher, dass Sie diesen Beitrag teilen möchten",
        repostedSuccessfully: "Erfolgreich erneut veröffentlicht",
        upload: "Hochladen",
        refresh: "Aktualisieren",
        allowedExtentions: "Erlaubte Erweiterungen: JPG, GIF oder PNG. Maximale Dateigröße",
        systemLandingPagePreference: "Einstellungen für Startseite des Systems",
        allownonadminuserstosearchforotherusers: "Nicht-Admin-Benutzern erlauben, nach anderen Benutzern zu suchen",
        passwordChange: "Passwort ändern",
        newPassword: "Neues Passwort",
        retypethenewpassword: "Neues Passwort erneut eingeben",
        notifications: "Benachrichtigungen",
        showWhoLikesYourPost: "Zeige, wer deine Beiträge mag",
        showWhoLikesYourComment: "Zeige, wer deine Kommentare mag",
        aboutMe: "Über mich",
        bio: "Biografie",
        dateOfBirth: "Geburtsdatum",
        website: "Website",
        phone: "Telefon",
        saveChanges: "Änderungen speichern",
        username: "Benutzername",
        content: "Inhalt",
        projectManagement: "Projektmanagement",
        addPage: "Seite hinzufügen",
        addFolder: "Ordner hinzufügen",
        showUsers: "Benutzer, die sehen können",
        showGroups: "Gruppen, die sehen können",
        readonlyUsers: "Benutzer, die lesen können",
        readonlyGroups: "Gruppen, die lesen können",
        columnHeight: "Spaltenhöhe",
        showKanbanCount: "Kanban-Mengeninformation anzeigen",
        kanbanSettings: "Kanban-Einstellungen",
        gantSettings: "Gantt-Einstellungen",
        schedulerSettings: "Kalendereinstellungen",
        showRowLines: "Zeige Zeilenlinien",
        activateOutlookConnection: "Outlook-Verbindung aktivieren",
        selectOutlookColumn: "Spalte für Outlook auswählen",
        selectSMTPopoverText: "Die SMTP-Mail, die in Kanban, Gantt, Kalender oder Aufgabenkomponenten verwendet werden soll, wird ausgewählt.",
        menuFilePathId: "ID des River-Menüordners",
        recycleBinPathName: "Name des River-Papierkorbs",
        recycleBinPathId: "ID des River-Papierkorbs",
        fileNameChangedMessage: "Dateiname erfolgreich geändert.",
        fileNameChangedErrorMessage: "Der Dateiname konnte nicht geändert werden.",
        fileCreatedMessage: "Die Datei wurde erfolgreich erstellt.",
        newTaskMailDetailPopoverText: "Wenn einer neuen Aufgabe Kanban, Gantt, Kalender oder Aufgabenkomponenten zugewiesen werden, wird der Teil des E-Mail-Inhalts ausgewählt, der der automatisch gesendeten E-Mail an die zugewiesenen Benutzer hinzugefügt wird.",
        taskOnDropMailPopoverText: "Der Teil des E-Mail-Inhalts wird ausgewählt, der der automatisch gesendeten E-Mail an Kanban-, Gantt-, Kalender- oder Aufgabenkomponenten hinzugefügt wird, wenn die Spalte mit einer Aufgabe geändert wird.",
        createdBy: "Erstellt von",
        updatedBy: "Aktualisiert von",
        createdDate: "Erstellungsdatum",
        updatedDate: "Aktualisierungsdatum",
        folderPermissionSettings: "Dateiberechtigungseinstellungen",
        quantity: "Stück",
        fileManagementPermissionSettings: "Dateiverwaltungsberechtigungseinstellungen",
        fileNotFoundErrorText: "Es wurden keine Dateien gefunden, die den Suchkriterien entsprechen.",
        updateTaskMailDetailPopoverText: "Der Teil des E-Mail-Inhalts wird ausgewählt, der der automatisch gesendeten E-Mail an die zugewiesenen Benutzer hinzugefügt wird, wenn Änderungen an den Kanban-, Gantt-, Kalender- oder Aufgabenkomponenten in der relevanten Aufgabe vorgenommen werden.",
        selectOutlookColumnPopoverText: "Die Spalte wird ausgewählt, um die über Outlook hinzuzufügenden Aufgaben einzuschließen.",
        createFolder: "Ordner erstellen",
        versionHistory: "Versionsverlauf",
        deletePermanently: "Endgültig löschen",
        recover: "Wiederherstellen",
        groupPermissions: "Gruppenberechtigungen",
        allowUpload: "Datei-Upload erlauben",
        allowView: "Dateiansicht erlauben",
        allowDownload: "Datei-Download erlauben",
        permissionType: "Berechtigungstyp",
        applyToSubFolders: "Auf Unterordner anwenden",
        newFolderName: "Neuer Dateiname",
        tagTips: "Tags müssen mit einem #-Zeichen versehen sein. Sie dürfen nur Buchstaben und Zahlen enthalten.",
        addAsNewVersion: "Als neue Version hinzufügen",
        addAndChangeName: "Mit Umbenennen hochladen",
        skip: "Überspringen",
        fileExistError: "Datei mit gleichem Namen existiert im Zielordner",
        processFileShow: "Zeige River-Verzeichnisordner",
        processFilePathName: "Name des River-Verzeichnisordners",
        processFilePathId: "ID des River-Verzeichnisordners",
        menuFileShow: "Zeige River-Menüordner",
        menuFilePathName: "Name des River-Menüordners",
        fileCreatedErrorMessage: "Dateierstellung fehlgeschlagen.",
        fileDeletedMessage: "Dateilöschung abgeschlossen.",
        fileDeletedErrorMessage: "Das Löschen der Datei ist fehlgeschlagen.",
        fileMovedMessage: "Dateiverschiebung abgeschlossen.",
        fileMovedErrorMessage: "Das Verschieben der Datei ist fehlgeschlagen.",
        fileCopyMessage: "Dateikopie abgeschlossen.",
        fileCopyErrorMessage: "Der Kopiervorgang der Datei ist fehlgeschlagen.",
        fileDownloadMessage: "Dateidownload abgeschlossen.",
        fileDownloadErrorMessage: "Der Dateidownload ist fehlgeschlagen.",
        fileSizeShowErrorMessage: "Aufgrund der großen Dateigröße kann der Inhalt nicht angezeigt werden. Bitte laden Sie die Datei herunter.",
        unsupportedFile: "Nicht unterstützte Datei",
        fileNameError: "Es gibt bereits eine Datei mit diesem Dateinamen. Bitte versuchen Sie es erneut mit einem anderen Namen.",
        fileGivePermissionMessage: "Dateiberechtigung erfolgreich erteilt.",
        fileGivePermissionErrorMessage: "Fehler bei der Dateiberechtigung. Die Registrierung ist fehlgeschlagen.",
        groupName: "Gruppenname",
        createGroup: "Gruppe erstellen",
        conversation: "Konversationen",
        startConversation: "Konversation starten",
        userNotFound: "Kontakt nicht gefunden",
        chatNotFound: "Chat nicht gefunden",
        themeTypeLight: "Hell",
        themeTypeBorderedLight: "Gerahmt (Hell)",
        themeHalfDark: "Halb Dunkel",
        themeDark: "Dunkel",
        themeRouteAnimation: "Seitenübergangsanimation",
        themeRouteAnimationFadeInLeft: "Links Einblenden",
        themeRouteAnimationZoomIn: "Vergrößern",
        themeRouteAnimationFadeIn: "Einblenden",
        themeRouteAnimationNone: "Keine",
        themeMenuType: "Menütyp",
        themeMenuTypeHorizontal: "Horizontal",
        themeMenuTypeVertical: "Vertikal",
        themeMenuCollapse: "Schmales Menü",
        themeNavbarColor: "Navigationsleistenfarbe",
        themeNavbarType: "Navigationsleistentyp",
        themeSticky: "Anheften",
        themeStatic: "Statisch",
        themeFloating: "Schwebend",
        themeHidden: "Versteckt",
        themeFooterType: "Fußleistentyp",
        catalog: "Katalog",
        riverBoards: "River Boards",
        clipboardDesigner: "Zwischenablagendesigner",
        designMode: "Sie befinden sich im Design-Modus",
        editPage: "Seite bearbeiten",
        rename: "Umbenennen",
        exportToPdf: "Als PDF exportieren",
        exportToExcel: "Als Excel exportieren",
        revenueReport: "Umsatzbericht",
        header: "Titel",
        connection: "Verbindung",
        primaryKey: "Primärschlüssel",
        secondaryKey: "Sekundärschlüssel",
        completedKey: "Abgeschlossener Schlüssel",
        countKey: "Zählschlüssel",
        statusKey: "Statusschlüssel",
        progressConnection: "Fortschrittsverbindung",
        progressScript: "Fortschrittsskript",
        progressPrimaryKey: "Primärschlüssel des Fortschritts",
        progressCountKey: "Zählschlüssel des Fortschritts",
        progressPercentageKey: "Prozentschlüssel des Fortschritts",
        progressColorKey: "Farbschlüssel des Fortschritts",
        keyColumn: "Wertschlüsselspalte",
        currency: "Währung",
        whitePage: "Weiße Seite",
        noReportSelected: "Kein Bericht ausgewählt",
        noTypeSelected: "Kein Berichtstyp ausgewählt",
        whitePageReports: "Berichte auf weißer Seite",
        addBranch: "Zweig/Breakdown hinzufügen",
        branchName: "Breakdown-Name",
        rowNumber: "Sequenznummer",
        reportTypeSelect: "Berichtstyp auswählen",
        reportDescription: "Berichtsbeschreibung...",
        useWeb: "Auf der Webseite verwenden",
        useMobile: "Auf Mobilgeräten verwenden",
        mainScript: "Hauptskript",
        prerunScript: "Vor-Skript ausführen?",
        preScript: "Vor-Skript",
        testIt: "Testen",
        selectAll: "Alle auswählen",
        addReport: "Bericht hinzufügen",
        selectReportType: "Berichtstyp auswählen",
        reportType: "Berichtstyp",
        reportDesingPermission: "Der Benutzer hat keine Berechtigung zum Berichtsdesign.",
        reportImportedOpenLeftMenu: "Bericht importiert. Sie können ihn im linken Menü öffnen.",
        forReportExport: "Für den Berichtsexport müssen Sie zuerst einen Bericht auswählen.",
        anErrorOccurred: "Ein Fehler ist aufgetreten.",
        reportDeleted: "Bericht gelöscht",
        branchDeleted: "Breakdown gelöscht",
        addBranchName: "Bitte geben Sie den Namen des Breakdowns ein",
        addedBranch: "Breakdown hinzugefügt",
        selectConnectionAddress: "Bitte wählen Sie eine Verbindungsadresse",
        enterSqlScript: "Bitte geben Sie das SQL-Skript ein",
        enterReportName: "Bitte geben Sie den Berichtsnamen ein",
        reportAdded: "Bericht hinzugefügt",
        numberOfDataToBeProcessed: "Verbindung erfolgreich. Anzahl der zu verarbeitenden Daten:",
        dataNotFoundCheckYourConnection: "Daten nicht gefunden! Bitte überprüfen Sie Ihre Verbindung und SQL-Befehl",
        connectionNotFoundCheckYourConnection: "Verbindung fehlgeschlagen! Bitte überprüfen Sie Ihre Verbindung und SQL-Befehl",
        fillConnectionAndSql: "Bitte füllen Sie Ihre Verbindung und den SQL-Befehl aus",
        allTypesActivate: "Alle Typen sind zur Anzeigezeit aktiviert",
        passwordRequired: "Passwort ist erforderlich",
        showLess: "Weniger anzeigen",
        showMore: "Mehr anzeigen",
        authority: "Berechtigung",
        preScriptDescription: "Beschreibung des Vor-Skripts",
        enterReportFilters: "Berichtsfilter eingeben",
        gridDesigner: "Grid-Designer",
        addMainBranch: "Haupt-Breakdown hinzufügen",
        resetSettings: "Einstellungen zurücksetzen",
        columsToGroup: "Verwenden Sie das Kontextmenü der Header-Spalten, um Daten zu gruppieren",
        customize: "Anpassen",
        colorType: "Farbtyp",
        formatManagement: "Formatverwaltung",
        bottomTotalColumn: "Untere Gesamtsäule",
        formatType: "Formattyp",
        datagridSettingsReset: "Die Einstellungen des Datengrids werden zurückgesetzt",
        unexpectedError: "Entschuldigung, es ist ein unerwarteter Fehler aufgetreten.",
        changesSaved: "Änderungen wurden gespeichert",
        addGantTask: "Gantt-Aufgabe hinzufügen",
        updGantTask: "Gantt-Aufgabe aktualisieren",
        updSchedulerTask: "Kalenderaufgabe aktualisieren",
        updTodoTask: "Aufgabe im Aufgabenbereich aktualisieren",
        completeTask: "Aufgabe abschließen",
        cantBeEmptyKanban: "Der Aufgabentitel, die Aufgabendetails und die Aufgabenempfänger dürfen nicht leer sein.",
        successAdd: "Die Aufgabe wurde erfolgreich hinzugefügt.",
        successUpd: "Die Aufgabe wurde erfolgreich aktualisiert.",
        statusChangeTodo: "Der Aufgabenstatus wurde erfolgreich geändert.",
        reminderSended: "Erinnerungs-E-Mail für die Aufgabe erfolgreich gesendet.",
        askForCompleteTask: "Sind Sie sicher, dass Sie die Aufgabe abschließen möchten?",
        infoForCompletedTask: "Die Teilaufgaben Ihrer Aufgabe werden ebenfalls abgeschlossen.",
        noPermisison: "Sie sind nicht berechtigt.",
        successDependencyAdded: "Aufgabenabhängigkeit erfolgreich hinzugefügt.",
        filterimportant: "Nach Wichtigkeit filtern",
        enterEmail: "E-Mail-Adresse eingeben",
        reply: "Antworten",
        newCol: "Neue Spalte",
        thickness: "Stärke",
        distanceToUpperComponent: "Abstand zur oberen Komponente",
        companyName: "Firmenname",
        showEmailModule: "E-Mail-Modul anzeigen",
        distanceToBottomComponent: "Abstand von der unteren Komponente",
        canNonAdminUsersPost: "Können nicht-administrative Benutzer Beiträge verfassen",
        columnColor: "Spaltenfarbe",
        byWho: "von",
        updated: "Aktualisiert",
        commentAdded: "Kommentar hinzugefügt",
        completed: "Abgeschlossen",
        addRemoveColumns: "Spalten hinzufügen/entfernen",
        searchMessage: "Nach Nachrichten suchen",
        riverReports: "River BI-Berichte",
        forms: "Formulare",
        searchinProcess: "Suche in Prozessen",
        searchVoiceMessage: "Die Nachricht, die Sie suchen, befindet sich in dieser Audiodatei.",
        searchVoiceMessages: "Durchsuche Voicemails",
        showSocialNotifications: "Soziale Benachrichtigungen anzeigen",
        showProjectManagementNotifications: "Projektmanagement-Benachrichtigungen anzeigen",
        showProcessNotifications: "Prozessbenachrichtigungen anzeigen",
        pageDesingPermission: "Der Benutzer verfügt nicht über Berechtigungen zum Seitenentwurf.",
        filemanagementDesingPermission: "Der Benutzer verfügt nicht über Berechtigungen zur Dateiverwaltung.",
        taskDelete: "Die Aufgabe wird gelöscht.",
        tasksWillDeleted: "Sind Sie sicher, dass Sie die Aufgabe löschen möchten?",
        componentSettings: "Komponenteneinstellungen",
        aboutComponent: "Über die Komponente",
        sureSaveChanges: "Sind Sie sicher, dass Sie Ihre Änderungen speichern möchten?",
        showDeletedNotifications: "Zeige Benachrichtigungen, die ich gelöscht habe",
        shareSometthing: "Etwas teilen",
        resetPageLayout: "Seitenlayout zurücksetzen",
        downloadingSS: "Screenshot wird heruntergeladen. Bitte warten.",
        showHorizontalLines: "Horizontale Linien anzeigen",
        turnOffVerticalHide: "Deaktivieren Sie die vertikale Ausblendung",
        whichVerticalLinesAreToBeHidden: "Welche vertikalen Linien ausgeblendet werden sollen",
        priority: "Priorität",
        desingMode: "Sie befinden sich im Designmodus",
        boardSidebarMessageField: "Aufgaben-Nachrichtenfeld",
        boardSidebarExtraField: "Aufgaben Zusatzkomponentenfeld",
        boardSidebarTaskHistory: "Aufgabenhistorie",
        loadMoreNotifications: "Mehr Benachrichtigungen anzeigen",
        deletedNotifications: "Gelöschte Benachrichtigungen",
        noFormNotification: "Noch keine Prozessbenachrichtigungen",
        noDeletedFormNotifications: "Keine gelöschten Prozessbenachrichtigungen",
        noDeletedBoardNotification: "Keine gelöschten Board-Benachrichtigungen",
        noBoardNotification: "Noch keine Board-Benachrichtigungen",
        noDeletedSocialNotification: "Keine gelöschten Social-Media-Benachrichtigungen",
        noSocialNotification: "Noch keine Social-Media-Benachrichtigungen",
        deleteAll: "Alle löschen",
        markAllRead: "Alle als gelesen markieren",
        board: "Tafel",
        form: "Formular",
        formNotifications: "Formularbenachrichtigungen",
        boardNotifications: "Pano Bildirimleri",
        socialNotifications: "Soziale Benachrichtigungen",
        email: "E-Mail",
        oldFormName: "Alter Formularname",
        newFormName: "Neuer Formularname",
        oldFormVariable: "Alte Form Variable",
        newFormVariable: "Neue Form Variable",
        oldCategory: "Alte Kategorie",
        newCategory: "Neue Kategorie",
        connectionInFile: "Verbindung in Datei",
        yourConnection: "Ihre Verbindung",
        element: "Element",
        script: "Skript",
        formClosingEvent: "Formular Schließungsereignis",
        message: "Nachricht",
        formExportTextError: "Es wurden keine Komponenten zum Formular hinzugefügt oder das Formular wurde nicht gespeichert. Bitte beheben Sie dieses Problem, um einen Fehler bei der Operation zu vermeiden.",
        checkRequiedFields: "Pflichtfelder überprüfen",
        dontCheckRequiredFields: "Pflichtfelder nicht überprüfen",
        createPdfWarning: "Wenn ausgewählt, wird das Formular nicht gespeichert und geschlossen",
        cratePdf: "PDF erstellen",
        identityNumber: "Identifikationsnummer",
        minPaymentHour: "Mindeststunde",
        maxPaymentHour: "Maximale Stunde",
        darkBlue: "Dunkelblau",
        orange: "Orange",
        component: "Komponente",
        properties: "Eigenschaften",
        dmsPath: "DMS Pfad",
        dashboard: "Dashboard",
        slider: "Schieberegler",
        list: "Liste",
        otherImages: "Andere Bilder",
        unitScript: "Einheitsskript",
        updatableMaxQuantity: "Aktualisierbare Max Menge",
        newPage: "Neue Seite",
        code: "Code",
        resetHiddenComponent: "Wert der versteckten Zielkomponenten zurücksetzen",
        bold: "Fett",
        italic: "Kursiv",
        stringFormat: "Zeichenfolgenformat",
        videoUploaded: "Video Hochgeladen",
        fillInAllFields: "Füllen Sie alle Felder aus",
        normalPaging: "Normales Paging",
        pageSize: "Seitengröße",
        special: "Spezial",
        link: "Link",
        fontWeight: "Schriftgewicht",
        pdfFileNotFound: "PDF-Datei nicht gefunden",
        themeMenuHidden: "Verstecktes Menü",
        likedOn: "Gefiel Auf",
       
        messageAddedToTask: "Nachricht zur Aufgabe hinzugefügt",
        deleteTask: "Aufgabe gelöscht",
        subCategory: "Unterkategorie",
        toTheBoard: "an die Tafel",
        hideTaskDetail: "Aufgaben Details ausblenden",
        selectData: "Daten auswählen",
        noContentYet: "Noch Kein Inhalt",
        addRowBetween: "Zeile dazwischen einfügen",
        userPermissions: "Benutzerberechtigungen",
        riverModules: "RIVER Module",
        processHistory: "Prozesshistorie",
        commentHasBeenMadeAtThisStage: "Der Kommentar wurde zu diesem Zeitpunkt hinzugefügt",
        askForPassword: "Nach dem Passwort fragen?",
        passwordIsWrong: "Das Passwort ist falsch, bitte versuchen Sie es erneut.",
        pleaseEnterPassword: "Bitte geben Sie Ihr Passwort ein, um fortzufahren.",
        onePaymentEachSave: "Einmalige Zahlung pro Speichern",
        clearSelection: "Auswahl Aufheben",
        allowDownloadVersionHistoryDocuments: "Herunterladen von Versionsverlaufs-Dokumenten Erlauben",

        delegated: "delegiert",
        openDocxToPdf: "Datei als PDF Öffnen",
        activationStartDate: "Aktivierungsstartdatum",
        activationEndDate: "Aktivierungsenddatum",
        changeActivationDate: "Aktivierungsdatum Ändern",
        showArchive: "Archiv Anzeigen",
        hideArchive: "Archiv Ausblenden",
        tasks: "Aufgaben",
        maxFileCountLimit: "Maximale Dateianzahl",
        fileCountLimit: "Dateianzahl Limit",
        numberOfFilesExceeded: "Dateianzahl überschritten",
        numberOfFilesLimit: "Dateianzahlbegrenzung",
        lineIntermediateAllowAdd: "Zwischenzeile hinzufügen",
        lineIntermediateAllowCopy: "Zwischenzeile kopieren",
        triggerReport: "Triggerbericht",
        setWhitePageReportExportName: "Legen Sie den Exportnamen für White- Page - Berichte fest",
        menuRowUpdated: "Es gibt Datensätze mit automatisch inkrementiertem Wert. Die automatisch inkrementierte Nummer wurde aktualisiert: ",
        isMenuRowControl: "Menüzeilsteuerung",
        sqlScriptWarning: "Die Verwendung dieses Feldes kann zu Leistungsverlusten führen.",

        deletedTask: "Gelöschte Aufgabe",
        getDeletedTasks: "Gelöschte Aufgaben abrufen",
        getTasks: "Aufgaben abrufen",
        movePageDesignersNode: "Das ausgewählte Board/Menu wird in den Ordner verschoben",
        informationAboutMoveToDirectory: "Die Benutzerberechtigungen des verschobenen Boards/Menüs ändern sich entsprechend den Berechtigungen des Ordners, in den es verschoben wird.",
        move: "Verschieben",
        download: "Herunterladen",
        create: "Erstellen",
        statistic: "Statistik",
        priRate: "Zuordnungsrate nach Priorität",
        taskcreatorlist: "Liste der Aufgabenersteller",
        completionRate: "Fertigstellungsrate",
        columnsTaskCount: "Anzahl der Aufgaben in Spalten",
        showKanbanFilter: "Kanban-Filter anzeigen",
        processToStart: "Zu startender Prozess",
        fieldMatching: "Feldübereinstimmung",
        showFileHistory: "Dateiverlauf anzeigen",
        dontLetDeleteWhatTheyAdded: "Sie können löschen, was sie hinzugefügt haben",
        myJobs: "Meine Jobs",
        excelExportName:"Excel-Exportname",
        makeRequiredField: "Feld als erforderlich kennzeichnen",
        makeReadOnly: "Nur-Lesezugriff aktivieren",
        letItWorkProcessContinues: "Lass es arbeiten, wenn der Prozess weiterläuft",
        event: "Ereignis",
        selectEvent: "Ereignis auswählen",
        rowEditor: "Zeileneditor",
        formOpeningComponentEvent: "Formularöffnung Komponentenereignis",
         filterSearch: "In Aufgaben suchen",
        filterForAssignUsers: "Nach Zuständigem filtern",
        filterForAssignUGroups: "Nach Gruppe filtern",
        filterForPri: "Nach Priorität filtern",
        clearFilter: "Filter löschen",
        addedTask: "hinzugefügt",
        updatedTask: "aktualisiert",
        boardToastNotification: "{{0}} hat Aufgabe  {{2}}   in Tabelle {{1}}  {{3}} ",
        completedTask: "erledigt",
        socialToastNotification: "{{0}}  mochte Ihren Beitrag mit {{1}} Inhalt auf  {{2}}",
        processToastNotification: "{{0}} hat das Formular  {{1}} kommentiert",
        yesterday: "Gestern",
        today: "Heute",
        notSaved: "Nicht gespeichert",
        editors: "Editoren",
        basic: "Grundlegend",
        file: "Datei",
        datagridPreferences: "Datagrid-Einstellungen",
        deleteLetsAsk: "Löschen erlauben?",
        addNewRow: "Ohne zu fragen, als neue Zeile hinzufügen",
        oldRowShouldBeDeleted: "Ohne zu fragen, alte Zeilen löschen",
        canBeSearched: "Durchsuchbar?",
        processName: "Prozessname",
        removeReadOnly: "Schreibgeschützte Eigenschaft entfernen",
        dontMakeRequired: "Nicht erforderliches Feld erstellen",
        dataName: "Datenname",
        margin: "Rand",
        rowCount: "Zeilenanzahl",
        colCount: "Spaltenanzahl",
        outsideMargin: "Außenrand",
        widgetName: "Widget Name",
        searchInProcess: "Prozessformulare",


        changeEventScriptRun: "Sollen Änderungsskripte ausgeführt werden, wenn Daten ausgefüllt werden?",
        dontChanceId: "(Da dieses Komponentenformulare verwendet wird, kann die Objektidentität nicht geändert werden.)",
        scriptButtonWarning: "Statuseinstellungen für Skriptschaltflächen werden in Menüformularen nicht unterstützt.",
        description2: "Aussage 2",
        showJobListTabs: "Joblisten-Registerkarten anzeigen",
        showUserPhotosInJobList: "Benutzerfotos in der Jobliste anzeigen",
        dmsVideoGaleryTabWarning: "Sie haben keine Berechtigung, dieses Feld zu verwenden.",
        searchInFormJson: "Suchen Sie im JSON-Formular",
        useExpandedJoblist: "Erweiterte Jobliste verwenden",
        editTour: "Rediger Tur",
        selectComponent: "Vælg Komponent",
        tourComponents: "Turkomponenter",
        addTourElement: "Tilføj Tur Element",
        startTourWhenFormOpen: "Start Tur Når Formular Åbnes",
        compare: "Sammenligne",
        deleteTourElement: "Slet Tur Element",
        tourWillDeleted: "Tur vil blive slettet",
        tourDelete: "Slet Tur",
        fixedRowHeight: "Feste Zeilenhöhe",
        pushWidgetItems: "Widget-Elemente schieben",
        allowMultiLayer: "Mehrere Ebenen erlauben",
        gridType: "Gittertyp",

        useCollapsedJoblist: "Verwenden Sie eine reduzierte Jobliste",
        searchAllData: "Alle Daten durchsuchen",
        scriptsRunAllAreSelected: "Sollen die Skripte ausgeführt werden, wenn alles ausgewählt ist?",
        
        menuSchedulerPermission: "Sie haben keine Berechtigung, Menükarte hinzuzufügen",
        showOnTask: "Sollte der Editor in den Prozessen erscheinen",
        alreadyHaveMenu: "Wenn Sie eine Menükomponente verwenden möchten, darf nur eine Menükomponente im Formular vorhanden sein und es können nicht mehr als eine Menükomponente hinzugefügt werden.",
        select: "Wählen",
        startedUserName: "Gestarteter Benutzername",
        startedUserId: "Gestartete Benutzer-ID",
        processInstanceId: "Prozessinstanz-ID",
        formId: "Formular-ID",
        min: "Minimum",
        difference:"Änderungen zwischen zwei Dateien",
        labelNotFound: "Die Bezeichnung ist in dieser Sprache nicht definiert",
        cleanElement: "Elementfeld beim Drucken von Daten löschen?",
        cleanColumn: "Spaltenbereich beim Drucken von Daten löschen?",
        fileSkippedSuccessfully: "Abgesagt.",
        dmsTags: "Etikett",
        searchInFormContents: "Suche im Formularinhalt",
        jobType: "Auftragstyp",
        workStatus: "Arbeitsstatus",
        or: "Oder",
        signInWithMicrosoft: "Melden Sie sich mit einem Microsoft-Konto an",
        receipt: "Quittung",
        receiptOptions: "Quittungsoptionen",
        tax: "Steuer",
        selectboxWarningMessage: "Wenn die Daten 100 überschreiten, verwenden Sie bitte die Suchkomponente. Die ersten 100 Werte werden angezeigt.",
        degree: "Grad",
        confirmationPopupClose: "Wenn Sie keine Aktion ausführen, wird das Bestätigungs-Popup automatisch geschlossen.",
        confirmationSecondsSetting: "Bestätigungs-Popup-Sekundeneinstellung",
        createPdfAndSendForm: "Soll das Formular gesendet werden, wenn das PDF erstellt wurde?",
        confirmCreatePdfAndSendForm: "Es wird ein PDF erstellt und das Formular gesendet. Bestätigen Sie?",
        _filter: "Filter",
        removeFromFavorites: "Von Favoriten entfernen",
        addToFavorites: "Zu den Favoriten hinzufügen",
        id: "ID",
        assignedUserName: "zugewiesener Benutzername",
        schemeId: "Schema-ID",
        formStatusByUser: "Formularstatus nach Benutzer",
        formSituationByUser: "Formular Situation nach Benutzer",
        isDelegated: "ist delegiert",
        formname: "Formularname",
        formstatus: "Formularstatus",
        timeOutDate: "TimeOut-Datum",
        saveFilterPack: "Filterpaket speichern",
        enterRegistrationName: "Geben Sie den Registrierungsnamen ein",
        filterPackages: "Pakete filtern",
        onlyNumbersError: "Sie müssen einen Wert eingeben, der nur Zahlen enthält.",
        speechLengthError: "Die Rede ist länger als die erforderliche Länge.",
        removeImage: "Entfernen",
        headerBundle: "Header-Paket",
        other: "Andere",
        verticalHeader: "Vertikaler Header",
        exportDataAuth: "Exportgenehmigungen",
        errorFileType: "Die Dateityp ist falsch. Akzeptierter Dateityp: ",
        folder: "Ordner",
        page:"Seite",
        uploadPdfToDms: "Erstelltes PDF auf DMS hochladen",
        uploadPdfToDmsName: "Geben Sie den Namen der PDF-Datei ein, die auf DMS gespeichert werden soll.",
        pdfName: "PDF-Name",
        addTour: "Tour hinzufügen",
        updateTour: "Tour aktualisieren",
        editTourElement: "Tour-Element bearbeiten",
        buttonAction: "Tastenaktion",
        referenceArea: "Referenzbereich",
        fieldToFill: "Auszufüllendes Feld",
        buttonColor: "Button-Farbe",
        signFile: "Datei signieren",
        addNewCategory: "neue Kategorie hinzufügen",
        autoRefreshJoblist: "Jobliste wird automatisch aktualisiert",
        changeScriptRow: "Skriptreihenfolge ändern",
        defaultRowCheckRequired: "Soll die Überprüfung obligatorischer Felder durchgeführt werden, wenn eine Standardzeile vorhanden ist?",
        currentCode: "Aktueller Code",
        screenshotOnMobile: "Screenshot auf dem Handy machen",
        showCallElement: "Anruftaste anzeigen",
        created: "Erstellt",
        keepPermission: "Übertragungsberechtigungen beim Übertragen des Formulars. (Nicht empfohlen, wenn Ihre Benutzer oder Gruppen nicht übereinstimmen)",
        showAddComponentButtonOnDragMenu: "Soll die Hinzufügen-Schaltfläche im linken Ziehmenü angezeigt werden?",
        editCategory: "Kategoriename ändern",
        confirmDeleteCategory: "Kategorie löschen",
        formsWillMoveOnGeneral: "Formulare unter der gelöschten Kategorie werden automatisch in den Ordner 'Allgemein' verschoben.",

        disabledDates: "Deaktivierte Termine",
        fileLimitAndSizeRequired: "Dateigröße oder Dateigrenze darf nicht leer sein",
        relatedFieldChangeDetected: "Verknüpfte Datenänderung erkannt. Wert gelöscht.",
        toastAnonimFormEmailWarning: "In anonymen Formularen ist eine E-Mail-Komponente erforderlich.",
        toastAnonimFormEmailControlWarning: "Wenn das Login-Formularfeld aktiv ist, darf das E-Mail-Feld nicht leer bleiben.",

        poll: "Umfrage",
        quickPoll: "Schnellumfrage",
        pollTitle: "Umfragetitel",
        questionSection: "Fragebereich",
        options: "Optionen",
        enterYourQuestion: "Geben Sie Ihre Frage ein",
        option: "Option",
        addOption: "Option hinzufügen",
        selectGroups: "Gruppen auswählen",
        yourQuestionWillAppearHere: "Ihre Frage wird hier erscheinen",
        optionText: "Optionstext",
        objectFit: "Anpassung des visuellen Verhältnisses",
    }

}