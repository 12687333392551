import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UserService, WebMessageService } from '../../../@shared/services/api';
import { ShepherdService } from 'angular-shepherd';
import { AppInfoService, ComponentService, EventEmitterService } from '../../../@shared/services/common';
import { Subscription } from 'rxjs';
import { IceGroupCandidateModel } from '../../../@shared/models/call-users-model';
import { CallPipe } from '../../../@core/pipes/call.pipe';
import { CallService } from '../../../@shared/services/api/call.service';
import * as bootstrap from 'bootstrap';
import { CdkDrag, CdkDragEnd } from '@angular/cdk/drag-drop';
@Component({
    selector: 'app-call',
    templateUrl: './call.component.html',
    styleUrls: ['./call.component.scss']
})
export class CallComponent implements OnInit {
    @ViewChild(CdkDrag) draggableElement: CdkDrag;
    @ViewChildren('remoteVideo') videoElements!: QueryList<ElementRef>;

    subsStartCall: Subscription;
    subsCallConnections: Subscription;
    sendScreenShareStatus: Subscription;
    sendCallAnswer: Subscription;
    meetingRoom: Subscription;
    endCalling: Subscription;
    exitCalling: Subscription;
    subsReceiveIceCandidate: Subscription;
    subsReceiveOffer: Subscription;
    subsReceiveAnswer: Subscription;
    ClearStreamFunction: Subscription;
    StartCallPopup: Subscription;
    connections: any[] = [];
    meetingId: string;
    participantCon: any = {
        guid: 0,
        connGuid: '',
        from: '',
        to: '',
        connection: false
    }
    isUserAddModalOpen = false;
    onlineUsers: any[];
    currentUserPhoto: any;
    constructor(
        private _ngZone: NgZone,
        private webMessageService: WebMessageService,
        private shepherdService: ShepherdService,
        public appInfo: AppInfoService,
        public changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private eventEmitterService: EventEmitterService,
        private componentService: ComponentService,
        private callService: CallService) {
        this.subsStartCall = this.webMessageService.callEvent.subscribe(data => {
            if (data) {
                if (data.users.includes(this.appInfo.currentUser.id.toString()) && Object.keys(this.peerConnections).length == 0) {
                    this.meetingId = data.meetingId;
                    this.callUserIds = data.users;
                    if (this.callType == "") {
                        this.callType = data.callType;
                    }
                    if (!this.isCaller) {
                        this.camera = false;
                    }
                    this.startCallInterface(data);

                }
            }
        });
        this.subsCallConnections = this.webMessageService.setConnectionsEvent.subscribe(data => {
            const sameConnections = this.connections.some(connection => connection.connectionGuid === data.connectionGuid);
            if (data && !sameConnections) {
                this.connections.push(data);
                console.log(this.connections);
                if (this.connections.length > 1 && !this.isCaller) {
                    /*setTimeout(() => {
                        this.checkConnection();
                    }, 3000);*/
                }
            }
        });

        this.sendScreenShareStatus = this.webMessageService.screenStatus.subscribe(isActive => {
            if (this.callUserIds) {
                if (this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                    this.sheareScreenIsActiveted(isActive);
                }
            }

        });
        this.sendCallAnswer = this.webMessageService.sendCallingAnswer.subscribe(answer => {
            if (this.callUserIds) {
                if (this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                    this.stopRingAudio();
                    if (answer.answer) {
                        if (!this.meetingId || this.meetingId == '') {
                            //this.meetingId = data.meetingId;
                        }
                        console.log("Toplantı Odası:", this.meetingId);

                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                    } else if (this.isCaller) {
                        this.callRejected(answer.userId);
                    }
                }
            }

        });

        this.meetingRoom = this.webMessageService.meetingRoom.subscribe(meetingRoom => {
            if (meetingRoom && this.callUserIds) {
                if (this.callUserIds.includes(this.appInfo.currentUser.id.toString()) && meetingRoom.meetingId == this.meetingId) {
                    if (meetingRoom.usersAccepted.includes(this.appInfo.currentUser.id)) {
                        this.participantCon = this.createParticipantConnections(meetingRoom.usersAccepted, this.appInfo.currentUser.id, meetingRoom.usersActivatedCamera);
                        this.callInterfacePopup = true;
                        //this.callUserIds = meetingRoom.usersAccepted;
                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                        if (this.participantCon.length > 0) {
                            this.participantCon.forEach(participant => {
                                if (!this.peerConnections[participant.guid]) {
                                    let otherParticipantId: string;
                                    if (this.appInfo.currentUser.id == participant.from) {
                                        otherParticipantId = participant.to;
                                    } else if (this.appInfo.currentUser.id == participant.to) {
                                        otherParticipantId = participant.from;
                                    }

                                    const matchedUser = this.appInfo.RiverEntity.users.find(user => user.id == otherParticipantId);
                                    if (matchedUser) {
                                        participant.userName = matchedUser.fullName;

                                        const cameraStatus = participant.userActivatedCamera.find(camera => camera.userId === otherParticipantId);

                                        if (cameraStatus) {
                                            participant.cameraIsActive = cameraStatus.cameraIsActive;
                                        } else {
                                            participant.cameraIsActive = false;
                                        }

                                        if (!participant.cameraIsActive) {
                                            participant.photoUrl = this.appInfo.appUrl + "api/filemanagement/getUserPhoto?id=" + otherParticipantId + '&type=1';
                                        }
                                    }
                                    this.isIncomingCall = false;
                                    this.isCalling = false;
                                    const existingParticipant = this.participants2.find(p => p.connGuid == participant.connGuid);

                                    if (!existingParticipant) {
                                        this.participants2.push(participant);
                                    }
                                    try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                                    if (participant.from == this.appInfo.currentUser.id) {
                                        setTimeout(() => {
                                            this.startGroupCallForAllParticipants(this.callType, this.callUserIds, participant);
                                        }, 2000)
                                    } else {
                                        this.startParticipantGroupCallInterface(this.callType, this.callUserIds, participant);
                                    }
                                }
                            });
                        }
                    } else if (this.isCaller) {
                        //this.callRejected();
                    }
                }
            }

        });
        this.endCalling = this.webMessageService.endCalling.subscribe(endCalling => {
            if (this.callUserIds) {
                if (this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                    if (endCalling) {
                        this.stopVideoCallInterface();
                    }
                }
            }

        });

        this.exitCalling = this.webMessageService.exitCalling.subscribe(data => {

            if (data.callUsers.includes(this.appInfo.currentUser.id.toString())) {
                this.callUserIds = this.callUserIds.filter(userId => userId !== data.currentUser.toString());
                let _conn = this.createSingleParticipantConnection(data.currentUser.toString(), this.appInfo.currentUser.id);
                this.participants2 = this.participants2.filter(participant => participant.connGuid != _conn.connGuid);
                if (this.peerConnections[_conn.connGuid]) {
                    delete this.peerConnections[_conn.connGuid];
                }
                if (Object.keys(this.peerConnections).length < 1) {
                    this.stopVideoCallInterface();
                }
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            }

        });

        this.subsReceiveIceCandidate = this.webMessageService.ReceiveIceCandidate.subscribe(
            candidate => {
                if (candidate) {
                    if (this.callUserIds) {
                        if (this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                            if (this.isCaller) {
                                setTimeout(() => {
                                    this.handleRemoteIceCandidate(candidate);
                                }, 500);
                            } else {
                                setTimeout(() => {
                                    this.handleRemoteIceCandidate(candidate);
                                }, 500);
                            }
                        }
                    }
                }
            },
            error => console.error('ICE adayı alınırken hata oluştu:', error)
        );

        this.subsReceiveOffer = this.webMessageService.ReceiveOffer.subscribe(
            offer => {
                if (offer) {
                    if (this.callUserIds) {
                        if (this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                            this.handleOfferReceived(offer);
                        }
                    }
                }
            },
            error => console.error('Teklif alınırken hata oluştu:', error)
        );

        this.subsReceiveAnswer = this.webMessageService.ReceiveAnswer.subscribe(
            answer => {
                if (answer) {
                    if (this.callUserIds) {
                        if (this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                            this.handleAnswerReceived(answer);
                        }
                    }
                }
            },
            error => console.error('Cevap alınırken hata oluştu:', error)
        );



        //Grup arama alanı
        this.subsReceiveIceCandidate = this.webMessageService.ReceiveGroupIceCandidate.subscribe(
            candidate => {
                if (candidate && this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                    if (candidate.participantId !== this.appInfo.currentUser.id) {
                        if (this.isCaller) {
                            setTimeout(() => this.handleGroupIceCandidate(candidate, candidate.participantId), 500);
                        } else {
                            this.handleGroupIceCandidate(candidate, candidate.participantId);
                        }
                    }
                }
            },
            error => console.error('ICE adayı alınırken hata oluştu:', error)
        );

        // Teklifleri dinle
        this.subsReceiveOffer = this.webMessageService.ReceiveGroupOffer.subscribe(
            offer => {
                if (offer && this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                    if (offer.participantId !== this.appInfo.currentUser.id) {
                        this.handleOfferGroupReceived(offer);
                    }
                }
            },
            error => console.error('Teklif alınırken hata oluştu:', error)
        );

        this.subsReceiveAnswer = this.webMessageService.ReceiveGroupAnswer.subscribe(
            answer => {
                if (answer && this.callUserIds.includes(this.appInfo.currentUser.id.toString())) {
                    const participantId = answer.participantId;
                    if (participantId !== this.appInfo.currentUser.id) {
                        this.createAndSendGroupOffer(participantId);
                    }
                }
            },
            error => console.error('Cevap alınırken hata oluştu:', error)
        );



    }
    currentUser: any;
    ngOnInit(): void {
        this.ringAudio = new Audio();
        this.ringAudio.src = "/assets/others/call-ringtone.mp3";
        this.ringAudio.loop = false;
        this.StartCallPopup = this.eventEmitterService.InvokeStartCallPopup.subscribe(callType => {
            if (callType) {
                this.startCallPopup(callType);
            } 
        });
        this.currentUser = this.appInfo.currentUser.id
        this.userService.onlineUsersSubject.subscribe(onlineUsers => {
            this.onlineUsers = onlineUsers;
        });
        this.currentUserPhoto = this.appInfo.appUrl + "api/filemanagement/getUserPhoto?id=" + this.appInfo.currentUser.id + '&type=1';
    }

    ngOnDestroy() {
        try { if (typeof this.subsStartCall != "undefined") this.subsStartCall.unsubscribe(); } catch (err) { console.error(err) }
        try { if (typeof this.ClearStreamFunction != "undefined") this.ClearStreamFunction.unsubscribe(); } catch (err) { console.error(err) }
    }

    checkAudioLevelWithStats(receiver: RTCRtpReceiver, participantId: number) {
        const participant = this.participants2.find(p => p.connGuid === participantId);
        if (!participant) {
            return;
        }

        let lastSpeakingState = false;
        let lastUpdateTime = 0;

        const checkAudio = () => {
            const currentParticipant = this.participants2.find(p => p.connGuid === participantId);
            if (!currentParticipant) {
                clearInterval(intervalId); 
                return;
            }

            receiver.getStats().then(stats => {
                stats.forEach(report => {
                    if (report.type === 'inbound-rtp' && report.mediaType === 'audio') {
                        const audioLevel = report.audioLevel;
                        const currentSpeakingState = audioLevel && audioLevel > 0.01;

                        const now = Date.now();
                        if (currentSpeakingState !== lastSpeakingState && (now - lastUpdateTime > 200)) {
                            currentParticipant.isSpeaking = currentSpeakingState;
                            lastSpeakingState = currentSpeakingState;
                            lastUpdateTime = now;
                        }
                    }
                });
            }).catch(error => {
                console.error('Ses seviyesi kontrolünde hata oluştu:', error);
            });
        };

        const intervalId = setInterval(checkAudio, 500);

        checkAudio();

    }

    getGroupedParticipants() {
        const grouped = [];
        for (let i = 1; i < this.participants2.length; i += 2) {
            grouped.push(this.participants2.slice(i, i + 2));
        }
        return grouped;
    }



    remoteOffer: RTCSessionDescription | null = null;
    showCallPopup: boolean = false;
    callLoading: boolean = false;
    callType: string = "";
    callUsers: any = [];
    callUsers2: any = [];
    addUsers: any = [];
    selectedCallUsers: any = [];
    selectedAddUsers: any = [];
    selectedCallUser: any = [];
    callInterfacePopup: boolean = false;
    processUsers: [];
    startCallPopup(callType) {
        if (callType == 1) {
            this.selectedCallUsers = [];
            this.camera = false;
            this.checkAndRequestMediaPermissions();
            this.callUsers = this.onlineUsers.filter(user => user.id !== 0 && user.id !== this.appInfo.currentUser.id);
            this.callUsers2 = this.appInfo.RiverEntity.users.filter(user => user.id !== 0 && user.id !== this.appInfo.currentUser.id);
            this.processUsers = this.appInfo.RiverEntity.users.filter(user => user.id !== 0 && user.id !== this.appInfo.currentUser.id && this.componentService.processUsers.includes(user.id.toString()));
            this.groups = this.appInfo.RiverEntity.userGroups;
            this.callLoading = true;
            this.callType = "Görüntülü arama";
            console.log('Görüntülü arama başlatılıyor...');
            this.showCallPopup = true;
            this.callLoading = false;
            console.log(this.userService.onlineUsersSubject);
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        } else if (callType == 2) {
            this.camera = false;
            this.checkAndRequestMediaPermissions();
            this.callUsers = this.onlineUsers.filter(user => user.id !== 0 && user.id !== this.appInfo.currentUser.id);
            this.callLoading = true;
            this.callType = "Sesli arama";
            console.log('Sesli arama başlatılıyor...');
            this.showCallPopup = true;
            this.callLoading = false;
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }


    toggleDropdown() {
        const dropdownMenu = document.getElementById('callDropdownMenu');
        if (dropdownMenu) {
            dropdownMenu.classList.toggle('show');
        }
    }

    private isCaller: boolean = false;

    startCall(selected): void {
        if (selected != null) {
            this.isCaller = true; // Başlatıcı olarak belirle
            //this.selectedCallUsers.push(selected);
            /*if (type == 1) {
                this.callType = "Görüntülü arama"
                this.camera = true;
            } else if (type == 2) {
                this.callType = "Sesli arama"
                this.camera = false;

            }*/
            console.log('Arama başlatılıyor:', this.selectedCallUsers);
            this.meetingId = this.generateMeetingNumber(); //Bir meeting id oluşturuyoruz ve bütün bağlantıları bu meeting id altında kontrol edeceğiz, başka aramalarla çakışmalar yaşanmaması ve kontrol edilmesi daha kolay olması için.
            const selectedUserIds = this.selectedCallUsers.map(user => user.id);
            this.callService.callUsers(selectedUserIds, this.callType, this.appInfo.currentUser.id, this.meetingId, this.camera).subscribe(
                response => {
                    console.log('API yanıtı:', response);
                    //this.startCallInterface(this.callType);
                    this.selectedCallUsers = [];
                },
                error => {
                    console.error('Çağrı başlatılırken bir hata oluştu:', error);
                }
            );
        } else {
            console.log('Lütfen bir kullanıcı seçin.');
        }
    }
    mousewheel(e: any) {
        e.stopPropagation();
    }
    addCallUser(addedUsers): void {
        if (addedUsers) {
            this.callUserIds.forEach(userId => {
                if (!this.selectedAddUsers.includes(userId)) {
                    this.selectedAddUsers.push(userId);
                }
            });
            if (this.callType == "") {
                this.callType = "Görüntülü arama"
            }
            this.callService.callUsers(this.selectedAddUsers, this.callType, this.appInfo.currentUser.id, this.meetingId, this.camera).subscribe(
                response => {
                    console.log('API yanıtı:', response);
                    //this.startCallInterface(this.callType);
                    this.selectedAddUsers = [];
                },
                error => {
                    console.error('Çağrı başlatılırken bir hata oluştu:', error);
                }
            );
            this.closeUserAddModal();
        }
    }
    combinedUsers: any[] = [];
    openUserAddModal() {
        this.addUsers = this.appInfo.RiverEntity.users.filter(user =>
            user.id != 0 &&
            user.id != this.appInfo.currentUser.id &&
            !this.callUserIds.includes(user.id.toString())
        );
        this.updateCombinedUsers();
        this.isUserAddModalOpen = true;
    }

    updateCombinedUsers() {
        const currentUserId = this.appInfo.currentUser.id; 

        const unansweredUsers = this.usersWhoReject.map(user => ({
            id: user.id,
            fullName: user.name + ' (Cevapsız)',
            isUnanswered: true
        }));

        const otherUsers = this.onlineUsers
            .filter(user =>
                !this.usersWhoReject.some(rejectedUser => rejectedUser.id === user.id) &&
                user.id !== currentUserId &&
                !this.callUserIds.includes(user.id.toString())
            )
            .map(user => ({
                id: user.id,
                fullName: user.fullName,
                isUnanswered: false
            }));

        this.combinedUsers = [...unansweredUsers, ...otherUsers];
    }

    closeUserAddModal() {
        this.isUserAddModalOpen = false;
    }
    callUserIds: any[];
    isIncomingCall: boolean = false;
    isCalling: boolean = false;
    callerName: string;
    callingUsers: {
        id: string;
        name: string;
        photoUrl?: string;
        callRejected?: boolean;
    }[] = [];
    callerPhotoUrl: string;
    callingType: number = 0;
    activeCamera(camera: boolean) {
        if (camera) {
            this.camera = !this.camera;
        } else {
            this.camera = !this.camera;
        }
    }
    userSelectionModal: any;
    openUserSelectionModal() {
        const modalElement = document.getElementById('userSelectionModal');
        if (modalElement) {
            this.userSelectionModal = new bootstrap.Modal(modalElement);
            this.userSelectionModal.show();
        }
        this.clearSearchTerm();
    }
    closeUserSelectionModal() {
        if (this.userSelectionModal) {
            this.userSelectionModal.hide();
        }
        this.clearSearchTerm();
    }
    tempSelectedUsers: any[] = [];
    tempSelectedGroups: any[] = [];
    tempSelectedProcessUsers: any[] = [];

    isUserSelected(userId: any): boolean {
        return this.tempSelectedUsers.some(user => user.id === userId);
    }
    isGroupSelected(group) {
        const userIds: number[] = group.users.split(',').filter(id => id).map(id => parseInt(id));
        return this.tempSelectedGroups.some(user => user.id === group.id);
    }
    toggleUserSelection(user: any) {
        const index = this.tempSelectedUsers.findIndex(u => u.id === user.id);
        if (index === -1) {
            this.tempSelectedUsers.push(user);
        } else {
            this.tempSelectedUsers.splice(index, 1); 
        }
    }
    toggleGroupSelection(group: any) {
        const userIds: number[] = group.users.split(',').filter(id => id).map(id => parseInt(id));
        userIds.forEach(id => {
            const index = this.tempSelectedGroups.findIndex(u => u === id);
            if (index === -1) {
                this.tempSelectedGroups.push(id); 
            } 
        });
    }
    isProcessUserSelected(processUserId: any): boolean {
        return this.tempSelectedProcessUsers.some(user => user.id === processUserId);
    }

    toggleProcessUserSelection(processUser: any) {
        const index = this.tempSelectedProcessUsers.findIndex(u => u.id === processUser.id);
        if (index === -1) {
            this.tempSelectedProcessUsers.push(processUser);
        } else {
            this.tempSelectedProcessUsers.splice(index, 1);
        }
    }

    confirmUserSelection() {
        this.selectedCallUsers = [...this.tempSelectedUsers];

        this.tempSelectedGroups.forEach(userId => {
            const user = this.appInfo.RiverEntity.users.find(u => u.id === userId && u.id != this.appInfo.currentUser.id);
            if (user && !this.selectedCallUsers.includes(user)) {
                this.selectedCallUsers.push(user);
            }
        });

        // Süreç kullanıcılarından seçilenleri ekleyelim
        this.tempSelectedProcessUsers.forEach(processUser => {
            if (!this.selectedCallUsers.includes(processUser)) {
                this.selectedCallUsers.push(processUser);
            }
        });

        const modalElement = document.getElementById('userSelectionModal');
        if (this.userSelectionModal) {
            this.userSelectionModal.hide();
        }
        this.clearSearchTerm();
    }

    removeUser(userId: any) {
        this.selectedCallUsers = this.selectedCallUsers.filter(user => user.id !== userId);
        this.isUserSelected(userId);
    }
    searchTerm: string = ''; 
    selectedGroupUsers: any[] = []; 

    groups: any[] = [];

    selectGroup(group: any) {
        this.selectedGroupUsers = group.users;
        this.updateSearchTermFromGroup();
    }

    updateSearchTermFromGroup() {
        this.searchTerm = this.selectedGroupUsers.map(user => user.fullName).join(', ');
    }

    // Kullanıcıları filtrele
    filteredUsers() {
        const usersToFilter = this.selectedGroupUsers.length > 0 ? this.selectedGroupUsers : this.callUsers2;

        if (!this.searchTerm) {
            return usersToFilter;
        }

        return usersToFilter.filter(user =>
            user.fullName.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }
    filteredGroup() {
        const usersToFilter = this.groups.length > 0 ? this.groups : this.callUsers2;

        if (!this.searchTerm) {
            return usersToFilter;
        }

        return usersToFilter.filter(user =>
            user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }
    filteredProcessUsers() {
        const usersToFilter = this.processUsers.length > 0 ? this.processUsers : [];

        if (!this.searchTerm) {
            return usersToFilter;
        }

        return usersToFilter.filter(user =>
            user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }
    clearSearchTerm() {
        this.searchTerm = ''; 
        this.selectedGroupUsers = [];
    }
    isUserOnline(userId: number): boolean {
        return this.onlineUsers.some(onlineUser => onlineUser.id === userId);
    }
    isChatVisible = false;

    toggleFullScreen(participant: any) {
        if (!this.isFullScreen) {
            this.participants2.forEach(p => {
                if (p.connGuid != participant.connGuid)
                    p.isFullScreen = false;
            });

            participant.isFullScreen = !participant.isFullScreen;
        }
        
    }
    isFullScreen = false;

    
    onExpandButtonClick(participant: any) {
        console.log(`${participant.userName} katılımcısının genişletme butonuna tıklandı.`);

        participant.isFullScreen = true;
    }

    exitFullScreen(participant: any, event: Event) {
        event.stopPropagation(); 
        participant.isFullScreen = false; 
    }
    toggleExpanded(participant: any) {

        if (!participant.isFullScreen && participant.cameraIsActive) {
            this.participants2.forEach(p => {
                if (p.connGuid != participant.connGuid)
                    p.isExpanded = false;
            });

            participant.isExpanded = !participant.isExpanded;
        }
    }
    enterFullScreen(participant: any) {
        const videoElement = document.getElementById('remoteVideo_' + participant.connGuid);
        if (videoElement?.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if ((videoElement as any)?.webkitRequestFullscreen) {
            (videoElement as any).webkitRequestFullscreen();
        } else if ((videoElement as any)?.mozRequestFullScreen) {
            (videoElement as any).mozRequestFullScreen();
        } else if ((videoElement as any)?.msRequestFullscreen) {
            (videoElement as any).msRequestFullscreen();
        }
    }
    handleParticipantClick(participant: any) {
        debugger;
        if (participant.isFullScreen) {
            return;
        }

        if (!participant.isExpanded) {
            this.participants2.forEach(p => p.isExpanded = false);
            participant.isExpanded = true;
        } else {
            this.toggleFullScreen(participant);
        }
    }
    toggleChat() {
        this.isChatVisible = !this.isChatVisible;
    }

    onDragEnd(event: CdkDragEnd) {
        const element = event.source.element.nativeElement;
        const rect = element.getBoundingClientRect();

        const outOfBounds =
            rect.right < window.innerWidth * 0.2 ||
            rect.left > window.innerWidth * 0.8 ||
            rect.bottom < window.innerHeight * 0.2 ||
            rect.top > window.innerHeight * 0.8;

        if (outOfBounds) {
            element.style.transform = `translate3d(0px, 0px, 0px)`;

            this.draggableElement.reset();
        }
    }

    isMinimized: boolean = false;
    toggleMinimize() {
        this.isMinimized = !this.isMinimized;
    }

    private startCallInterface(data): void {
        //this.restartMediaStream();
        /*this.callInterfacePopup = true;
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }*/
        this.playRingAudio();
        this.showCallPopup = false;
        if (this.isCaller) {
            this.callingUsers = data.users.filter(u => u != this.appInfo.currentUser.id.toString()).map(userId => {
                const user = this.appInfo.RiverEntity.users.find(u => u.id == userId);
                return {
                    id: user.id,
                    name: user.fullName,
                    photoUrl: this.appInfo.appUrl + "api/filemanagement/getUserPhoto?id=" + userId + '&type=1',
                    callRejected: false
                };
            });

            //this.callerPhotoUrl = this.appInfo.appUrl + "api/filemanagement/getUserPhoto?id=" + caller + '&type=1'
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            if (data.callType === 'Görüntülü arama') {
                //this.callerName = this.appInfo.RiverEntity.users.find(u => u.id == caller).fullName;
                this.isCalling = true;
                this.callingType = 1;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            } else if (data.callType === 'Sesli arama') {
                //this.callerName = this.appInfo.RiverEntity.users.find(u => u.id == caller).fullName;
                this.isCalling = true;
                this.callingType = 2;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                //this.startVoiceCallInterface();
            }
        } else {
            // Katılımcı tarayıcılar için video akışını başlat
            this.checkAndRequestMediaPermissions();
            let filteredUsers = data.users.filter(u => u != this.appInfo.currentUser.id.toString());
            let caller = filteredUsers[filteredUsers.length - 1];
            if (data.callType === 'Görüntülü arama') {
                this.callerName = this.appInfo.RiverEntity.users.find(u => u.id == caller).fullName;
                this.isIncomingCall = true;
                this.callingType = 1;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                setTimeout(() => {
                    if (!this.answerStatus) {
                        this.answerCall(false);
                    }
                }, 10000);
            } else if (data.callType === 'Sesli arama') {
                this.callerName = this.appInfo.RiverEntity.users.find(u => u.id == caller).fullName;
                this.isIncomingCall = true;
                this.callingType = 2;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                setTimeout(() => {
                    if (!this.answerStatus) {
                        this.answerCall(false);
                    }
                }, 10000);
            }
        }
    }
    autoWidth() {
        const baseWidth = 400;
        const userCount = this.callingUsers.length;
        return userCount > 4 ? baseWidth + (userCount - 4) * 100 : baseWidth;
    }

    autoHeight() {
        const baseHeight = 300;
        const userCount = this.callingUsers.length;
        return userCount > 4 ? baseHeight + (userCount - 4) * 100 : baseHeight;
    }
    answerStatus: boolean = false;
    answerCall(answerCall) {
        this.answerStatus = true;
        this.callService.sendCallAnswer(answerCall, this.appInfo.currentUser.id, this.meetingId, this.camera).subscribe(response => {

        });
        if (!answerCall) {
            this.isIncomingCall = false;
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }
    /*private restartMediaStream(): void {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            if (videoDevices.length > 0) {
                navigator.mediaDevices.getUserMedia({ video: { deviceId: videoDevices[0].deviceId }, audio: false })
                    .then(stream => {
                        const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                        localVideoElement.srcObject = stream;
                    })
                    .catch(error => {
                        console.error('Error restarting media stream:', error);
                    });
            }
        });
    }*/
    /*private startParticipantVideoCallInterface(): void {
        this.callInterfacePopup = true;
        this.isIncomingCall = false;
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        const configuration = {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        };

        this.peerConnection = new RTCPeerConnection(configuration);

        this.peerConnection.onicecandidate = (event) => this.handleIceCandidate(event);

        this.peerConnection.ontrack = (event: RTCTrackEvent) => {
            const track = event.track;
            if (this.isActiveScreenShare) {
                this.handleScreenShareStream(event);
            } else {
                this.handleRemoteStream(event);
            }
        };

        navigator.mediaDevices.getUserMedia({
            video: true, audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        })
            .then(stream => {
                this.localStream = stream;

                const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                localVideoElement.srcObject = this.localStream;

                stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));

                this.peerConnection.onconnectionstatechange = () => {
                    console.log('Connection state change:', this.peerConnection.connectionState);
                };

                this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                    if (clear) {
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                });
            })
            .catch(error => {
                console.error('Error accessing camera and microphone:', error);
            });

        this.initializeResize();
    }*/

    /*private startParticipantVoiceCallInterface(): void {
        this.callInterfacePopup = true;
        this.isIncomingCall = false;
        const configuration = {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        };
        this.peerConnection = new RTCPeerConnection(configuration);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                this.localStream = stream;
                const localVideoElement = document.getElementById('localAudio') as HTMLVideoElement;
                if (localVideoElement) {
                    localVideoElement.srcObject = stream;
                }




                stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));

                this.peerConnection.onicecandidate = (event) => this.handleIceCandidate(event);
                this.peerConnection.ontrack = event => {
                    const remoteAudioElement = document.getElementById('remoteAudio') as HTMLAudioElement;
                    remoteAudioElement.srcObject = event.streams[0];
                };
                this.peerConnection.onconnectionstatechange = (state) => {
                    console.log(state);
                };

                this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                    if (clear) {
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                });


            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
            });
    }*/
    /*private createAndSendAnswer(): void {
        this.peerConnection.createAnswer()
            .then(answer => this.peerConnection.setLocalDescription(answer))
            .then(() => this.userService.sendAnswerToServer(this.peerConnection.localDescription))
            .catch(error => console.error('Yanıt oluşturulurken veya gönderilirken hata oluştu:', error));
    }*/
    private peerConnection!: RTCPeerConnection;
    peerConnections: { [participantId: string]: RTCPeerConnection } = {};
    private localStream!: MediaStream;
    private localScreenStream: MediaStream | null = null;
    /*private startVideoCallInterface(): void {
        this.isCalling = false;
        this.callInterfacePopup = true;
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        const configuration = {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        };

        this.peerConnection = new RTCPeerConnection(configuration);

        this.peerConnection.onicecandidate = (event) => this.handleIceCandidate(event);

        this.peerConnection.ontrack = (event: RTCTrackEvent) => {
            if (this.isActiveScreenShare) {
                this.handleScreenShareStream(event);
            } else {
                this.handleRemoteStream(event);
            }
        };

        navigator.mediaDevices.getUserMedia({
            video: true, audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        })
            .then(stream => {
                this.localStream = stream;

                const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                localVideoElement.srcObject = this.localStream;

                stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
                setTimeout(() => {
                    this.createAndSendOffer();
                }, 500);

                this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                    if (clear) {
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 5000);
                    }
                });

            })
            .catch(error => {
                console.error('Error accessing camera and microphone:', error);
            });

    }*/
    usersWhoReject: {
        id: string;
        name: string;
    }[] = [];
    callRejecting: boolean = false;
    callRejected(userId) {
        const user = this.callingUsers.find(u => u.id === userId);
        if (user) {
            user.callRejected = true;
            this.usersWhoReject.push(user);
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
        this.callRejecting = this.callingUsers.every(u => u.callRejected === true);
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }
    isCallingClose() {
        this.isCalling = false;
        this.isCaller = false;
        this.callRejecting = false;
        this.callerPhotoUrl = undefined;
        this.answerStatus = false;
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }
    getCombinedUsers() {
        const unansweredUsers = this.usersWhoReject.map(user => ({
            id: user.id,
            fullName: user.name + ' (Cevapsız)',
            isUnanswered: true
        }));

        const otherUsers = this.onlineUsers.map(user => ({
            id: user.id,
            fullName: user.name,
            isUnanswered: false
        }));

        return [...unansweredUsers, ...otherUsers];
    }

    private screenShareStream!: MediaStream | null;
    screenDisplay: boolean = false;
    startScreenShare(): void {
        this.isActiveScreenShare = true;
        navigator.mediaDevices.getDisplayMedia({ video: true }).then(screenStream => {
            const screenTrack = screenStream.getTracks()[0];

            //this.notifyScreenShareStatus(true);

            setTimeout(() => {
                const localVideoElement = document.getElementById('localScreenVideo') as HTMLVideoElement;
                if (localVideoElement) {
                    localVideoElement.srcObject = screenStream;
                    localVideoElement.play().catch(error => {
                        console.error('Ekran paylaşımı videosu oynatılırken hata oluştu:', error);
                    });
                }
            }, 1500);

            this.screenShareStream = screenStream;
            this.participants2.forEach(_part => {
                let _conGuid = _part.connGuid;
                if (this.peerConnections[_conGuid]) {
                    this.peerConnections[_conGuid].addTrack(screenTrack, screenStream);
                }

                const videoSender = this.peerConnections[_conGuid].getSenders().find(sender => sender.track?.kind === 'video');
                if (videoSender) {
                    videoSender.replaceTrack(screenTrack).then(() => {
                        console.log('Kamera track\'i ekran paylaşımı ile değiştirildi.');
                    }).catch(error => {
                        console.error('Track değiştirilirken hata oluştu:', error);
                    });
                } else {
                    console.error('Video sender bulunamadı.');
                }
            })

            // Ekran paylaşımı sona erdiğinde kameraya geri dön
            screenTrack.onended = () => {
                console.log('Ekran paylaşımı sona erdi.');
                this.isActiveScreenShare = false;
                this.notifyScreenShareStatus(false);

                if (screenStream) {
                    screenStream.getTracks().forEach(track => track.stop());
                }

                // Kamera stream'ini yeniden al ve ekle
                navigator.mediaDevices.getUserMedia({ video: true }).then(cameraStream => {
                    this.participants2.forEach(_part => {
                        let _conGuid = _part.connGuid;
                        const cameraTrack = cameraStream.getTracks().find(track => track.kind === 'video');
                        const videoSender = this.peerConnections[_conGuid].getSenders().find(sender => sender.track?.kind === 'video');

                        if (videoSender && cameraTrack) {
                            videoSender.replaceTrack(cameraTrack).then(() => {
                                console.log('Ekran paylaşımı sona erdi, kamera geri yüklendi.');

                                const localVideoElement = document.getElementById('localScreenVideo') as HTMLVideoElement;
                                if (localVideoElement) {
                                    localVideoElement.srcObject = cameraStream;
                                    localVideoElement.play().catch(error => {
                                        console.error('Kamera videosu oynatılırken hata oluştu:', error);
                                    });
                                }
                            }).catch(error => {
                                console.error('Kamera track\'i geri yüklenirken hata oluştu:', error);
                            });
                        } else {
                            console.error('Video sender ya da kamera track bulunamadı.');
                        }
                    });

                    this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                        if (clear) {
                            cameraStream.getTracks().forEach(track => {
                                track.stop();
                            });
                            setTimeout(() => {
                                window.dispatchEvent(new Event('resize'));
                            }, 5000);
                        }
                    });
                }).catch(error => {
                    console.error('Kamera alınırken hata oluştu:', error);
                });
            };
        }).catch(error => {
            console.error('Ekran paylaşımı başlatılırken hata oluştu:', error);
        });
    }
    stopScreenShare(): void {
        if (this.screenShareStream) {
            this.screenShareStream.getTracks().forEach(track => track.stop());
            this.screenDisplay = false;
            this.screenShareStream = null;
            this.isActiveScreenShare = false;
            //this.notifyScreenShareStatus(false);
            navigator.mediaDevices.getUserMedia({ video: true }).then(cameraStream => {
                this.participants2.forEach(_part => {
                    let _conGuid = _part.connGuid;
                    const cameraTrack = cameraStream.getTracks().find(track => track.kind === 'video');
                    const videoSender = this.peerConnections[_conGuid].getSenders().find(sender => sender.track?.kind === 'video');

                    if (videoSender && cameraTrack) {
                        videoSender.replaceTrack(cameraTrack).then(() => {
                            console.log('Ekran paylaşımı sona erdi, kamera geri yüklendi.');

                            const localVideoElement = document.getElementById('localScreenVideo') as HTMLVideoElement;
                            if (localVideoElement) {
                                localVideoElement.srcObject = cameraStream;
                                localVideoElement.play().catch(error => {
                                    console.error('Kamera videosu oynatılırken hata oluştu:', error);
                                });
                            }
                        }).catch(error => {
                            console.error('Kamera track\'i geri yüklenirken hata oluştu:', error);
                        });
                    } else {
                        console.error('Video sender ya da kamera track bulunamadı.');
                    }
                });

            }).catch(error => {
                console.error('Kamera alınırken hata oluştu:', error);
            });
        }
    }
    private handleRemoteStream(event: RTCTrackEvent, user = 0): void {
        var remoteVideoElement;
        var remoteVideoElementId;
        if (user != 0) {
            remoteVideoElementId = `remoteVideo_${user}`;
            remoteVideoElement = document.getElementById(remoteVideoElementId) as HTMLVideoElement;
        } else {
            remoteVideoElement = document.getElementById('remoteVideo') as HTMLVideoElement;
        }


        if (remoteVideoElement) {
            remoteVideoElement.srcObject = event.streams[0];
        } else {
            console.warn(`Video elementi bulunamadı: ${remoteVideoElementId}`);
        }
        const track = event.track;
        if (track.kind === 'video') {
            if (remoteVideoElement) {
                remoteVideoElement.srcObject = null;

                remoteVideoElement.srcObject = event.streams[0];
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                remoteVideoElement.play().catch(error => {
                    console.error('Kamera videosu oynatılırken hata oluştu:', error);
                });
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            } else {
                console.error('Kamera videosunu gösterecek video element bulunamadı.');
            }
        } else {
            console.log('Bu track bir video track değil, atlanıyor.');
        }
        this.initializeResize();
    }
    private handleScreenShareStream(event: RTCTrackEvent): void {
        const localScreenVideoElement = document.getElementById('localScreenVideo') as HTMLVideoElement;

        // Ekran paylaşımı gelen stream'i localScreenVideo elementine bağlıyoruz çünkü burada katılımcı tarayıcılar tekrardan istek veya cevap oluşturamıyorlar
        if (localScreenVideoElement) {
            localScreenVideoElement.srcObject = event.streams[0];
            localScreenVideoElement.play().catch(error => {
                console.error('Ekran paylaşımı oynatılırken hata oluştu:', error);
            });
        } else {
            console.error('Ekran paylaşımını gösterecek video element bulunamadı.');
        }
    }
    private handleIceCandidate(event: RTCPeerConnectionIceEvent, user = 0): void {
        if (event.candidate) {
            if (user != 0) {
                this.callService.sendIceCandidateToServer(event.candidate, user);
            } else {
                this.callService.sendIceCandidateToServer(event.candidate);
            }
        }
    }
    /*private startPeerConnection(): void {
        const configuration = {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        };
        this.peerConnection = new RTCPeerConnection(configuration);

    }
    private initializeCallListeners(): void {
        *//*this.userService.receiveIceCandidate().subscribe(
    this.handleRemoteIceCandidate.bind(this),
    error => console.error('ICE adayı alınırken hata oluştu:', error)
);

this.userService.receiveOffer().subscribe(
    this.handleOfferReceived.bind(this),
    error => console.error('Teklif alınırken hata oluştu:', error)
);

this.userService.receiveAnswer().subscribe(
    this.handleAnswerReceived.bind(this),
    error => console.error('Cevap alınırken hata oluştu:', error)
);*//*
}*/
    private createAndSendOffer(user = 0): void {
        if (user != 0) {
            this.peerConnections[user].createOffer()
                .then(offer => this.peerConnections[user].setLocalDescription(offer))
                .then(() => {
                    this.callService.sendOfferToServer(this.peerConnections[user].localDescription, user);
                })
                .catch(error => console.error('Teklif oluşturulurken hata oluştu:', error));
        }

    }
    /*private createPeerConnection(): void {
        if (!this.peerConnection) {
            this.peerConnection = new RTCPeerConnection(*//* ICE server config *//*);
}
}*/
    private handleOfferReceived(offer: any): void {
        this.remoteOffer = new RTCSessionDescription(offer);
        const filteredParticipants = this.participantCon.filter(participant => participant.connGuid == offer.user);
        if (filteredParticipants.length > 0 && filteredParticipants[0].to == this.appInfo.currentUser.id) {
            // Bu tarayıcı (katılımcı) bir teklif aldı ve yanıt oluşturmalı
            if (!offer || !offer.type || !offer.sdp) {
                console.error('Geçersiz teklif:', offer);
                return;
            }
            if (this.peerConnections[offer.user]) {
                this.peerConnections[offer.user].setRemoteDescription(new RTCSessionDescription(offer))
                    .then(() => {
                        if (this.peerConnections[offer.user].signalingState !== "stable") {
                            console.warn("Bağlantı hala kurulmamış durumda, createAnswer çağırılmadan önce beklenmeli.");
                        }
                        return this.peerConnections[offer.user].createAnswer();
                    })
                    .then(answer => {
                        if (!answer) {
                            throw new Error("Cevap oluşturulamadı (null veya undefined döndü)");
                        }
                        return this.peerConnections[offer.user].setLocalDescription(answer);
                    })
                    .then(() => {
                        if (!this.peerConnections[offer.user].localDescription) {
                            throw new Error("Local description boş, cevap oluşturulamadı.");
                        }
                        this.callService.sendAnswerToServer(this.peerConnections[offer.user].localDescription, offer.user);
                    })
                    .catch(error => console.error('Teklif işlenirken hata oluştu:', error));
            }
        }
    }

    /*callAnswerUser() {
        debugger;
        this.peerConnection.setRemoteDescription(new RTCSessionDescription(this.remoteOffer))
            .then(() => this.peerConnection.createAnswer())
            .then(answer => this.peerConnection.setLocalDescription(answer))
            .then(() => this.userService.sendAnswerToServer(this.peerConnection.localDescription))
            .catch(error => console.error('Teklif işlenirken hata oluştu:', error));
    }*/

    private handleAnswerReceived(answer: any): void {
        if (!answer || !answer.type || !answer.sdp) {
            console.error('Geçersiz cevap:', answer);
            return;
        }
        if (this.peerConnections[answer.user]) {
            if (this.peerConnections[answer.user].signalingState === 'stable') {
                console.warn('PeerConnection zaten stable durumda. Remote description ayarlanmıyor.');
                return;
            }

            this.peerConnections[answer.user].setRemoteDescription(new RTCSessionDescription(answer))
                .catch(error => console.error('Cevap işlenirken hata oluştu:', error));
        }
    }

    private handleRemoteIceCandidate(candidate: any): void {
        if (!candidate || !candidate.candidate || !candidate.sdpMid || candidate.sdpMLineIndex === null) {
            console.error('Geçersiz ICE adayı:', candidate);
            return;
        }
        if (this.peerConnections[candidate.user]) {
            if (!this.peerConnections[candidate.user].remoteDescription) {
                console.warn('Uzak tanım ayarlanmamış. ICE adayı eklenemiyor.');
                return;
            }

            const iceCandidate = new RTCIceCandidate({
                candidate: candidate.candidate,
                sdpMid: candidate.sdpMid,
                sdpMLineIndex: candidate.sdpMLineIndex
            });

            this.peerConnections[candidate.user].addIceCandidate(iceCandidate)
                .catch(error => console.error('ICE adayını işlerken hata oluştu:', error));
            setTimeout(() => {
                const filteredParticipants = this.participantCon.filter(participant => participant.connGuid == candidate.user);
                if (filteredParticipants.length > 0 && filteredParticipants[0].from == this.appInfo.currentUser.id) {
                    this.peerConnections[candidate.user].createOffer().then(offer => {
                        return this.peerConnections[candidate.user].setLocalDescription(offer);
                    }).then(() => {
                        this.callService.sendOfferToServer(this.peerConnections[candidate.user].localDescription);
                    }).catch(error => {
                        console.error('Offer oluşturulurken hata oluştu:', error);
                    });
                }
            }, 5000);
        }
    }
    private notifyScreenShareStatus(isActive: boolean): void {
        this.callService.sendScreenShareStatus(isActive).subscribe(_ret => {
            if (_ret) {
                console.log("111111111111111111111111", _ret)
            } else {
                console.log("22222222222222222222222222222222", _ret)
            }
        });
    }
    endCall() {
        this.callService.endCall(true, this.meetingId).subscribe(response => {
            this.participants = [];
        });
    }

    exitCall() {
        this.callService.exitCall(this.appInfo.currentUser.id, this.callUserIds, this.meetingId).subscribe(response => {
            this.participants = [];
        });
        this.stopVideoCallInterface();
    }
    stopVideoCallInterface(): void {
        this.eventEmitterService.clearStreamFunction(true);
        this.participants = [];
        this.participants2 = [];
        this.connections = [];
        this.usersWhoReject = [];
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture()
                .then(() => {
                    this.stopMediaTracks();
                    this.cleanUpVideoElement();
                    this.closePeerConnection();
                    this.callInterfacePopup = false;
                })
                .catch(error => console.error('PiP exit error:', error));
        } else {
            this.stopMediaTracks();
            this.cleanUpVideoElement();
            this.closePeerConnection();
            this.callInterfacePopup = false;
            this.isCaller = false;
            this.callUserIds = [];
        }
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }

    private stopMediaTracks(): void {
        if (this.localStream) {
            this.localStream.getTracks().forEach(track => {
                track.stop();
                track.enabled = false;
            });
            this.localStream = null!;
        }

        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                stream.getTracks().forEach(track => track.stop());
            })
            .catch(error => console.error("Error stopping media devices:", error));
    }

    private cleanUpVideoElement(): void {
        const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
        if (localVideoElement) {
            localVideoElement.srcObject = null;
        }
    }

    private closePeerConnection(): void {
        if (this.peerConnection) {
            this.peerConnection.getSenders().forEach(sender => {
                if (sender.track) {
                    sender.track.stop();
                }
            });
            this.peerConnection.close();
            this.peerConnection = null!;
        }

        Object.keys(this.peerConnections).forEach(connectionGuid => {
            const peer = this.peerConnections[connectionGuid];
            if (peer) {
                peer.getSenders().forEach(sender => {
                    if (sender.track) {
                        sender.track.stop();
                    }
                });
                peer.close();
                delete this.peerConnections[connectionGuid];
            }
        });
    }

    /*private startVoiceCallInterface(): void {
        this.isCalling = false;
        this.callInterfacePopup = true;
        const configuration = {
            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        };

        this.peerConnection = new RTCPeerConnection(configuration);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const localAudioElement = document.getElementById('localAudio') as HTMLAudioElement;
                localAudioElement.srcObject = stream;

                stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));

                this.peerConnection.onicecandidate = (event) => this.handleIceCandidate(event);

                this.peerConnection.ontrack = event => {
                    const remoteAudioElement = document.getElementById('remoteAudio') as HTMLAudioElement;
                    remoteAudioElement.srcObject = event.streams[0];
                };

                stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
                setTimeout(() => {
                    this.createAndSendOffer();
                }, 500);

                this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                    if (clear) {
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 5000);
                    }
                });
            })
            .catch(error => {
                console.error('Mikrofon erişim hatası:', error);
            });
    }*/
    isActiveScreenShare: boolean = false;
    sheareScreenIsActiveted(status: boolean) {
        this.isActiveScreenShare = status;
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
    }
    toggleMute(): void {
        const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
        const tracks = this.localStream.getAudioTracks();
        tracks.forEach(track => track.enabled = !track.enabled);
    }

    isCameraOn: boolean = true; 

    toggleCamera() {
        if (this.localStream) {
            const videoTrack = this.localStream.getVideoTracks()[0];

            if (videoTrack.enabled) {
                videoTrack.enabled = false;

                this.participants2.forEach(_part => {
                    const _conGuid = _part.connGuid;
                    const videoSender = this.peerConnections[_conGuid].getSenders().find(sender => sender.track?.kind === 'video');

                    if (videoSender) {
                        videoSender.replaceTrack(null).then(() => {
                            console.log('Kamera durduruldu ve video akışı kaldırıldı.');
                        }).catch(error => {
                            console.error('Video track durdurulurken hata oluştu:', error);
                        });
                    }
                });

                console.log('Kamera kapatıldı.');
            } else {
                videoTrack.enabled = false;
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then(cameraStream => {
                        const cameraTrack = cameraStream.getTracks().find(track => track.kind === 'video');

                        this.participants2.forEach(_part => {
                            const _conGuid = _part.connGuid;
                            const videoSender = this.peerConnections[_conGuid].getSenders().find(sender => sender.track?.kind === 'video');

                            if (videoSender && cameraTrack) {
                                videoSender.replaceTrack(cameraTrack).then(() => {
                                    console.log('Kamera geri yüklendi.');
                                }).catch(error => {
                                    console.error('Kamera track\'i geri yüklenirken hata oluştu:', error);
                                });
                            } else {
                                console.error('Video sender ya da kamera track bulunamadı.');
                            }
                        });
                    })
                    .catch(error => {
                        console.error('Kamera açılırken hata oluştu:', error);
                    });
            }
        } else {
            console.error('Yerel akış bulunamadı.');
        }
    }


    videoInterface: any;
    initializeResize() {
        this.videoInterface = document.querySelector('.video-interface');
        const resizeHandle = document.querySelector('.resize-handle');

        if (resizeHandle && this.videoInterface) {
            resizeHandle.addEventListener('mousedown', (event: MouseEvent) => {
                const startX = event.clientX;
                const startY = event.clientY;
                const startWidth = this.videoInterface.clientWidth;
                const startHeight = this.videoInterface.clientHeight;

                const mouseMoveHandler = (e: MouseEvent) => {
                    this.videoInterface.style.width = (startWidth + e.clientX - startX) + 'px';
                    this.videoInterface.style.height = (startHeight + e.clientY - startY) + 'px';
                };

                const mouseUpHandler = () => {
                    document.removeEventListener('mousemove', mouseMoveHandler);
                    document.removeEventListener('mouseup', mouseUpHandler);
                };

                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            });
        }
    }




    // Grup arama alanları aşağıdaki alanlarda yapılacak





    participants: any[] = [];
    participants2: any[] = [];

    /*private startGroupCallInterface(callType: string, participants: any[]): void {
        this.participants.push(...participants);
        this.callInterfacePopup = true;
        participants.forEach(user => {
            if (user != this.appInfo.currentUser.id) {
                this.isCalling = false;
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                const configuration = {
                    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
                };

                this.peerConnections[user] = new RTCPeerConnection(configuration);

                this.peerConnections[user].onicecandidate = (event) => this.handleIceCandidate(event, user);

                this.peerConnections[user].ontrack = (event: RTCTrackEvent) => {
                    if (this.isActiveScreenShare) {
                        this.handleScreenShareStream(event);
                    } else {
                        this.handleRemoteStream(event, user);
                    }
                };

                navigator.mediaDevices.getUserMedia({
                    video: true, audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        autoGainControl: true
                    }
                })
                    .then(stream => {
                        this.localStream = stream;

                        const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                        localVideoElement.srcObject = this.localStream;

                        stream.getTracks().forEach(track => this.peerConnections[user].addTrack(track, stream));
                        setTimeout(() => {
                            this.createAndSendOffer(user);
                        }, 250);

                        this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                            if (clear) {
                                stream.getTracks().forEach(track => {
                                    track.stop();
                                });
                                setTimeout(() => {
                                    window.dispatchEvent(new Event('resize'));
                                }, 500);
                            }
                        });

                    })
                    .catch(error => {
                        console.error('Error accessing camera and microphone:', error);
                    });
            }
        });
    }*/

    private startParticipantGroupCallInterface(callType: string, participants: any[], participantCon): void {
        this.isIncomingCall = false;
        if (callType == "Sesli arama") {
            this.camera = false;
        }
        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }

        if (participantCon.to == this.appInfo.currentUser.id) {
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            const configuration = {
                iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
            };

            this.peerConnections[participantCon.connGuid] = new RTCPeerConnection(configuration);

            this.peerConnections[participantCon.connGuid].onicecandidate = (event) => this.handleIceCandidate(event, participantCon.connGuid);

            this.peerConnections[participantCon.connGuid].ontrack = (event: RTCTrackEvent) => {
                this.participantCon.forEach(participant => {
                    if (participant.connGuid === participantCon.connGuid) {
                        const cameraStatus = participant.userActivatedCamera.find(camera => camera.userId === participant.from);
                        if (cameraStatus && cameraStatus.cameraIsActive) {
                            this.handleRemoteGroupStream(event, participantCon.connGuid)
                        } else {
                            if (event.track.kind === 'video') {
                                console.log(`Kullanıcı ${participant.userId} kamerası kapalı, video gönderilmiyor.`);
                            } else {
                                this.handleRemoteGroupStream(event, participantCon.connGuid);
                            }
                        }
                    }
                });
            };

            navigator.mediaDevices.getUserMedia({
                video: true, audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }
            })
                .then(stream => {
                    this.localStream = stream;

                    if (this.camera) {
                        const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                        localVideoElement.srcObject = this.localStream;
                    }

                    stream.getTracks().forEach(track => this.peerConnections[participantCon.connGuid].addTrack(track, stream));

                    /*this.peerConnections[user].onconnectionstatechange = () => {
                        if (this.peerConnections[user].connectionState == "connected") {
                            this.eventEmitterService.setConnections(user);
                        }
                        console.log('Connection state change:', this.peerConnections[user].connectionState);
                    };
                    if (this.peerConnections[user].iceConnectionState != "connected" && user == 3 && this.appInfo.currentUser.id == 1) {
                        this.startGroupCallInterface(callType, user);
                    }*/

                    this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                        if (clear) {
                            stream.getTracks().forEach(track => {
                                track.stop();
                            });
                        }
                    });
                })
                .catch(error => {
                    if (error.name === 'NotAllowedError') {
                        console.error('Camera or microphone permissions denied. Checking individual permissions...');
                        (navigator.permissions as any).query({ name: 'microphone' }).then((microphoneStatus: any) => {
                            if (microphoneStatus.state === 'granted') {
                                console.log('Microphone permission is granted. Trying to access microphone only.');
                                navigator.mediaDevices.getUserMedia({
                                    video: false,
                                    audio: {
                                        echoCancellation: true,
                                        noiseSuppression: true,
                                        autoGainControl: true
                                    }
                                })
                                    .then(audioStream => {
                                        this.localStream = audioStream;
                                        audioStream.getTracks().forEach(track => this.peerConnections[participantCon.connGuid].addTrack(track, audioStream));
                                        
                                    })
                                    .catch(microphoneError => {
                                        console.error('Error accessing microphone:', microphoneError);
                                    });
                            } else {
                                console.error('No permission for microphone or camera.');
                            }
                        });
                    } else {
                        console.error('Error accessing camera and microphone:', error);
                    }
                });

            this.initializeResize();
        }

    }

    private generateConnectionGuid(participant1: any, participant2: any): number {
        const sortedParticipants = [participant1, participant2].sort();
        const guidString = `${sortedParticipants[0]}${sortedParticipants[1]}`;
        const parseguid = parseInt(guidString);
        return parseguid;
    }

    /*private createPeerConnectionWithGuid(participant: any, otherParticipant: any, callType: string): void {
        const connectionGuid = this.generateConnectionGuid(participant, otherParticipant);
        const sameParticipants = this.participants2.some(connection => connection.connectionGuid === connectionGuid);
        if (!sameParticipants) {
            this.participants2.push(connectionGuid);
        }
        if (this.peerConnections[connectionGuid]) {
            console.log(`Bağlantı zaten mevcut: ${connectionGuid}`);
            return;
        }

        const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
        this.peerConnections[connectionGuid] = new RTCPeerConnection(configuration);

        this.peerConnections[connectionGuid].onicecandidate = (event) => this.handleIceCandidate(event, connectionGuid);
        this.peerConnections[connectionGuid].onconnectionstatechange = () => {
            if (this.peerConnections[connectionGuid].connectionState == "connected") {
                this.callService.setConnections(connectionGuid, this.callUserIds).subscribe(data => {
                    console.log(data);
                });
            }
            console.log('Connection state change:', this.peerConnections[connectionGuid].connectionState);
        };
        this.peerConnections[connectionGuid].ontrack = (event: RTCTrackEvent) => {
            if (this.isActiveScreenShare) {
                this.handleScreenShareStream(event);
            } else {
                const track = event.track;
                if (track.kind === 'video') {
                    this.handleRemoteGroupStream(event, connectionGuid)
                };
            }
        };

        navigator.mediaDevices.getUserMedia({
            video: true, audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        })
            .then(stream => {
                this.localStream = stream;

                const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                localVideoElement.srcObject = this.localStream;

                stream.getTracks().forEach(track => this.peerConnections[connectionGuid].addTrack(track, stream));

                setTimeout(() => {
                    this.createAndSendOffer(connectionGuid);
                }, 250);
            })
            .catch(error => {
                console.error('Error accessing camera and microphone:', error);
            });
    }*/
    private startGroupCallForAllParticipants(callType: string, participants: any[], participantCon): void {
        this.participants.push(...participants);
        if (callType == "Sesli arama") {
            this.camera = false;
        }
        this.createConnectionParticipant(participantCon);
        /*participants.forEach(participant => {
            participants.forEach(otherParticipant => {
                if (participant !== otherParticipant && participant == this.appInfo.currentUser.id) {
                    this.createPeerConnectionWithGuid(participant, otherParticipant, callType);
                }
            });
        });*/
    }
    camera: boolean = false;
    createConnectionParticipant(participantCon) {
        if (this.peerConnections[participantCon.connGuid]) {
            console.log(`Bağlantı zaten mevcut: ${participantCon.connGuid}`);
            return;
        }

        const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
        this.peerConnections[participantCon.connGuid] = new RTCPeerConnection(configuration);

        this.peerConnections[participantCon.connGuid].onicecandidate = (event) => this.handleIceCandidate(event, participantCon.connGuid);
        this.peerConnections[participantCon.connGuid].onconnectionstatechange = () => {
            if (this.peerConnections[participantCon.connGuid].connectionState == "connected") {
                /*this.callService.setConnections(participantCon.connGuid, this.callUserIds).subscribe(data => {
                    console.log(data);
                });*/
                participantCon.connection = true;
            }
            console.log('Connection state change:', this.peerConnections[participantCon.connGuid].connectionState);
        };
        this.peerConnections[participantCon.connGuid].ontrack = (event: RTCTrackEvent) => {
            
            this.participantCon.forEach(participant => {
                if (participant.connGuid === participantCon.connGuid) {
                    const cameraStatus = participant.userActivatedCamera.find(camera => camera.userId === participant.to);
                    if (cameraStatus && cameraStatus.cameraIsActive) {
                        this.handleRemoteGroupStream(event, participantCon.connGuid)
                    } else {
                        if (event.track.kind === 'video') {
                            console.log(`Kullanıcı ${participant.userId} kamerası kapalı, video gönderilmiyor.`);
                        } else {
                            this.handleRemoteGroupStream(event, participantCon.connGuid);
                        }
                    }
                }
            });
                
        };
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        })
            .then(stream => {
                this.localStream = stream;
                if (this.camera) {
                    const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                    localVideoElement.srcObject = this.localStream;
                }

                stream.getTracks().forEach(track => this.peerConnections[participantCon.connGuid].addTrack(track, stream));

                setTimeout(() => {
                    this.createAndSendOffer(participantCon.connGuid);
                }, 1250);
            })
            .catch(error => {
                if (error.name === 'NotAllowedError') {
                    console.error('Camera or microphone permissions denied. Checking individual permissions...');
                    (navigator.permissions as any).query({ name: 'microphone' }).then((microphoneStatus: any) => {
                        if (microphoneStatus.state === 'granted') {
                            console.log('Microphone permission is granted. Trying to access microphone only.');
                            navigator.mediaDevices.getUserMedia({
                                video: false,
                                audio: {
                                    echoCancellation: true,
                                    noiseSuppression: true,
                                    autoGainControl: true
                                }
                            })
                                .then(audioStream => {
                                    this.localStream = audioStream;
                                    audioStream.getTracks().forEach(track => this.peerConnections[participantCon.connGuid].addTrack(track, audioStream));
                                    setTimeout(() => {
                                        this.createAndSendOffer(participantCon.connGuid);
                                    }, 1250);
                                })
                                .catch(microphoneError => {
                                    console.error('Error accessing microphone:', microphoneError);
                                });
                        } else {
                            console.error('No permission for microphone or camera.');
                        }
                    });
                } else {
                    console.error('Error accessing camera and microphone:', error);
                }
            });
    }
    /*meeting = [{
        joinedUsers: [10, 78, 16],

        users: [10, 5, 3, 78, 16]

    }]*/

    /*private startGroupCallForAllParticipants2(callType: string, participants: any[]): void {
        this.participants.push(...participants);
        this.callInterfacePopup = true;

        let arr = "3 , 5, 8"

        participants.forEach(participant => {
            participants.forEach(otherParticipant => {
                if (participant !== otherParticipant && participant == this.appInfo.currentUser.id) {
                    let guid = this.generateConnectionGuid(participant, otherParticipant);
                    const sameParticipants = this.participants2.some(connection => connection.connectionGuid === guid);
                    if (!sameParticipants) {
                        this.participants2.push(guid);
                    }
                }
            });
        });
    }*/

    /*private createPeerConnectionsForGroup(participants: any[], stream: MediaStream): void {
        const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };

        participants.forEach(participant => {
            const peerConnection = new RTCPeerConnection(configuration);
            this.peerConnections[participant.id] = peerConnection;

            peerConnection.onicecandidate = (event) => this.handleGroupIceCandidate(event, participant.id);
            peerConnection.ontrack = (event: RTCTrackEvent) => this.handleRemoteGroupStream(event, participant.id);

            stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
        });
    }*/

    private createAndSendGroupOffer(participantId: string): void {
        const peerConnection = this.peerConnections[participantId];
        if (!peerConnection) {
            console.error(`Peer connection bulunamadı: ${participantId}`);
            return;
        }

        peerConnection.createOffer()
            .then(offer => peerConnection.setLocalDescription(offer))
            .then(() => {
                this.callService.sendGroupOfferToServer(peerConnection.localDescription, participantId);
            })
            .catch(error => console.error('Teklif oluşturulamadı:', error));
    }

    private handleOfferGroupReceived(offer: any): void {
        const participantId = offer.ParticipantId;
        this.peerConnections[participantId].setRemoteDescription(new RTCSessionDescription(offer))
            .then(() => this.peerConnections[participantId].createAnswer())
            .then(answer => this.peerConnections[participantId].setLocalDescription(answer))
            .then(() => {
                this.callService.sendGroupAnswerToServer(this.peerConnections[participantId].localDescription, participantId);
            })
            .catch(error => console.error('Teklif işlenirken hata oluştu:', error));
    }

    private handleGroupIceCandidate(event: RTCPeerConnectionIceEvent | IceGroupCandidateModel, participantId?: string): void {
        if ('candidate' in event && event.candidate) {
            this.callService.sendGroupIceCandidateToServer(event.candidate, participantId);
        }
    }

    private handleRemoteGroupStream(event: RTCTrackEvent, participantId: number): void {
        const remoteVideoElementId = `remoteVideo_${participantId}`;
        const remoteVideoElement = document.getElementById(remoteVideoElementId) as HTMLVideoElement;
        
        if (remoteVideoElement) {
            let remoteStream = remoteVideoElement.srcObject as MediaStream;
            if (!remoteStream) {
                remoteStream = new MediaStream();
                remoteVideoElement.srcObject = remoteStream;
            }
            if (!this.camera) {

            }
            remoteStream.addTrack(event.track);
            if (event.track.kind === 'audio') {
                // Ses seviyesini periyodik olarak kontrol etmek için `checkAudioLevelWithStats` fonksiyonunu çağır
                this.checkAudioLevelWithStats(event.receiver, participantId);
            } 
        } else {
            console.warn(`Video elementi bulunamadı: ${remoteVideoElementId}`);
        }
    }
    generateMeetingNumber(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    checkConnection() {
        /*const filteredParticipants = this.callPipe.transform(this.participants2, this.appInfo.currentUser.id, this.meetingId);

        filteredParticipants.forEach(participant => {
            const connectionGuid = this.generateConnectionGuid(this.appInfo.currentUser.id, participant.id);

            if (!this.connections.some(conn => conn.connectionGuid === connectionGuid)) {
                console.log(`Bağlantı oluşturuluyor: ${connectionGuid}`);

                const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
                this.peerConnections[connectionGuid] = new RTCPeerConnection(configuration);

                this.peerConnections[connectionGuid].onicecandidate = (event) => this.handleIceCandidate(event, connectionGuid);
                this.peerConnections[connectionGuid].ontrack = (event: RTCTrackEvent) => this.handleRemoteStream(event, connectionGuid);

                navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                    .then(stream => {
                        stream.getTracks().forEach(track => this.peerConnections[connectionGuid].addTrack(track, stream));
                        this.createAndSendOffer(connectionGuid);
                    })
                    .catch(error => console.error('Media access error:', error));
            }
        });*/













        /*Object.keys(this.peerConnections).forEach(connectionGuid => {
            if (!this.connections.some(conn => conn.connectionGuid === parseInt(connectionGuid))) {
                console.log(`Bağlantı eksik: ${connectionGuid}`);
                const matchedParticipants = this.findParticipantsFromGuid(connectionGuid, this.callUserIds);
                if (matchedParticipants) {
                    const smallerParticipant = matchedParticipants[0];
                    if (this.peerConnections[connectionGuid].connectionState != "connected") {
                        if (smallerParticipant == this.appInfo.currentUser.id) {
                            const configuration = {
                                iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
                            };
                            this.isCaller = true;
                            this.peerConnections[connectionGuid] = new RTCPeerConnection(configuration);

                            this.peerConnections[connectionGuid].onicecandidate = (event) => this.handleIceCandidate(event, parseInt(connectionGuid));

                            this.peerConnections[connectionGuid].ontrack = (event: RTCTrackEvent) => {
                                if (this.isActiveScreenShare) {
                                    this.handleScreenShareStream(event);
                                } else {
                                    this.handleRemoteStream(event, parseInt(connectionGuid));
                                }
                            };

                            navigator.mediaDevices.getUserMedia({
                                video: true, audio: {
                                    echoCancellation: true,
                                    noiseSuppression: true,
                                    autoGainControl: true
                                }
                            })
                                .then(stream => {
                                    this.localStream = stream;

                                    const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                                    localVideoElement.srcObject = this.localStream;

                                    stream.getTracks().forEach(track => this.peerConnections[connectionGuid].addTrack(track, stream));

                                    setTimeout(() => {
                                        this.createAndSendOffer(parseInt(connectionGuid));
                                    }, 500);
                                })
                                .catch(error => {
                                    console.error('Kamera ve mikrofona erişim hatası:', error);
                                });
                        }
                    }
                }
            }
        });*/






        /*this.connections.forEach(_con => {
            const connectionGuid = _con.connectionGuid;

            const matchedParticipants = this.findParticipantsFromGuid(connectionGuid, this.callUserIds);
            if (matchedParticipants) {
                const smallerParticipant = matchedParticipants[0];
                if (this.peerConnections[connectionGuid]) {
                    if (this.peerConnections[connectionGuid].connectionState != "connected") {
                        if (smallerParticipant == this.appInfo.currentUser.id) {
                            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                            const configuration = {
                                iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
                            };

                            this.peerConnections[connectionGuid] = new RTCPeerConnection(configuration);

                            this.peerConnections[connectionGuid].onicecandidate = (event) => this.handleIceCandidate(event, connectionGuid);

                            this.peerConnections[connectionGuid].ontrack = (event: RTCTrackEvent) => {
                                if (this.isActiveScreenShare) {
                                    this.handleScreenShareStream(event);
                                } else {
                                    this.handleRemoteStream(event, connectionGuid);
                                }
                            };

                            navigator.mediaDevices.getUserMedia({
                                video: true, audio: {
                                    echoCancellation: true,
                                    noiseSuppression: true,
                                    autoGainControl: true
                                }
                            })
                                .then(stream => {
                                    this.localStream = stream;

                                    const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                                    localVideoElement.srcObject = this.localStream;

                                    stream.getTracks().forEach(track => this.peerConnections[connectionGuid].addTrack(track, stream));
                                    setTimeout(() => {
                                        this.createAndSendOffer(connectionGuid);
                                    }, 500);

                                    this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                                        if (clear) {
                                            stream.getTracks().forEach(track => {
                                                track.stop();
                                            });
                                            setTimeout(() => {
                                                window.dispatchEvent(new Event('resize'));
                                            }, 5000);
                                        }
                                    });

                                })
                                .catch(error => {
                                    console.error('Error accessing camera and microphone:', error);
                                });
                        }
                    }
                } else {
                    if (smallerParticipant == this.appInfo.currentUser.id) {
                        try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
                        const configuration = {
                            iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
                        };

                        this.peerConnections[connectionGuid] = new RTCPeerConnection(configuration);

                        this.peerConnections[connectionGuid].onicecandidate = (event) => this.handleIceCandidate(event, connectionGuid);

                        this.peerConnections[connectionGuid].ontrack = (event: RTCTrackEvent) => {
                            if (this.isActiveScreenShare) {
                                this.handleScreenShareStream(event);
                            } else {
                                this.handleRemoteStream(event, connectionGuid);
                            }
                        };

                        navigator.mediaDevices.getUserMedia({
                            video: true, audio: {
                                echoCancellation: true,
                                noiseSuppression: true,
                                autoGainControl: true
                            }
                        })
                            .then(stream => {
                                this.localStream = stream;

                                const localVideoElement = document.getElementById('localVideo') as HTMLVideoElement;
                                localVideoElement.srcObject = this.localStream;

                                stream.getTracks().forEach(track => this.peerConnections[connectionGuid].addTrack(track, stream));
                                setTimeout(() => {
                                    this.createAndSendOffer(connectionGuid);
                                }, 500);

                                this.ClearStreamFunction = this.eventEmitterService.InvokeClearStreamFunction.subscribe(clear => {
                                    if (clear) {
                                        stream.getTracks().forEach(track => {
                                            track.stop();
                                        });
                                        setTimeout(() => {
                                            window.dispatchEvent(new Event('resize'));
                                        }, 5000);
                                    }
                                });

                            })
                            .catch(error => {
                                console.error('Error accessing camera and microphone:', error);
                            });
                    }
                }
                
            }
        });*/

    }
    createParticipantConnections(acceptedUsers: string[], currentUserId: string, userActivatedCamera) {
        const participantsCon = [];
        const meetingGuid = this.meetingId;
        acceptedUsers.forEach(userId => {
            if (userId != currentUserId) {
                const connGuid = this.generateConnectionGuid(currentUserId, userId);

                let fromUserId: string;
                let toUserId: string;

                if (userId < currentUserId) {
                    fromUserId = userId;
                    toUserId = currentUserId;
                } else {
                    fromUserId = currentUserId;
                    toUserId = userId;
                }

                const participantCon = {
                    guid: meetingGuid,
                    connGuid: connGuid,
                    from: fromUserId,
                    to: toUserId,
                    connection: false,
                    userActivatedCamera: userActivatedCamera
                };

                participantsCon.push(participantCon);
            }
        })

        return participantsCon;
    }

    createSingleParticipantConnection(acceptedUserId: string, currentUserId: string) {
        const meetingGuid = this.meetingId;
        let fromUserId: string;
        let toUserId: string;

        // acceptedUserId ve currentUserId karşılaştırması yapılıyor
        if (acceptedUserId < currentUserId) {
            fromUserId = acceptedUserId;
            toUserId = currentUserId;
        } else {
            fromUserId = currentUserId;
            toUserId = acceptedUserId;
        }

        // Bağlantı objesi oluşturuluyor
        const participantCon = {
            guid: meetingGuid,
            connGuid: this.generateConnectionGuid(currentUserId, acceptedUserId),
            from: fromUserId,
            to: toUserId,
            connection: false
        };

        return participantCon;
    }

    /*private findParticipantsFromGuid(guid, selectedUsers: string[]): [string, string] | null {
        for (let i = 0; i < selectedUsers.length; i++) {
            for (let j = i + 1; j < selectedUsers.length; j++) {
                const user1 = selectedUsers[i];
                const user2 = selectedUsers[j];

                const generatedGuid = this.generateConnectionGuid(user1, user2);

                if (generatedGuid == guid) {
                    const sortedParticipants = [user1, user2].sort();
                    return sortedParticipants as [string, string];
                }
            }
        }

        return null;
    }*/


    /// Kamera izini alanları
    isCamerePermmissionDenied: boolean = false;
    async checkAndRequestMediaPermissions() {
        try {
            // Kamera ve mikrofon izinlerini kontrol edin
            const cameraPermission = await navigator.permissions.query({ name: 'camera' as PermissionName });
            const micPermission = await navigator.permissions.query({ name: 'microphone' as PermissionName });

            // Kamera ve mikrofon izinlerini kontrol edin ve işlemleri başlatın
            if (cameraPermission.state === 'granted' || micPermission.state === 'granted') {
                console.log('Kamera ve mikrofon izinleri zaten verilmiş.');
            } else if (cameraPermission.state === 'denied' || micPermission.state === 'denied') {
                console.warn('Kamera veya mikrofon izni reddedildi. Yeniden izin isteyeceğiz.');
                await this.requestMediaAccess();
            } else {
                console.log('Kamera ve mikrofon izinleri henüz sorulmadı. İzin isteniyor...');
                await this.requestMediaAccess();
            }
            if (cameraPermission.state === "denied" && micPermission.state === "granted") {
                this.isCamerePermmissionDenied = true;
            }
        } catch (error) {
            console.error('İzin kontrolünde hata oluştu:', error);
        }
    }

    // İzinleri istemek için kamera ve mikrofonu tetikleyen fonksiyon
    async requestMediaAccess() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            console.log('Kamera ve mikrofon izinleri verildi.');
            // İzinler verildikten sonra aramayı başlatın
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                console.error('Kullanıcı kamera veya mikrofon iznini reddetti.');
                alert('Kamera ve mikrofon izinleri reddedildi. Lütfen tarayıcı ayarlarından izinleri açın.');
                if (this.isIncomingCall) {
                    this.answerCall(false);
                }
                if (this.showCallPopup) {
                    this.showCallPopup = false;
                }
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            } else {
                console.error('Kamera veya mikrofon izinleri sırasında başka bir hata oluştu:', error);
            }
        }
    }





    private ringAudio: HTMLAudioElement;
    playRingAudio() {
        if (this.ringAudio.paused) {
            this.ringAudio.load();
            this.ringAudio.play();
        }
    }

    stopRingAudio() {
        if (!this.ringAudio.paused) {
            this.ringAudio.pause();
            this.ringAudio.currentTime = 0;
        }
    }


    /*setupPeerConnectionForUser(userId: string, callType: string): void {
        const peerConnection = new RTCPeerConnection();

        peerConnection.onicecandidate = event => {
            if (event.candidate) {
                this.userService.sendIceCandidateToServer(event.candidate, userId);
            }
        };

        peerConnection.ontrack = event => {
            this.handleRemoteStream(event.streams[0], userId);
        };

        if (this.isCaller) {
            peerConnection.createOffer().then(offer => {
                return peerConnection.setLocalDescription(offer);
            }).then(() => {
                this.webMessageService.sendOfferToServer(peerConnection.localDescription, userId);
            }).catch(error => {
                console.error('Offer oluşturulurken hata oluştu:', error);
            });
        }
    }*/
}
