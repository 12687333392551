import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { WebmsgModel } from '@shared/models/webmsg-model';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { DataRefreshModel } from '@shared/models/datarefresh-model';
import { ReOpenFormModel, ReOpenMenuModel } from '@shared/models/reopenform-model';
import { OpenLoadingModel } from '@shared/models/openloading-model';
import { ChatMessageModel } from '@shared/models/chatmessage-model';
import { KanbanHubModel } from '@shared/models/kanban-hub-model';
import { ProfileHubModel } from '@shared/models/profile-hub-model';
import { JoblistChatHubModel, NotificationHubModel } from '@shared/models/notification-hub-model';
import { EventEmitterService } from '../common';
import { CallUsersModel, IceCandidateModel, OfferModel, AnswerModel, IceGroupCandidateModel, OfferGroupModel, AnswerGroupModel, ConnectionModel, ExitCallModel } from '../../models/call-users-model';
import { UserService } from './user.service';
import { PollHubVM } from '@shared/models/poll-hub-vm';
import { PollAnswersHubVM } from '@shared/models/poll-hub-answer-vm';


@Injectable({
    providedIn: 'root'
})
export class WebMessageService {
    messageReceived = new EventEmitter<WebmsgModel>();
    changeModule = new EventEmitter<DataRefreshModel>();
    jobListChat = new EventEmitter<JoblistChatHubModel>();
    reOpenForm = new EventEmitter<ReOpenFormModel>();
    reOpenMenu = new EventEmitter<ReOpenMenuModel>();
    chatMessageGetAll = new EventEmitter<ChatMessageModel>();
    openLoading = new EventEmitter<OpenLoadingModel>();
    projectManagementEvent = new EventEmitter<KanbanHubModel>();
    profileManagementEvent = new EventEmitter<ProfileHubModel>();
    notificationManagementEvent = new EventEmitter<NotificationHubModel>();
    chatUserStatus = new EventEmitter<number>();
    callEvent = new EventEmitter<CallUsersModel>();
    ReceiveIceCandidate = new EventEmitter<IceCandidateModel>();
    ReceiveOffer = new EventEmitter<OfferModel>();
    ReceiveAnswer = new EventEmitter<AnswerModel>();
    screenStatus = new EventEmitter<boolean>();
    sendCallingAnswer = new EventEmitter<{ answer: boolean, userId: number }>();
    endCalling = new EventEmitter<boolean>();
    exitCalling = new EventEmitter<ExitCallModel>();
    setConnectionsEvent = new EventEmitter<ConnectionModel>();
    meetingRoom = new EventEmitter<any>();
    ReceiveGroupIceCandidate = new EventEmitter<IceGroupCandidateModel>();
    ReceiveGroupOffer = new EventEmitter<OfferGroupModel>();
    ReceiveGroupAnswer = new EventEmitter<AnswerGroupModel>();
    connectionEstablished = new EventEmitter<Boolean>();
    private connectionIsEstablished = false;
    private _hubConnection: HubConnection;
    isLoggedIn = false;
    pollConfigurationEvent = new EventEmitter<PollHubVM>();
    pollAnswersConfigurationEvent = new EventEmitter<PollAnswersHubVM>();


    constructor(
        public appInfo: AppInfoService, 
        private http: HttpClient, 
        public eventEmitter: EventEmitterService, 
        public _userService: UserService
    ) {   
        }

    sendMessage(message: WebmsgModel) {
        if (!!this._hubConnection) {
            this._hubConnection.invoke('NewWebMessage', message);
        }
    }

    saveMessageInfo(message: WebmsgModel): Observable<boolean> {
        let params = new HttpParams().set("id", message.id.toString());
        return this.http.get<boolean>(this.appInfo.appUrl + "api/generic/saveMessageInfo", { params: params });
    }


    sendMessageService(message: WebmsgModel): Observable<boolean> {        
        let params = new HttpParams().set("user_id", message.user_id.toString()).set("msg_show_type", message.msg_show_type.toString()).set("message_type", message.message_type.toString())
            .set("message", message.message.toString()).set("message_title", message.message_title.toString()).set("is_read", message.is_read.toString()).set("id", message.id.toString());
        return this.http.get<boolean>(this.appInfo.appUrl + "api/generic/sendwebmessage", { params: params });
    }

    startAuthConnection() {
        
        if (this.isLoggedIn == false && typeof this._hubConnection == "undefined") {

            const localUserSession = localStorage.getItem("localUserSession");
            if (localUserSession) {
                const userSession = JSON.parse(localUserSession);
                const token = userSession.token;

                if (token) {
                    this.startConnection(token);
                } else {
                    console.error("JWT token bulunamad�.");
                }
            
            this.registerOnServerEvents();
            this.JobListChatChange();
            this.updateModule();
            this.reOpenFormEvent();
            this.reOpenMenuEvent();
            this.chatMessageGetAllEvent();
            this.openLoadingEvent();
            this.projectManagementChangeEvent();
            this.profileManagementChangeEvent();
            this.notificationManagementChangeEvent();
            this.listenForIncomingCalls();
            this.listenReceiveIceCandidate();
            this.listenReceiveOffer();
            this.listenReceiveAnswer();
            this.screenShareStatus();
            this.sendCallAnswer();
            this.endCall();
            this.exitCall();
            this.setConnections()
            this.meetingRooms()
            this.addListeners();


            //Grup arama alanlar�
            this.listenReceiveGroupIceCandidate();
            this.listenReceiveGroupOffer();
            this.listenReceiveGroupAnswer();

            //Poll arama alanları
            this.listenPollConfiguration();
            this.listenPollAnswersConfiguration();
            } 

        }
    }

    stopConnection() {
        this.isLoggedIn = false;
        if (this._hubConnection) {
            this._hubConnection.off("NotificationManagementChange");
            this._hubConnection.stop();
            this._hubConnection = undefined;
        }
        
    }

    private createConnection(token: string) { 

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(this.appInfo.appUrl + 'RiverHub', {
                accessTokenFactory: () => token 
            })
            .build();

        this._hubConnection.onclose(() => {
            if (this.isLoggedIn) {
                let _this = this;
                setTimeout(function () {
                    _this.startConnection(token);
                }, 5000);
            }
        });
    }

    private startConnection(token: string): void { 

        this.createConnection(token); 
        this.isLoggedIn = true;
        this._hubConnection
            .start()
            .then(() => {
                this.connectionIsEstablished = true;
                this.connectionEstablished.emit(true);
            })
            .catch(err => {
                if (this.isLoggedIn) {
                    let _this = this;
                    setTimeout(function () {
                        _this.startConnection(token); 
                    }, 5000);
                }
            });
    }




    private registerOnServerEvents(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('MessageReceived', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.messageReceived.emit(data);
                }
            });
        }

    }

    private updateModule(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ChangeModule', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.changeModule.emit(data);
                }
            });
        }
    }

    private reOpenFormEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReOpenForm', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.reOpenForm.emit(data);
                }
            });
        }

    }

    private reOpenMenuEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReOpenMenu', (data: any) => {

                if (typeof this._hubConnection != "undefined") {
                    this.reOpenMenu.emit(data);
                }
            });
        }

    }

    private chatMessageGetAllEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('SendChatMessage', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.chatMessageGetAll.emit(data);
                }
            });
        }

    }
    private openLoadingEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('OpenLoading', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.openLoading.emit(data);
                }
            });
        }

    }

    private projectManagementChangeEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ProjectManagementChange', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.projectManagementEvent.emit(data);
                }
            });
        }

    }
    private profileManagementChangeEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ProfileManagementChange', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.profileManagementEvent.emit(data);
                }
            });
        }

    }
    private notificationManagementChangeEvent(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('NotificationManagementChange', (data: any) => {
                
                if (typeof this._hubConnection != "undefined") {
                    this.notificationManagementEvent.emit(data);
                }

            })
        }

    }
    private JobListChatChange(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('JobListChat', (data: any) => {
                if (typeof this._hubConnection != "undefined") {
                    this.jobListChat.emit(data);
                }
            })
        }

    }

    sendChatUserId() {
        if (typeof this._hubConnection != "undefined" && this._hubConnection.state == 1) {
            this._hubConnection.invoke('ChatUserStatus', this.appInfo.currentUser.id);
        }

    }
    private listenForIncomingCalls(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceiveCall', (data: any) => {
                this.callEvent.emit(data);
            });
        }
    }

    private listenReceiveIceCandidate(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceiveIceCandidate', (candidate: IceCandidateModel) => {
                this.ReceiveIceCandidate.emit(candidate);
            });
        }
    }    
    private listenReceiveOffer(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceiveOffer', (offer: OfferModel) => {
                this.ReceiveOffer.emit(offer);
            });
        }
    }
    private listenReceiveAnswer(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceiveAnswer', (answer: AnswerModel) => {
                this.ReceiveAnswer.emit(answer);
            });
        }
    }

    private screenShareStatus(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ScreenShareStatus', (screenStatus: boolean) => {
                this.screenStatus.emit(screenStatus);
            });
        }
    }

    private sendCallAnswer(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('sendCallAnswer', (answer: boolean, userId: number) => {
                this.sendCallingAnswer.emit({ answer: answer, userId: userId }); 
            });
        }
    }

    private meetingRooms(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('meetingRooms', (meeting) => {
                this.meetingRoom.emit(meeting);
            });
        }
    }

    private endCall(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('endCall', (endcall: boolean) => {
                this.endCalling.emit(endcall);
            });
        }
    }
    private exitCall(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('exitcall', (data: ExitCallModel) => {
                this.exitCalling.emit(data);
            });
        }
    }

    private setConnections(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('SetConnections', (data: any) => {
                this.setConnectionsEvent.emit(data);
            });
        }
    }
    private addListeners() {
        // Online kullan�c�lar g�ncellenince UserService'i bilgilendir
        this._hubConnection.on('updateOnlineUsers', (onlineUsers: string[]) => {
            this._userService.updateOnlineUsers(onlineUsers);
        });
    }





    //Grup Arama Alanlar�

    private listenReceiveGroupIceCandidate(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceiveGroupIceCandidate', (candidate: IceGroupCandidateModel) => {
                this.ReceiveGroupIceCandidate.emit(candidate);
            });
        }
    }

    private listenReceiveGroupOffer(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceiveGroupOffer', (offer: OfferGroupModel) => {
                this.ReceiveGroupOffer.emit(offer);
            });
        }
    }
    private listenReceiveGroupAnswer(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ReceivegroupAnswer', (answer: AnswerGroupModel) => {
                this.ReceiveGroupAnswer.emit(answer);
            });
        }
    }

    private listenPollConfiguration(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ConfigurePolls', (data: PollHubVM) => {
                if (typeof this._hubConnection != "undefined") {
                    this.pollConfigurationEvent.emit(data);
                }
            });
        }
    }

    private listenPollAnswersConfiguration(): void {
        if (typeof this._hubConnection != "undefined") {
            this._hubConnection.on('ConfigurePollsAnswers', (data: PollAnswersHubVM) => {
                if (typeof this._hubConnection != "undefined") {
                    this.pollAnswersConfigurationEvent.emit(data);
                }
            });
        }
    }

}  
