import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallComponent } from './call.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DxPopupModule, DxSelectBoxModule, DxTagBoxModule } from 'devextreme-angular';
import { CallPipe } from '../../../@core/pipes/call.pipe';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [CallComponent, CallPipe],
    imports: [
        CommonModule,
        DragDropModule,
        DxPopupModule,  
        DxSelectBoxModule,
        DxTagBoxModule,
        FormsModule,
        NgbModule
    ],
    exports: [CallComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CallModule { }