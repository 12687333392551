import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { NgSelectModule } from "@ng-select/ng-select";

import { DevExtremeModule } from '../../devextreme.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { CoreSidebarModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';
import { ChatComponent } from './chat.component';

import { ChatContentComponent } from './chat-content/chat-content.component';
import { ChatSidebarComponent } from './chat-sidebars/chat-sidebar/chat-sidebar.component';
import { ChatUserSidebarComponent } from './chat-sidebars/chat-user-sidebar/chat-user-sidebar.component';
import { ChatActiveSidebarComponent } from './chat-sidebars/chat-active-sidebar/chat-active-sidebar.component';

import { WebMessageService } from '@shared/services/api/webmessage.service';
import { ChatService } from '@shared/services/api/chat.service';
import { TranslateModule } from '@ngx-translate/core';
// routing
const routes: Routes = [
    {
        path: '**',
        component: ChatComponent,
        data: { animation: 'chat' }
    }
];

@NgModule({
    declarations: [
        ChatComponent,
        ChatContentComponent,
        ChatSidebarComponent,
        ChatUserSidebarComponent,
        ChatActiveSidebarComponent
    ],
    exports: [
        ChatComponent,
        ChatContentComponent,
        ChatSidebarComponent,
        ChatUserSidebarComponent,
        ChatActiveSidebarComponent,
    ],
    imports: [
        CommonModule,
        CoreSidebarModule,
        CoreCommonModule,
        PerfectScrollbarModule,
        NgbModule,
        DevExtremeModule,
        NgSelectModule,
        TranslateModule,
    ],
    providers: [ChatService, WebMessageService]
})
export class ChatModule { }
